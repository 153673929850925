import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GlobalContext } from "../../Consts/GlobalContext";
import Group8446 from "../../img/products/group-8446.png";
import Group8447 from "../../img/products/group-8447.png";

const ProductsSoftwareWrapper = styled.div`
  padding: 145px 375px;
  .text-wrapper {
    text-align: center;
    padding: 0 265px;

    h1 {
      ${(props) => props.theme.h1_64};
    }
    p {
      ${(props) => props.theme.body_24};
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 125px;
    .left {
      flex-basis: 48%;
      position: relative;
      display: flex;
      flex-flow: column;
      img {
        display: block;
        width: 100%;
      }
      .left-text {
        text-align: center;
        padding-right: 60px;
        display: flex;
        flex-flow: column;
        justfy-content: space-between;
        height: 100%;
        p {
          ${(props) => props.theme.body_24};
          margin-bottom: 20px;
        }
        .receive-quote-btn {
          width: 267px;
          margin: auto auto 0;
        }
      }
      .image-badge {
        position: absolute;
        padding: 30px 58px;
        background-color: white;
        box-shadow: 5px 10px 30px #1a284d29;
        border-radius: 50px;
        top: -47px;
        left: 110px;
        p {
          font: normal normal 600 30px/34px TT Commons;
          color: #202945;
          span {
            color: #2ed9c3;
          }
        }
      }
    }
    .right {
      position: relative;
      flex-basis: 48%;
      display: flex;
      flex-flow: column;
      img {
        display: block;
        width: 100%;
      }
      .right-text {
        text-align: center;
        padding-right: 60px;
        display: flex;
        flex-flow: column;
        justfy-content: space-between;
        height: 100%;
        p {
          ${(props) => props.theme.body_24};
          margin-bottom: 20px;
        }
        .receive-quote-btn {
          width: 267px;
          background-color: #0071ce;
          margin: auto auto 0;
        }
        .receive-quote-btn:hover {
          color: #0071ce;
          background-color: #fff;
        }
      }

      .image-badge {
        position: absolute;
        padding: 30px 58px;
        background-color: white;
        box-shadow: 5px 10px 30px #1a284d29;
        border-radius: 50px;
        top: -47px;
        left: 110px;
        p {
          font: normal normal 600 30px/34px TT Commons;
          color: #202945;
          span {
            color: #0071ce;
          }
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 145px 275px;
    .text-wrapper {
      padding: 0 140px;
    }

    .content-wrapper {
      .left {
        .left-text {
          p {
            font-size: 22px;
          }
        }
        .image-badge {
          padding: 20px 45px;
          left: 60px;
        }
      }
      .right {
        .right-text {
          p {
            font-size: 22px;
          }
        }
        .image-badge {
          padding: 20px 45px;
          left: 60px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 145px 50px;
  }
  @media (max-width: 1023px) {
    padding: 100px 20px;
    .text-wrapper {
      padding: 0;
      h1 {
        font-size: 48px;
        line-height: 67px;
      }
    }
    .content-wrapper {
      .left {
        .left-text {
          p {
            font-size: 20px;
            line-height: 28px;
          }
        }
        .image-badge {
          padding: 10px 25px;
          top: -30px;
          p {
            font-size: 24px;
            line-height: 34px;
          }
        }
      }
      .right {
        .right-text {
          p {
            font-size: 20px;
            line-height: 28px;
          }
        }
        .image-badge {
          padding: 10px 25px;
          top: -30px;
          p {
            font-size: 24px;
            line-height: 34px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 50px 20px;

    .text-wrapper {
      h1 {
        font-size: 38px;
        line-height: 53px;
      }
      p {
        font-size: 18px;
        line-height: 25px;
      }
    }
    .content-wrapper {
      margin-top: 65px;
      display: block;
      .left {
        margin-bottom: 80px;
        .left-text {
          text-align: left;
          padding-right: 0;
          p {
            font-size: 18px;
            line-height: 25px;
          }
          .receive-quote-btn {
            width: 100%;
          }
        }
        .image-badge {
          padding: 15px 30px;
          left: 70px;
        }
      }

      .right {
        .right-text {
          text-align: left;
          padding-right: 0;
          p {
            font-size: 18px;
            line-height: 25px;
          }
          .receive-quote-btn {
            width: 100%;
          }
        }
        .image-badge {
          padding: 15px 30px;
          left: 70px;
        }
      }
    }
  }
`;

const ProductsSoftware = () => {
  const { lang } = useContext(GlobalContext);
  return (
    <ProductsSoftwareWrapper>
      <div className="text-wrapper">
        <h1>
          IceTrackr{" "}
          <span className="t" data-translate="productsSoftwareTitle">
            Software
          </span>
        </h1>
        <p className="t" data-translate="productsSoftwareText">
          You can choose between two platform versions - IceTrackr Pro and
          IceTrackr Lite - based on your fleet size and business needs
        </p>
      </div>
      <div className="content-wrapper">
        <div className="left">
          <img src={Group8447} alt="Group8447" />
          <div className="left-text">
            <p className="t" data-translate="productsSoftwareProText">
              Professional fleet management and asset tracking solutions for
              companies that require in-depth fleet data analysis, a wide range
              of alerts, reports and integration options with external systems
            </p>
            <Link to="/products/pro" className="receive-quote-btn">
              <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
            </Link>
          </div>
          <div className="image-badge">
            <p>
              IceTrackr <span>Pro</span>
            </p>
          </div>
        </div>
        <div className="right">
          <img src={Group8446} alt="Group8447" />
          <div className="right-text">
            <p className="t" data-translate="productsSoftwareLiteText">
              A platform that includes basic functionality and is suitable for
              smaller companies and organizations that require standard
              solutions and seek a simple, user-friendly system
            </p>
            <Link to="/products/lite" className="receive-quote-btn">
              <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
            </Link>
          </div>
          <div className="image-badge">
            <p>
              IceTrackr <span>Lite</span>
            </p>
          </div>
        </div>
      </div>
    </ProductsSoftwareWrapper>
  );
};

export default ProductsSoftware;
