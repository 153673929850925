import React from "react";
import CookiesSettings from "../CookiesSettings/CookiesSettings";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import SmallSlider from "../SmallSlider/SmallSlider";
import TemperatureControlFirstContent from "./TemperatureControlFirstContent";
import TemperatureControlIntro from "./TemperatureControlIntro";
import TemperatureControlSecondContent from "./TemperatureControlSecondContent";

const TemperatureControlPage = () => {
  return (
    <>
      <MenuFixed />
      <TemperatureControlIntro />
      <TemperatureControlFirstContent />
      <SmallSlider />
      <TemperatureControlSecondContent />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default TemperatureControlPage;
