import React from "react";
import CookiesSettings from "../CookiesSettings/CookiesSettings";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import SmallSlider from "../SmallSlider/SmallSlider";
import RoutePlanningFirstContent from "./RoutePlanningFirstContent";
import RoutePlanningIntro from "./RoutePlanningIntro";
import RoutePlanningSecondContent from "./RoutePlanningSecondContent";

const RoutePlanningPage = () => {
  return (
    <>
      <MenuFixed />
      <RoutePlanningIntro />
      <RoutePlanningFirstContent />
      <SmallSlider />
      <RoutePlanningSecondContent />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default RoutePlanningPage;
