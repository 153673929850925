import React from "react";
import styled from "styled-components";
import Ellipse from "../../img/home-page/ellipse-520.svg";
import Intersection from "../../img/home-page/intersection.svg";
import solutionsCategoriesCards from "./SolutionsContentArray.js";
import SolutionsContentCards from "./SolutionsContentCards";

const SolutionsContentWrapper = styled.div`
  padding: 100px 0 250px;
  position: relative;
  .container {
    width: 60%;
    margin: 0 auto;
    .cards {
      display: flex;
      align-items: center;
      .left {
        flex-basis: 50%;
        padding-left: 100px;
        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          text-transform: capitalize;
        }
        p {
          ${(props) => props.theme.body_20};
          margin-bottom: 45px;
        }
        .receive-quote-btn {
          width: 250px;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
        }
        .right {
          justify-content: flex-start;
        }
      }
      &:not(:last-child) {
        margin-bottom: 120px;
      }
      .right {
        flex-basis: 50%;
        display: flex;
        justify-content: flex-end;
        img {
          display: block;
        }
      }
    }
  }
  .ellipse {
    position: absolute;
    left: -175px;
    top: 1050px;
  }
  .ellipse-2 {
    position: absolute;
    left: -441px;
    top: 9438px;
    width: 626px;
    height: 626px;
  }
  .intersection {
    position: absolute;
    right: 0;
    top: 4700px;
  }
  @media (max-width: 1900px) {
    .container {
      .cards {
        .left {
          padding-left: 50px;
        }
        .right {
          img {
            width: 350px;
          }
        }
      }
    }
    .ellipse {
      top: 795px;
    }
    .ellipse-2 {
      top: 7260px;
    }
    .intersection {
      top: 3735px;
    }
  }
  @media (max-width: 1439px) {
    .container {
      width: 85%;
    }
    .intersection {
      display: none;
    }
    .ellipse {
      left: -285px;
    }
    .ellipse-2 {
      left: -550px;
    }
  }

  @media (max-width: 1023px) {
    .container {
      width: 100%;
      padding: 0 20px;
      .cards {
        .left {
          padding-left: 0;
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        .right {
          img {
            width: 300px;
          }
        }
      }
    }
    .ellipse {
      display: none;
    }
    .ellipse-2 {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .container {
      .cards {
        display: block;
        .left {
          margin-bottom: 20px;
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 16px;
            line-height: 22px;
          }
        }
        .right {
          justify-content: center;
          img{
            width:200px;
          }
        }
        &:nth-child(2n) {
          .right {
            justify-content: center;
          }
        }
      }
    }
  }
`;
const SolutionsContent = () => {
  return (
    <SolutionsContentWrapper>
      <div className="container">
        {solutionsCategoriesCards.map((el) => (
          <SolutionsContentCards
            title={el.title}
            desc={el.desc}
            image={el.image}
            descMK={el.descMK}
            titleMK={el.titleMK}
            key={el.id}
          />
        ))}
      </div>
      <img alt="" src={Ellipse} className="ellipse" />
      <img alt="" src={Ellipse} className="ellipse-2" />
      <img alt="" src={Intersection} className="intersection" />
    </SolutionsContentWrapper>
  );
};

export default SolutionsContent;
