import React from "react";
import styled from "styled-components";
import Group7021 from "../../img/dispatch/group-7021.svg";
import Group7024 from "../../img/dispatch/group-7024.svg";
import Group7028 from "../../img/dispatch/group-7028.svg";

const DispatchCommunicationSecondContentWrapper = styled.div`
  padding: 200px 375px;
  .container {
    background-color: #fff;
    h2 {
      ${(props) => props.theme.h2_48};
      color: #202945;
      text-align: center;
    }
    .container-inner {
      display: flex;
      margin-top: 100px;
      margin-left: -30px;
      margin-right: -30px;
      .container-cards {
        flex-basis: 33.3333%;
        padding-right: 30px;
        padding-left: 30px;
        .container-cards-inner {
          position: relative;
          overflow: hidden;
          border-radius: 5px;
          height: 100%;
          padding: 30px 0 60px;
          text-align: center;
          p {
            ${(props) => props.theme.body_20};
            color: #6b7ba4;
            margin-top: 20px;
          }
          img {
            display: block;
            width: 200px;
            height: 140px;
            margin: 0 auto;
          }
          span {
            margin-top: 30px;
            display: block;
            ${(props) => props.theme.body_24};
            font-weight: 600;
          }
        }
      }
    }
  }

  @media (max-width: 1900px) {
    padding: 150px 275px;
  }
  @media (max-width: 1439px) {
    padding: 100px 50px;
  }
  @media (max-width: 1023px) {
    padding: 50px 20px;
    .container {
      h2 {
        font-size: 38px;
        line-height: 53px;
      }
      .container-inner {
        margin-top: 50px;
        margin-left: -10px;
        margin-right: -10px;
        .container-cards {
          padding-left: 10px;
          padding-right: 10px;
          .container-cards-inner {
            padding: 0;
            span {
              font-size: 18px;
              line-height: 25px;
              margin-top: 15px;
            }
            p {
              font-size: 15px;
              line-height: 21px;
              margin-top: 10px;
            }
            img {
              width: 140px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .container {
      h2 {
        font-size: 28px;
        line-height: 39px;
      }
      .container-inner {
        margin-top: 50px;
        margin-left: 0;
        margin-right: 0;
        display: block;
        .container-cards {
          padding-left: 0;
          padding-right: 0;
          .container-cards-inner {
            padding: 0;
            span {
              font-size: 18px;
              line-height: 25px;
              margin-top: 15px;
            }
            p {
              font-size: 15px;
              line-height: 21px;
              margin-top: 10px;
            }
            img {
              width: 140px;
            }
          }
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
`;
const DispatchCommunicationSecondContent = () => {
  return (
    <DispatchCommunicationSecondContentWrapper>
      <div className="container">
        <h2
          className="t"
          data-translate="dispatchCommunicationSecondContentTitle"
        >
          Everything you need in one place
        </h2>
        <div className="container-inner">
          <div className="container-cards">
            <div className="container-cards-inner">
              <img src={Group7021} alt=""/>
              <span
                className="t"
                data-translate="dispatchCommunicationSecondContentFirstCardTitle"
              >
                Data exchange and storage
              </span>
              <p
                className="t"
                data-translate="dispatchCommunicationSecondContentFirstCardText"
              >
                Share photos, documents and coordinates with your drivers and
                access all media in one place anytime, anywhere.
              </p>
            </div>
          </div>
          <div className="container-cards">
            <div className="container-cards-inner">
              <img src={Group7024} alt=""/>
              <span
                className="t"
                data-translate="dispatchCommunicationSecondContentSecondCardTitle"
              >
                Advanced search
              </span>
              <p
                className="t"
                data-translate="dispatchCommunicationSecondContentSecondCardText"
              >
                Advanced search within individual conversations, groups and
                workspaces that will help you quickly find what you're looking
                for.
              </p>
            </div>
          </div>
          <div className="container-cards">
            <div className="container-cards-inner">
              <img src={Group7028} alt=""/>
              <span
                className="t"
                data-translate="dispatchCommunicationSecondContentThirdCardTitle"
              >
                Integrations available
              </span>
              <p
                className="t"
                data-translate="dispatchCommunicationSecondContentThirdCardText"
              >
                Flawless integration with your Garmin device. It will enable you
                to explore and use all communication tool features with no
                limitations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </DispatchCommunicationSecondContentWrapper>
  );
};

export default DispatchCommunicationSecondContent;
