import React from "react";
import ContactTeam from "../Contact/ContactTeam";
import CookiesSettings from "../CookiesSettings/CookiesSettings";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import TachographThirdContent from "../Tachograph/TachographThirdContent";
import ProductsFirstContent from "./ProductsFirstContent";
import ProductsIntro from "./ProductsIntro";
import ProductsSoftware from "./ProductsSoftware";
import ProductsTable from "./ProductsTable";

const ProductsPage = () => {
  return (
    <>
      <MenuFixed />
      <ProductsIntro />
      <ProductsSoftware />
      <ProductsTable />
      <ProductsFirstContent />
      <TachographThirdContent />
      <ContactTeam />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default ProductsPage;
