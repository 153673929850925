import FuelPumpSolutions from "../../img/solutions/fuel-pump.svg";
import CostControlSolutions from "../../img/solutions/cost-control.svg";
import DriverBehaviourSolutions from "../../img/solutions/driver-behaviour.svg";
import RoutePlanningSolutions from "../../img/solutions/route-planning.svg";
import TachographSolutions from "../../img/solutions/tachograph.svg";
import DispatchCommsSolutions from "../../img/solutions/dispatch.svg";
import GpsSolutions from "../../img/solutions/gps.svg";
import SensorsSolutions from "../../img/solutions/sensors.svg";
import FleetMaintenanceSolutions from "../../img/solutions/fleet-maintenance.svg";
import ReportsSolutions from "../../img/solutions/reports.svg";
import TemperatureControlSolutions from "../../img/solutions/temperature-control.svg";
import TaskManagementSolutions from "../../img/solutions/task-management.svg";
import FleetEfficiencySolutions from "../../img/solutions/fleet-efficiency.svg";
import ErpIntegrationSolutions from "../../img/solutions/erp-integration.svg";
import DashboardsSolutions from "../../img/solutions/fuel-pump.svg";
import VehicleInspectionSolutions from "../../img/solutions/vehicle-inspection.svg";
import AlertsSolutions from "../../img/solutions/alerts.svg";
import ApiIntegrationSolutions from "../../img/solutions/api-integration.svg";
import HistoryEventsSolutions from "../../img/solutions/history.svg";
import TaxiCallSolutions from "../../img/solutions/taxi-call.svg";
const solutionsCategoriesCards = [
  {
    id: 1,
    title: "Fuel control",
    titleMK: "Контрола на гориво",
    image: FuelPumpSolutions,
    desc: "See fuel level changes and generate detailed reports to optimise the fleet's fuel consumption. View precise real-time fuel consumption and more.",
    descMK:
      "Видете ги промените во нивото на горивото и генерирајте детални извештаи за да ја оптимизирате потрошувачката на гориво на возниот парк. Погледнете ја прецизната потрошувачка на гориво во реално време и повеќе.",
  },
  {
    id: 2,
    title: "Digital tachograph remote download",
    titleMK: "Далечинско преземање на дигитален тахограф",
    image: TachographSolutions,
    desc: "Download tachograph data remotely to save time and ease file management. You can also schedule and automate the downloads.",
    descMK:
      "Преземете ги податоците од тахографот од далечина за да заштедите време и да го олесните управувањето со датотеките. Можете исто така да планирате и автоматизирате преземања.",
  },
  {
    id: 3,
    title: "Fleet maintenance",
    image: FleetMaintenanceSolutions,
    titleMK: "Одржување на флота",

    desc: "Make maintenance easier by setting reminders. Keep track of repair costs, manage licenses and other important documents. Get automatically created calculations of your fleet's efficiency to see whether changes are needed.",
    descMK:
      "Олеснете го одржувањето со поставување потсетници. Следете ги трошоците за поправка, управувајте со лиценците и други важни документи. Добијте автоматски креирани пресметки за ефикасноста на вашата флота за да видите дали се потребни промени.",
  },
  {
    id: 4,
    title: "Fleet efficiency",
    image: FleetEfficiencySolutions,
    titleMK: "Ефикасност на флотата",

    desc: "An automated solution for measuring fleet performance, regardless of its size and usage patterns",
    descMK:
      "Автоматизирано решение за мерење на перформансите на флотата, без оглед на нејзината големина и шеми на употреба",
  },
  {
    id: 5,
    title: "Alerts",
    titleMK: "Известувања",
    image: AlertsSolutions,
    desc: "Get automated alerts for special events, as speeding, entering a predefined zones, fuel level changes ect",
    descMK:
      "Добивајте автоматизирани предупредувања за специјални настани, како што се забрзување, влегување во предефинирани зони, промена на нивото на гориво и др",
  },
  {
    id: 6,
    title: "Cost control",
    titleMK: "Cost control",
    image: CostControlSolutions,
    desc: "Track all your expenses with the help of our software, insert costs for maintenance, parts repair, fuel refill and many more...",
    descMK:
      "Следете ги сите ваши трошоци со помош на нашиот софтвер, вметнете трошоци за одржување, поправка на делови, полнење гориво и многу повеќе...",
  },
  {
    id: 7,
    title: "dispatch and communication",
    titleMK: "испраќање и комуникација",
    image: DispatchCommsSolutions,
    desc: "Connect the office with the driver's cabin by using a unified platform for conversations, file and location sharing. Available in mobile app and web.",
    descMK:
      "Поврзете ја канцеларијата со кабината на возачот користејќи унифицирана платформа за разговори, споделување датотеки и локација. Достапно во мобилна апликација и веб.",
  },
  {
    id: 8,
    title: "reports",
    titleMK: "извештаи",
    image: ReportsSolutions,
    desc: "Have a overview of all the fleet data represented in a variety of ways, and a easy download of all this reports via PDF or Excel format",
    descMK:
      "Имајте преглед на сите податоци за флотата претставени на различни начини и лесно преземање на сите овие извештаи преку PDF или Excel формат",
  },
  {
    id: 9,
    title: "ERP Integration",
    titleMK: "ERP интеграција",
    image: ErpIntegrationSolutions,
    desc: "Integrate any ERP like software with our platform on a easy to do way, by calling our rest point api endpoints or by using the JavaScript SDK",
    descMK:
      "Интегрирајте кој било софтвер како ERP со нашата платформа на лесен начин, со повикување на крајните точки на api на точката за одмор или со користење на JavaScript SDK",
  },
  {
    id: 10,
    title: "API integrations",
    titleMK: "API интеграции",
    image: ApiIntegrationSolutions,
    desc: "We offer a variety of integrations to automatically send and receive fleet data from external systems. From the leading IT systems in transport and logistics industry, to custom integrations with your accounting and warehouse system - we'll make sure you have everything you need in one place",
    descMK:
      "Нудиме различни интеграции за автоматско испраќање и примање податоци за флотата од надворешни системи. Од водечките ИТ системи во транспортната и логистичката индустрија, до сопствените интеграции со вашиот сметководствен и магацински систем - ќе се погрижиме да имате се што ви треба на едно место",
  },
  {
    id: 11,
    title: "Driver behaviour",
    titleMK: "Однесување на возачот",
    image: DriverBehaviourSolutions,
    desc: "See how your drivers are performing and where they could improve to become more efficient and cut fuel and maintenance costs.",
    descMK:
      "Погледнете како функционираат вашите возачи и каде би можеле да се подобрат за да станат поефикасни и да ги намалат трошоците за гориво и одржување.",
  },
  {
    id: 12,
    title: "GPS tracking and live data",
    titleMK: "GPS следење и податоци во живо",
    image: GpsSolutions,
    desc: "Be in control. See the exact location, current route and speed of all the vehicles in your fleet.",
    descMK:
      "Бидете во контрола. Погледнете ја точната локација, моменталната рута и брзината на сите возила во вашата флота.",
  },
  {
    id: 13,
    title: "Temperature monitoring",
    titleMK: "Следење на температурата",
    image: TemperatureControlSolutions,
    desc: "Get notified about refrigeration system failures and analyse data conveniently to eliminate human error, cut costs and improve efficiency.",
    descMK:
      "Добијте известување за дефекти на системот за ладење и анализирајте ги податоците погодно за да ја елиминирате човечката грешка, да ги намалите трошоците и да ја подобрите ефикасноста.",
  },
  {
    id: 14,
    title: "Dashboards",
    titleMK: "Контролни табли",
    image: DashboardsSolutions,
    desc: "Keeping track of the most important information about your fleet - all in one place",
    descMK:
      "Следете ги најважните информации за вашата флота - сите на едно место",
  },
  {
    id: 15,
    title: "History of events",
    titleMK: "Историја на настани",
    image: HistoryEventsSolutions,
    desc: "Have a detailed overview of all events day by day, with a listed events details which are replayed on the map with a easy to use player",
    descMK:
      "Имајте детален преглед на сите настани од ден на ден, со наведени детали за настани кои се многу лесни за репродукција",
  },
  {
    id: 16,
    title: "Route planning",
    titleMK: "Планирање на маршрутата",
    image: RoutePlanningSolutions,
    desc: "Designed to reduce logistics costs, optimize the fleet and improve your company service quality, Ice Trackr route planning solution will help you to manage your logistics process more efficiently.",
    descMK:
      "Дизајниран да ги намали трошоците за логистика, да ја оптимизира флотата и да го подобри квалитетот на услугите на вашата компанија, решението за планирање маршрута Ice Trackr ќе ви помогне поефикасно да управувате со вашиот логистички процес.",
  },
  {
    id: 17,
    title: "Sensors for seats and taximeter",
    titleMK: "Сензори за седишта и таксиметар",
    image: SensorsSolutions,
    desc: "With our custom solution you can track the number of passengers in the vehicle, and know when the taximeter is on or off",
    descMK:
      "Со нашето приспособено решение можете да го следите бројот на патници во возилото и да знаете кога таксиметарот е вклучен или исклучен",
  },
  {
    id: 18,
    title: "Task management",
    titleMK: "Управување со задачи",
    image: TaskManagementSolutions,
    desc: "A mobile app that allows you to manage your drivers and field workers by scheduling, dispatching and managing their tasks, ensuring transparent communication with the back-office and keeping you updated on the progress of their daily schedule",
    descMK:
      "Мобилна апликација која ви овозможува да управувате со вашите возачи и работници на терен преку закажување, испраќање и управување со нивните задачи, обезбедувајќи транспарентна комуникација со задната канцеларија и информирајќи ве за напредокот на нивниот дневен распоред",
  },
  {
    id: 19,
    title: "Vehicle inspection",
    image: VehicleInspectionSolutions,
    titleMK: "Преглед на возилото",

    desc: "Save time and trouble by using simple vehicle inspection forms. They're easy to customise to fit your needs and have functionality like scheduling and adding photos and comments to catch any issues early.",
    descMK:
      "Заштедете време и проблеми со користење на едноставни формулари за проверка на возилото. Лесно се приспособуваат за да одговараат на вашите потреби и имаат функционалност како закажување и додавање фотографии и коментари за рано да се фатат сите проблеми.",
  },
  {
    id: 20,
    title: "Taxi Call Dispatch",
    titleMK: "Повик до такси",
    image: TaxiCallSolutions,
    desc: "Automate all business processes and increase the profitability of your taxi company",
    descMK:
      "Автоматизирајте ги сите деловни процеси и зголемете ја профитабилноста на вашата такси компанија",
  },
];
export default solutionsCategoriesCards;
