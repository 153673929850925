import React from "react";
import styled from "styled-components";
import BackgroundIntro from "../../img/quote_background.svg";
import DriverBehaviour from "../../img/driver-behaviour.svg";

const DriverBehaviourIntroWrapper = styled.div`
  background-image: url(${BackgroundIntro});
  padding: 150px 375px 0;
  .container {
    display: flex;
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #dce3eb;

    .left {
      flex-basis: 35%;
      padding: 100px 0;
      border-right: 1px solid #dce3eb;
      img {
        display: block;
        width: 254px;
        height: 277px;
        margin: 0 auto;
      }
    }
    .right {
      flex-basis: 65%;
      padding: 25px 100px;
      border-top-right-radius: 5px;
      display: flex;
      align-items: center;
      .right-wrapper {
        h1 {
          ${(props) => props.theme.h1_64};
          margin-bottom: 25px;
        }
        p {
          ${(props) => props.theme.body_24};
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 150px 275px 0;
    .container {
      .left {
        padding: 50px 0;
      }
      .right {
        padding: 0 50px;
        .right-wrapper {
          h1 {
            font-size: 48px;
            line-height: 67px;
          }
          p {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 150px 50px 0;
    .container {
      .left {
        padding: 30px 0;
      }
      .right {
        padding: 0 15px;
      }
    }
  }
  @media (max-width: 1023px) {
    padding: 150px 20px 0;
    .container {
      .left {
        img {
          width: 200px;
          height: 230px;
        }
      }
      .right {
        padding: 0 15px;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 100px 20px 0;
    .container {
      display: block;
      .left {
        padding: 15px 0 0;
        border-right: none;
        img {
          height: 100px;
        }
      }
      .right {
        padding: 15px;
        .right-wrapper {
          h1 {
            font-size: 30px;
            line-height: 42px;
            margin-bottom: 10px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
      }
    }
  }
`;
const DriverBehaviourIntro = () => {
  return (
    <DriverBehaviourIntroWrapper>
      <div className="container">
        <div className="left">
          <img src={DriverBehaviour} alt=""/>
        </div>
        <div className="right">
          <div className="right-wrapper">
            <h1 className="t" data-translate="driverBehaviourIntroTitle">
              Driver Behaviour
            </h1>
            <p className="t" data-translate="driverBehaviourIntroText">
              Our Driver Behaviour solution will help in creating an environment
              for safe and efficient driving. In the long run, it'll result in
              reducing the associated business costs.
            </p>
          </div>
        </div>
      </div>
    </DriverBehaviourIntroWrapper>
  );
};

export default DriverBehaviourIntro;
