import React from "react";
import styled from "styled-components";
import Group8393 from "../../img/reports/group-8393.png";
import Group8395 from "../../img/reports/group-8395.png";

const ReportsFirstContentWrapper = styled.div`
  padding: 0px 375px 200px;
  position: relative;
  z-index: 1;
  .container {
    padding: 100px 100px 70px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        flex-basis: 70%;
        margin-right: 30px;
        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
          ${(props) => props.theme.body_20};
        }
      }

      .right {
        flex-basis: 30%;
        position: relative;
        img {
          display: block;
          box-shadow: -10px 10px 40px #1a284d1a;
        }
        img:first-of-type {
          transition: all 0.6s ease-in-out;
          transform: translate(30px, 20px);
        }
        img:last-of-type {
          transition: all 0.6s ease-in-out;
          position: relative;
          z-index: 1;
        }
        &:hover {
          img:first-of-type {
            transform: translate(30px, -20px);
          }
          img:last-of-type {
            transform: translateY(20px);
          }
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0px 275px 150px;
    .container {
      padding: 50px 25px;
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 0px 50px 100px;
  }
  @media (max-width: 1023px) {
    padding: 0px 20px 50px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img:first-of-type {
            width: 260px;
            transform: translate(20px, 20px);
          }
          img:last-of-type {
            width: 300px;
          }
          &:hover {
            img:first-of-type {
              transform: translate(20px, -10px);
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      .container-cards {
        display: block;
        .left {
          margin-right: 0;
          margin-bottom: 15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }

          img:first-of-type {
            width: 260px;
            transform: translate(0px, 20px);
          }
          img:last-of-type {
            width: 300px;
          }
          &:hover {
            img:first-of-type {
              transform: translate(0px, 0px);
            }
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
`;
const ReportsFirstContent = () => {
  return (
    <ReportsFirstContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="reportsFirstContentFirstCardTitle"
            >
              Detailed picture for any of the solutions we offer
            </h2>
            <p className="t" data-translate="reportsFirstContentFirstCardText">
              Generate un overview for any of the solutions we offer, analyze
              the data and download it in PDF or Excel format
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group8395} />
            <img alt="" src={Group8393} />
          </div>
        </div>
      </div>
    </ReportsFirstContentWrapper>
  );
};

export default ReportsFirstContent;
