import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import axios from "axios";
import { useContext } from "react";
import { GlobalContext } from "../../Consts/GlobalContext";

const SubscribeTemp = () => {
  const { bearerToken } = useContext(GlobalContext);

  useEffect(() => {});

  const handleClick = (e) => {
    e.preventDefault();
    let emailSubscribed = "baze@icelabs.mk";
    axios({
      method: "POST",
      url: `${window.baseurl}/restful/v2/subscribe?email=${emailSubscribed}`,
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
        Authorization: bearerToken,
      },
    });
  };

  return (
    <>
      <MenuFixed />
      <button type="button" onClick={(e) => handleClick(e)}>
        Test
      </button>
      <Quote />
      <FooterLinks />
      <Footer />
    </>
  );
};

export default SubscribeTemp;
