import React from "react";
import styled from "styled-components";
import Group6611 from "../../img/driver-behaviour/group-6611.svg";
import Group7037 from "../../img/driver-behaviour/group-7037.png";
import Group7041 from "../../img/driver-behaviour/group-7041.svg";
import ListIcon from "../../img/list-icon.svg";

const DriverBehaviourFirstContentWrapper = styled.div`
  padding: 0px 375px;
  margin-bottom: -115px;
  position: relative;
  z-index: 1;
  .container {
    padding: 100px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        flex-basis: 75%;

        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
          ${(props) => props.theme.body_20};
        }
        ul {
          padding-left: 24px;
          list-style-image: url(${ListIcon});

          li {
            ${(props) => props.theme.body_20};
            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }
      }

      .right {
        img {
          width: 370px;
          display: block;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }
      &:last-child {
        img {
          box-shadow: none;
        }
      }

      &:nth-of-type(2) {
        .left {
          ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
              flex-basis: 45%;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0px 275px;
    .container {
      padding: 30px 25px 40px;
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 0px 50px;
  }
  @media (max-width: 1023px) {
    padding: 0px 20px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
          ul {
            li {
              font-size: 16px;
              line-height: 22px;
              &:not(:last-child) {
                margin-bottom: 8px;
              }
            }
          }
        }
        .right {
          img {
            width: 280px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      .container-cards {
        display: block;
        .left {
          margin-right: 0;
          margin-bottom: 15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
          ul {
            li {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
`;
const DriverBehaviourFirstContent = () => {
  return (
    <DriverBehaviourFirstContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="driverBehaviourFirstContentFirstCardTitle"
            >
              Save Time and Money
            </h2>
            <ul>
              <li
                className="t"
                data-translate="dispatchCommunicationFirstContentFirstCardText1"
              >
                On maintenance{" "}
              </li>
              <li
                className="t"
                data-translate="dispatchCommunicationFirstContentFirstCardText2"
              >
                On fuel
              </li>
              <li
                className="t"
                data-translate="dispatchCommunicationFirstContentFirstCardText3"
              >
                On legal and repair costs due to traffic accidents
              </li>
            </ul>
          </div>
          <div className="right">
            <img alt="" src={Group6611} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="driverBehaviourFirstContentSecondCardTitle"
            >
              Features
            </h2>
            <ul>
              <li
                className="t"
                data-translate="driverBehaviourFirstContentSecondCardText1"
              >
                Eco speed
              </li>
              <li
                className="t"
                data-translate="driverBehaviourFirstContentSecondCardText2"
              >
                Harsh braking
              </li>
              <li
                className="t"
                data-translate="driverBehaviourFirstContentSecondCardText3"
              >
                Excessive idling
              </li>
              <li
                className="t"
                data-translate="driverBehaviourFirstContentSecondCardText4"
              >
                Harsh acceleration
              </li>
              <li
                className="t"
                data-translate="driverBehaviourFirstContentSecondCardText5"
              >
                Cruise control usage
              </li>
              <li
                className="t"
                data-translate="driverBehaviourFirstContentSecondCardText6"
              >
                Harsh cornering
              </li>
              <li
                className="t"
                data-translate="driverBehaviourFirstContentSecondCardText7"
              >
                Coasting
              </li>
            </ul>
          </div>
          <div className="right">
            <img alt="" src={Group7037} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="driverBehaviourFirstContentThirdCardTitle"
            >
              Oversee Driver Behaviour Scores
            </h2>
            <p
              className="t"
              data-translate="driverBehaviourFirstContentThirdCardText"
            >
              Driver behaviour score helps to see where your drivers could be
              improving. You can see drivers ranked from A (highest) to G
              (lowest) either by their overall score or category such as harsh
              braking, eco speed, etc.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7037} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="driverBehaviourFirstContentFourthCardTitle"
            >
              Driver Report
            </h2>
            <p
              className="t"
              data-translate="driverBehaviourFirstContentFourthCardText"
            >
              A performance report of any individual driver. Overviewing each
              category helps with tracking progress and finding areas for
              improvement.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7041} />
          </div>
        </div>
      </div>
    </DriverBehaviourFirstContentWrapper>
  );
};

export default DriverBehaviourFirstContent;
