import React from "react";
import styled from "styled-components";
import Group7797 from "../../img/about-us/warehouse.png";

const AboutUsSecondContentWrapper = styled.div`
  padding: 0 375px 120px;
  .wrapper {
    text-align: center;
    .wrapper-text {
      padding: 0 170px;
      h2 {
        ${(props) => props.theme.h2_48};
        color: #202945;
      }
      p {
        ${(props) => props.theme.body_20};
      }
    }
    .wrapper-image {
      margin-right: -107px;
      margin-left: -67px;
      img {
        width: 100%;
        display: block;
      }
    }
  }

  @media (max-width: 1900px) {
    padding: 0 275px 120px;
    .wrapper {
      .wrapper-text {
        padding: 0 80px;
      }
      .wrapper-image {
        margin-right: -83px;
        margin-left: -53px;
      }
    }
  }
  @media (max-width: 1439px) {
    padding: 0 50px 120px;
    .wrapper {
      .wrapper-text {
        padding: 0 80px;
        h2 {
          font-size: 28px;
          line-height: 39px;
        }
        p {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
  @media (max-width: 1023px) {
    padding: 0 20px 60px;
    .wrapper {
      .wrapper-text {
        padding: 0 30px;
      }
      .wrapper-image {
        margin-right: -68px;
        margin-left: -43px;
      }
    }
  }
  @media (max-width: 767px) {
    .wrapper {
      .wrapper-text {
        padding: 0;
      }
      .wrapper-image {
        margin-right: -35px;
        margin-left: -23px;
      }
    }
  }
`;
const AboutUsSecondContent = () => {
  return (
    <AboutUsSecondContentWrapper>
      <div className="wrapper">
        <div className="wrapper-text">
          <h2 className="t" data-translate="aboutUsSecondContentFirstCardTitle">
            Life at Icetrackr
          </h2>
          <p className="t" data-translate="aboutUsSecondContentFirstCardText">
            Icetrackr is a part of Icelabs an international company based in
            Skopje, Macedonia. Our main goal is reliable technology for
            everyone, moreover, we try to deliver working solutions and products
            that meet our clients needs, and improve their business operations
            and achieve fleet management goals.
          </p>
        </div>
        <div className="wrapper-image">
          <img src={Group7797} alt=""/>
        </div>
      </div>
    </AboutUsSecondContentWrapper>
  );
};

export default AboutUsSecondContent;
