import React from "react";
import styled from "styled-components";
import BackgroundIntro from "../../img/about-us/group-7799.svg";
import { useContext } from "react";
import { GlobalContext } from "../../Consts/GlobalContext";
import { useState } from "react";
import axios from "axios";
import successTick from "../../img/success-tick.png";

const ContactIntroWrapper = styled.div`
  margin-top: 94px;
  padding: 70px 375px 70px 0;
  background-image: url(${BackgroundIntro});
  background-repeat: no-repeat;
  background-position: -95% 110%;
  background-color: #f2f7f9;
  display: flex;
  justify-content: flex-end;
  .container {
    width: 35%;
    position: relative;
    z-index: 1;

    form {
      padding: 45px 70px;
      background-color: #fff;
      box-shadow: 0px 18px 24px #1a284d1a;
      border-radius: 10px;
      h2 {
        ${(props) => props.theme.h2_48};
        color: #202945;
        margin-bottom: 15px;
      }
      .form-group {
        > label {
          ${(props) => props.theme.body_20};
          display: block;
          margin-bottom: 10px;
        }
        > input,
        > textarea {
          width: 100%;
          display: block;
          border: 1px solid #dce3eb;
          border-radius: 3px;
          padding: 10px;
          font-size: 18px;
        }
        textarea {
          resize: none;
        }
        input:focus,
        textarea:focus {
          outline: none;
          border-color: #202945;
        }
        .accept-privacy {
          flex-basis: 100%;
          display: flex;
          align-items: center;
          padding-left: 0 !important;
          label {
            display: inline-block;
            ${(props) => props.theme.body_16};
            line-height: 19px;
          }
          input {
            width: unset;
            display: inline-block;
            margin: 0;
            margin-right: 10px;
            width: 24px;
            height: 24px;
            border: 1px solid #dce3eb;
            border-radius: 3px;
          }
        }
        .receive-quote-btn {
          display: block;
          pointer-events: unset;
          cursor: pointer;
          width: 100%;
        }
        button[disabled] {
          pointer-events: none;
          cursor: default;
          opacity: 0.5;
        }
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
    &::after,
    &::before {
      content: "";
      border-radius: 50%;
      position: absolute;
      z-index: -1;
    }
    &::before {
      top: 170px;
      left: -100px;
      width: 200px;
      height: 200px;
      background-color: #0071ce;
    }
    &::after {
      width: 100px;
      height: 100px;
      bottom: -30px;
      right: -40px;
      background-color: #8db2e3;
    }
  }
  .success-message {
    display: flex;
    position: fixed;
    opacity: 0;
    top: -30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 350px;
    background-color: white;
    color: #007e33;
    z-index: 100000;
    background-color: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 8px 10px 35px #dadde0;
    transition: all 2s ease-in-out;
    > p {
      ${(props) => props.theme.body_20};
      color: rgba(46, 217, 195, 1);
    }
    img {
      display: block;
      width: 100px;
    }
  }
  .success-message.show {
    top: 50%;
    opacity: 1;
  }
  @media (max-width: 1900px) {
    padding: 50px 275px 50px 0;
    background-position: -720% 78%;
    .container {
      width: 40%;
      form {
        padding: 15px 30px;
      }
    }
  }
  @media (max-width: 1439px) {
    padding: 50px 50px 50px 0;
    background-position: 257% 88%;
    .container {
      width: 45%;
      form {
        padding: 15px 30px;
        h2 {
          font-size: 38px;
          line-height: 50px;
          margin-bottom: 10px;
        }
        .form-group {
          > label {
            font-size: 18px;
            line-height: 25px;
          }
        }
      }
    }
  }

  @media (max-width: 1023px) {
    padding: 50px 20px 50px 0;
    background-position: 93% 88%;
    .container {
      width: 55%;
      form {
        h2 {
          font-size: 30px;
          line-height: 40px;
        }
        .form-group {
          > label {
            font-size: 18px;
            line-height: 25px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding: 50px 20px;
    .container {
      width: 100%;
      form {
        h2 {
          font-size: 30px;
          line-height: 40px;
        }
        .form-group {
          > label {
            font-size: 18px;
            line-height: 25px;
          }
        }
      }
    }
  }
`;
const ContactIntro = () => {
  const [state, setState] = useState({
    fullName: "",
    company: "",
    phone: "",
    email: "",
    message: "",
  });
  const [acceptPP, setAcceptPP] = useState(false);
  const [success, setSuccess] = useState(false);
  const { bearerToken } = useContext(GlobalContext);

  const handleInputChange = (event) => {
    setState((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const receiveQuote = (e) => {
    e.preventDefault();
    if (
      state.fullName === "" ||
      state.phone === "" ||
      state.email === "" ||
      state.company === "" ||
      state.message === ""
    ) {
      return;
    }

    const data = {
      name: state.fullName,
      phone: state.phone,
      email: state.email,
      company: state.company,
      message: state.message,
    };
    axios({
      method: "post",
      url: `${window.baseurl}/restful/v2/quote`,
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
        Authorization: bearerToken,
      },
      data: data,
    }).then((res) => {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
      console.log(res);
      setState({
        fullName: "",
        company: "",
        phone: "",
        email: "",
        message: "",
      });
      setAcceptPP(false);
    });
  };

  return (
    <ContactIntroWrapper>
      <div className="container">
        <form onSubmit={(e) => receiveQuote(e)}>
          <h2 className="t" data-translate="contactIntroTitle">
            Contact us
          </h2>
          <div className="form-group">
            <label htmlFor="fullName" className="t" data-translate="fullName">
              Full Name
            </label>
            <input
              type="text"
              name="fullName"
              id="fullName"
              value={state.fullName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="company" className="t" data-translate="company">
              Company
            </label>
            <input
              type="text"
              name="company"
              id="company"
              value={state.company}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone" className="t" data-translate="phone">
              Phone
            </label>
            <input
              type="text"
              name="phone"
              id="phone"
              value={state.phone}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email" className="t" data-translate="email">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={state.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message" className="t" data-translate="message">
              Message
            </label>
            <textarea
              rows="3"
              name="message"
              id="message"
              value={state.message}
              onChange={handleInputChange}
            />
            <div className="accept-privacy">
              <input
                type="checkbox"
                name="acceptPP"
                checked={acceptPP}
                onChange={(e) => setAcceptPP(e.target.checked)}
                required
              />
              <label
                htmlFor="acceptPP"
                className="t"
                data-translate="privacyPolicy"
              >
                I accept that the information I provide is processed according
                to Ice Trackr privacy policy.
              </label>
            </div>
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="receive-quote-btn"
              disabled={acceptPP ? "" : "true"}
            >
              <span className="t" data-translate="submitButton">
                Submit
              </span>
            </button>
          </div>
        </form>
      </div>
      <section>
        <div className={success ? "success-message show" : "success-message"}>
          <img src={successTick} alt="" />
          <p>The Quote has been successfully sent.</p>
        </div>
      </section>
    </ContactIntroWrapper>
  );
};

export default ContactIntro;
