import React from "react";
import styled from "styled-components";
import BackgroundIntro from "../../img/about-us/group-7799.svg";
import Group7849 from "../../img/about-us/group-7849.svg";

const AboutUsStatisticsWrapper = styled.div`
  margin-top: -330px;
  padding: 360px 0 400px;
  background-image: url(${BackgroundIntro});
  background-position: 115% 50%;
  background-repeat: no-repeat;
  .container {
    display: flex;
    justify-content: center;
    .cards {
      position: relative;
      margin-right: -12px;
      margin-left: -46px;
      img {
        width: 100%;
        display: block;
      }
      p,
      span {
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
      }
      p {
        top: 21%;
        font: normal normal 600 99px/128px TT Commons;
        color: #0071ce;
      }
      span {
        font: normal normal normal 32px/39px TT Commons;
        color: #6b7ba4;
        top: 55%;
      }
      &:last-child {
        margin-right: -46px;
      }
    }
  }

  @media (max-width: 1439px) {
    .container {
      padding: 0 50px;
      .cards {
        p {
          // top: 21%;
          font: normal normal 600 80px/112px TT Commons;
        }
        span {
          font: normal normal normal 28px/39px TT Commons;
          top: 50%;
        }
      }
    }
  }
  @media (max-width: 1023px) {
    .container {
      padding: 0 20px;
      .cards {
        p {
          font: normal normal 600 60px/84px TT Commons;
        }
        span {
          font: normal normal normal 24px/33px TT Commons;
        }
      }
    }
  }
  @media (max-width: 767px) {
    background-position: 60% 50%;
    .container {
      display: block;
      .cards {
        margin-right: 0;
        margin-left: 0;
        p {
          // top: 25%;
          font: normal normal 600 80px/112px TT Commons;
        }
        span {
          font: normal normal normal 28px/39px TT Commons;
          top: 50%;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;
const AboutUsStatistics = () => {
  return (
    <AboutUsStatisticsWrapper>
      <div className="container">
        <div className="cards">
          <img src={Group7849} alt=""/>
          <p>6461</p>
          <span className="t" data-translate="aboutUsStatisticsFirstCardTitle">
            Devices installed
          </span>
        </div>
        <div className="cards">
          <img src={Group7849} alt=""/>
          <p>120</p>
          <span className="t" data-translate="aboutUsStatisticsSecondCardTitle">
            Satisfied clients
          </span>
        </div>
        <div className="cards">
          <img src={Group7849} alt=""/>
          <p>10</p>
          <span className="t" data-translate="aboutUsStatisticsThirdCardTitle">
            Years of experience
          </span>
        </div>
        <div className="cards">
          <img src={Group7849} alt=""/>
          <p>4</p>
          <span className="t" data-translate="aboutUsStatisticsFourthCardTitle">
            Partners
          </span>
        </div>
      </div>
    </AboutUsStatisticsWrapper>
  );
};

export default AboutUsStatistics;
