import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
const FooterWrapper = styled.div`
  padding: 28px 375px;
  background-color: #f2f7f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    flex-basis: 30%;
    a {
      display: block;
      text-decoration: none;
      ${(props) => props.theme.footer_14};
      &:first-child {
        margin-right: 18.5px;
      }
      &:nth-child(2) {
        margin-left: 18.5px;
      }
    }
  }
  .middle {
    flex-basis: 30%;
    a {
      text-decoration: none;
      ${(props) => props.theme.footer_14};
    }
  }
  .right {
    display: flex;
    .social-links {
      width: 24px;
      height: 24px;
      color: #6b7ba4;
      display: block;
      &:not(:last-of-type) {
        margin-right: 24px;
      }
    }
  }

  @media (max-width: 1900px) {
    padding: 28px 275px;
  }
  @media (max-width: 1439px) {
    padding: 28px 50px;
  }
  @media (max-width: 1023px) {
    padding: 28px 20px;
  }
  @media (max-width: 767px) {
    display: block;
    text-align: center;
    padding: 10px 0;
    .left {
      justify-content: center;
      margin-bottom: 8px;
    }
    .right {
      justify-content: center;
      margin-top: 8px;
    }
  }
`;
const Footer = () => {
  return (
    <FooterWrapper>
      <div className="left">
        <Link to="/privacy-policy" className="t" data-translate="privacyPolicyFooter">
          Privacy policy
        </Link>
        |
        <Link to="/website-terms" className="t" data-translate="websiteTerms">
          Website terms
        </Link>
      </div>
      <div className="middle">
        <Link to="/">
          &copy; 2021 Ice Trackr.
          <span className="t" data-translate="allRR">
            All rights reserved.
          </span>
        </Link>
      </div>
      <div className="right">
        <a
          href="https://www.facebook.com/icetrackr/"
          className="social-links"
          target="_blank"
        >
          <Icon>facebook_rounded</Icon>
        </a>
        <a href="/" className="social-links" target="_blank">
          <YouTubeIcon />
        </a>
        <a href="/" className="social-links" target="_blank">
          <TwitterIcon />
        </a>
        <a href="/" className="social-links" target="_blank">
          <LinkedInIcon />
        </a>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
