import React from "react";
import styled from "styled-components";
import Group5907 from "../../img/home-page/group-5907.png";
import Group5908 from "../../img/home-page/group-5908.png";
import Group5909 from "../../img/home-page/group-5909.png";

const ProductsIntroWrapper = styled.div`
  padding: 105px 335px 225px;
  margin-top: 94px;
  background-color: #f2f7f9;
  position: relative;
  h1 {
    ${(props) => props.theme.h1_64};
    text-align: center;
    margin-bottom: 35px;
  }

  p {
    ${(props) => props.theme.body_24};
    text-align: center;
    padding: 0 240px;
  }

  .intro-images {
    display: flex;
    margin-top: 150px;

    div {
      img {
        display: block;
        box-shadow: -4px 10px 60px #1a284d17;
        transition: all 0.6s ease-out;
        border-radius: 5px;
        width: 100%;
      }
    }
    div:nth-child(2) {
      flex-basis: 20%;
      img {
        position: relative;
        z-index: 2;
        transform: scale(1.4);
      }
    }
    div:first-child {
      flex-basis: 40%;
      position: relative;
      &::before {
        content: "";
        width: 62px;
        height: 62px;
        background-color: #0071ce;
        position: absolute;
        bottom: -85px;
        right: 20px;
        border-radius: 50%;
        transition: all 0.6s ease-out;
      }
    }
    div:last-child {
      flex-basis: 40%;
      position: relative;
      z-index: 1;
      &::before {
        content: "";
        width: 54px;
        height: 54px;
        background-color: #0071ce;
        position: absolute;
        top: -25px;
        left: 150px;
        border-radius: 50%;
        transition: all 0.6s ease-out;
        z-index: -1;
      }
    }
    &:hover {
      div:nth-child(2) {
        img {
          transform: scale(1.2);
        }
      }
      div:first-child {
        img {
          transform: translateX(-50px);
        }
        &::before {
          bottom: 0;
          right: 70px;
        }
      }
      div:last-child {
        img {
          transform: translateX(50px);
        }
        &::before {
          top: -50px;
          left: 0px;
        }
      }
    }
  }
  &::before {
    content: "";
    width: 106px;
    height: 106px;
    background-color: #8db2e3;
    position: absolute;
    bottom: 335px;
    left: -53px;
    border-radius: 50%;
  }
  &::after {
    content: "";
    width: 62px;
    height: 62px;
    background-color: #8db2e3;
    position: absolute;
    top: 160px;
    right: 135px;
    border-radius: 50%;
  }
  .circle {
    width: 26px;
    height: 26px;
    background-color: #0071ce;
    position: absolute;
    bottom: 100px;
    right: 34px;
    border-radius: 50%;
  }

  @media (max-width: 1900px) {
    padding: 105px 150px 225px;
    p {
      padding: 0 140px;
    }
    .intro-images {
      div:nth-child(2) {
        img {
          transform: scale(1.3);
        }
      }
      div:first-child {
        &::before {
          bottom: -65px;
          right: 0px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 105px 50px 225px;
    h1 {
      font-size: 48px;
      line-height: 67px;
    }
    p {
      font-size: 20px;
      line-height: 28px;
    }

    .intro-images {
      &:hover {
        div:nth-child(2) {
          img {
            transform: scale(1.15);
          }
        }
        div:first-child {
          img {
            transform: translateX(-35px);
          }
          &::before {
            bottom: 0;
            right: 70px;
          }
        }
        div:last-child {
          img {
            transform: translateX(35px);
          }
          &::before {
            top: -40px;
            left: -18px;
          }
        }
      }
    }
  }

  @media (max-width: 1023px) {
    padding: 50px 20px 125px;
    p {
      padding: 0 40px;
    }
    .intro-images {
      div:first-child {
        &::before {
          bottom: -53px;
          right: -10px;
        }
      }
      &:hover {
        div:nth-child(2) {
          img {
            transform: scale(1.3);
          }
        }
        div:first-child {
          img {
            transform: translateX(0);
          }
          &::before {
            bottom: -53px;
            right: -10px;
          }
        }
        div:last-child {
          img {
            transform: translateX(0);
          }
          &::before {
            top: -25px;
            left: 150px;
          }
        }
      }
    }

    &::after {
      top: 30px;
      right: 20px;
    }
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 38px;
      line-height: 53px;
    }
    p {
      padding: 0;
    }
    .intro-images {
      display: none;
    }

    &::after {
      top: 0px;
      right: 0px;
      width: 42px;
      height: 42px;
    }
    &::before {
      bottom: 50px;
      left: -31px;
      width: 62px;
      height: 62px;
    }
  }
`;

const ProductsIntro = () => {
  return (
    <ProductsIntroWrapper>
      <h1 className="t" data-translate="productsIntroTitle">
        Fleet Management and Asset Tracking Software and Hardware
      </h1>
      <p className="t" data-translate="productsIntroText">
        IceTrackr provides fleet management and asset tracking solutions that
        will suit any company, regardless of its field of business and fleet
        size
      </p>
      <div className="intro-images">
        <div>
          <img src={Group5907} alt="group-5907" />
        </div>
        <div>
          <img src={Group5909} alt="group-5909" />
        </div>

        <div>
          <img src={Group5908} alt="group-5908" />
        </div>
      </div>
      <div className="circle"></div>
    </ProductsIntroWrapper>
  );
};

export default ProductsIntro;
