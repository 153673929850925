import React, { useContext } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styled from "styled-components";
import { GlobalContext } from "../../Consts/GlobalContext";
const UnsubscribeSnackbarWrapper = styled.div`
  position: fixed;
  z-index: 100;
  bottom: -100px;
  left: 20px;
  background-color: rgb(77, 154, 81);
  border-radius: 5px;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  transition: all 1s ease-in-out;
  p {
    ${(props) => props.theme.body_20};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const UnsubscribeSnackbar = () => {
  const { unsubscribe } = useContext(GlobalContext);

  return (
    <UnsubscribeSnackbarWrapper
      className={unsubscribe && "unsubscribe-snackbar"}
    >
      <p>
        <CheckCircleOutlineIcon />
        Successfully unsubscribed
      </p>
    </UnsubscribeSnackbarWrapper>
  );
};

export default UnsubscribeSnackbar;
