import React from "react";
import CookiesSettings from "../CookiesSettings/CookiesSettings";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import ContactInfo from "./ContactInfo";
import ContactIntro from "./ContactIntro";
import ContactOffer from "./ContactOffer";
import ContactTeam from "./ContactTeam";

const ContactPage = () => {
  return (
    <>
      <MenuFixed />
      <ContactIntro />
      <ContactInfo />
      <ContactOffer />
      <ContactTeam />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default ContactPage;
