import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../Consts/GlobalContext";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";

const PrivacyPolicyFirstContentWrapper = styled.div`
  padding: 0px 375px 200px;
  position: relative;
  z-index: 1;
  .container {
    padding: 85px 40px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    .inner-container {
      padding: 0 160px;
      > span {
        text-align: justify;
        display: block;
        ${(props) => props.theme.body_20};
        a {
          color: rgb(141 178 227);
          font-weight: 700;
        }
        > span {
          color: rgb(141 178 227);
          font-weight: 700;
          text-decoration: underline;
        }
        &:first-of-type {
          margin-bottom: 30px;
        }
      }

      .privacy-contact {
        text-align: center;
        ${(props) => props.theme.body_24};
        > span {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            display: block;
            margin-right: 5px;
            color: rgb(141 178 227);
          }
          a {
            color: ${(props) => props.theme.color_blue};
            text-decoration: none;
          }
          &:first-of-type {
            font-size: 36px;
            line-height: 47px;
          }
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0 275px 150px;
    .container {
      .inner-container {
        padding: 0;
      }
    }
  }
  @media (max-width: 1439px) {
    padding: 0 50px 100px;
    .container {
      .inner-container {
        padding: 0 40px;
      }
    }
  }
  @media (max-width: 1023px) {
    padding: 0 20px 50px;
    .container {
      padding: 50px 15px;
    }
  }
  @media (max-width: 767px) {
    .container {
      .inner-container {
        padding: 0;
        > span {
          font-size: 18px;
          line-height: 25px;
        }
        p {
          font-size: 18px;
          line-height: 25px;
        }

        .privacy-contact {
          font-size: 16px;
          line-height: 22px;
          > span {
            flex-direction: column;
            &:first-child {
              font-size: 26px;
              line-height: 36px;
            }
          }
        }
      }
    }
  }
`;
const PrivacyPolicyFirstContent = () => {
  const { lang } = useContext(GlobalContext);
  return (
    <PrivacyPolicyFirstContentWrapper>
      <div className="container">
        <div className="inner-container">
          {lang === "en" ? (
            <span>
              This Privacy Policy explains how Ice Trackr processes information
              about individuals, including personal data, in relation to the use
              of the <span>icetrackr.com</span> website and the fleet management
              solution. Here you will find detailed information about what type
              of information we collect and why, what we do with it, and what
              are your rights. The controller of your personal data is the Ice
              Trackr group entity to which your personal data has been submitted
              on the basis of a contractual or pre-contractual relationship, or
              whose Services you use or intend to use. You can see our contacts
              in the <Link to="/contact">Contact information</Link> section. By
              accessing or using this website or any of our Services, you agree
              to the terms set out in this Privacy Policy,{" "}
              <Link to="/cookie-policy">Cookie Policy</Link> and other terms and
              policies published on our website. If you do not agree to this
              Privacy Policy, you must leave this website and discontinue all
              use of any of our Services. If you have any questions about your
              personal data or this Privacy Policy, or if you would like to file
              a complaint about how we process your personal data, please
              contact us by using the contact details below:
            </span>
          ) : (
            <span>
              Оваа Политика за приватност објаснува како Ice Tracker ги
              обработува информациите за поединци, вклучувајќи ги и личните
              податоци, во врска со употребата на веб-локацијата{" "}
              <span>icetrackr.com</span> и решението за управување со возниот
              парк. Овде ќе најдете детални информации за тоа каков тип на
              информации собираме и зошто, што правиме со тоа, и кои се вашите
              права. Контролорот на вашата личните податоци се ентитет на
              групата Ice Trackr на кој вашите лични е доставен податок врз
              основа на договорен или преддоговорен однос, или чии Услуги ги
              користите или имате намера да ги користите употреба. Можете да ги
              видите нашите контакти во Секцијата{" "}
              <Link to="/contact">Информации за контакт</Link>. Со пристапување
              или користејќи ја оваа веб-локација или која било од нашите
              услуги, се согласувате со условите наведено во оваа Политика за
              приватност, <Link to="/cookie-policy">Политика за колачиња</Link>{" "}
              и други услови и политики објавени на нашата веб-страница. Ако не
              се согласувате со ова Политика за приватност, мора да ја напуштите
              оваа веб-локација и да ја прекинете сета употреба на која било од
              нашите услуги. Ако имате какви било прашања за вашиот лични
              податоци или оваа Политика за приватност, или ако сакате да
              поднесете а жалба за тоа како ги обработуваме вашите лични
              податоци, ве молиме контактирајте со нас со користење на деталите
              за контакт подолу:
            </span>
          )}
          <div className="privacy-contact">
            <span className="t" data-translate="iceTrackrMacedonia">
              Ice Trackr Macedonia
            </span>
            <span>
              <LocalPhoneRoundedIcon />
              +389 78 - 336 / 032
            </span>
            <span>
              <EmailRoundedIcon />
              <a href="mailto:contact@icelabs.mk">contact@icelabs.mk</a>{" "}
            </span>
            <span>
              <LocationOnRoundedIcon />
              <span className="t" data-translate="iceTrackrAddress">
                Trifun Hadzijanev 12/3-17 Skopje, Macedonia
              </span>
            </span>
          </div>
        </div>
      </div>
    </PrivacyPolicyFirstContentWrapper>
  );
};

export default PrivacyPolicyFirstContent;
