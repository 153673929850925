import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import solutionsCategories from "../Menu/SolutionsCategories";
import { useContext } from "react";
import { GlobalContext } from "../../Consts/GlobalContext";
import arrow from "../../img/arrow.svg";

const FooterLinksWrapper = styled.div`
  padding: 140px 475px;
  display: flex;
  justify-content: space-between;
  .footer-titles {
    ${(props) => props.theme.footer_titles};
    .arrow {
      margin-left: 5px;
      transition: all 0.6s ease-out;
      width: 15px;
      display: none;
    }
  }
  .footer-titles.active {
    .arrow {
      transform: rotate(180deg);
    }
  }
  .left {
    flex-basis: 65%;
    .left-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      a {
        flex-basis: 45%;
      }
    }
  }

  .footer-links {
    text-transform: capitalize;
    text-decoration: none;
    margin-top: 30px;
    display: block;
    ${(props) => props.theme.body_20};
  }
  .right {
    flex-basis: 30%;
    .right-products-links,
    .right-about-links {
      margin-bottom: 80px;
    }
  }

  @media (max-width: 1900px) {
    padding: 140px 325px;
    .footer-links {
      font-size: 16px;
    }
    .right {
      flex-basis: 25%;
    }
  }
  @media (max-width: 1439px) {
    padding: 100px 50px;
    justify-content: space-between;
  }
  @media (max-width: 1023px) {
    padding: 50px 20px;
    .left {
      flex-basis: 70%;
    }
    .right {
      flex-basis: 20%;
    }
  }

  @media (max-width: 767px) {
    display: block;
    .footer-titles {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .arrow {
        display: inline-block;
      }
    }
    .left {
      margin-bottom: 40px;
      .left-wrapper {
        max-height: 0;
        overflow: hidden;
        transition: all 0.6s ease-out;
      }
      .open {
        max-height: 1000px;
      }
    }
    .right {
      .right-products-links {
        .right-products-mobile {
          max-height: 0;
          overflow: hidden;
          transition: all 0.6s ease-out;
        }
        .open {
          max-height: 200px;
        }
      }
      .right-about-links {
        .right-about-mobile {
          max-height: 0;
          overflow: hidden;
          transition: all 0.6s ease-out;
        }
        .open {
          max-height: 200px;
        }
      }
      .right-blog-links {
        .right-blog-mobile {
          max-height: 0;
          overflow: hidden;
          transition: all 0.6s ease-out;
        }
        .open {
          max-height: 100px;
        }
      }

      .right-products-links,
      .right-about-links {
        margin-bottom: 40px;
      }
    }
  }
`;
const FooterLinks = () => {
  const { lang } = useContext(GlobalContext);
  const handleMobileSubOpen = (e) => {
    if (window.outerWidth < 426) {
      e.currentTarget.nextElementSibling.classList.toggle("open");
      e.currentTarget.classList.toggle("active");
    } else {
      return;
    }
  };
  return (
    <FooterLinksWrapper>
      <div className="left">
        <p className="footer-titles" onClick={(e) => handleMobileSubOpen(e)}>
          <span className="t" data-translate="solutions">
            Solutions
          </span>
          <img src={arrow} alt="arrow" className="arrow" />
        </p>
        <div className="left-wrapper">
          {solutionsCategories.map((el) => (
            <Link
              to={`/solutions/${el.title.replaceAll(" ", "-").toLowerCase()}`}
              className="footer-links"
              key={el.id}
            >
              {lang === "en" ? el.title : el.titleMK}
            </Link>
          ))}
        </div>
      </div>
      <div className="right">
        <div className="right-products-links">
          <p className="footer-titles" onClick={(e) => handleMobileSubOpen(e)}>
            <span className="t" data-translate="products">
              Products
            </span>
            <img src={arrow} alt="arrow" className="arrow" />
          </p>
          <div className="right-products-mobile">
            <Link to="/products/lite" className="footer-links">
              IceTrackr Lite
            </Link>
            <Link to="/products/pro" className="footer-links">
              IceTrackr Pro
            </Link>
          </div>
        </div>
        <div className="right-about-links">
          <p className="footer-titles" onClick={(e) => handleMobileSubOpen(e)}>
            <span className="t" data-translate="aboutUs">
              About Us
            </span>
            <img src={arrow} alt="arrow" className="arrow" />
          </p>
          <div className="right-about-mobile">
            <Link to="/about-us" className="footer-links">
              <span className="t" data-translate="aboutUs">
                About Us
              </span>
            </Link>
          </div>
        </div>
        <div className="right-blog-links">
          <p className="footer-titles" onClick={(e) => handleMobileSubOpen(e)}>
            <span className="t" data-translate="blog">
              Blog
            </span>
            <img src={arrow} alt="arrow" className="arrow" />
          </p>
          <div className="right-blog-mobile">
            <Link to="/" className="footer-links t" data-translate="blog">
              Blog
            </Link>
          </div>
        </div>
      </div>
    </FooterLinksWrapper>
  );
};

export default FooterLinks;
