import React, { useContext, useEffect } from "react";
import axios from "axios";
import { GlobalContext } from "../../Consts/GlobalContext";
const UnsubscribeTemp = () => {
  const { unsubscribe, setUnsubscribe,bearerToken } = useContext(GlobalContext);

  useEffect(() => {
    let emailSubscribed = window.location.search;
    axios({
      method: "DELETE",
      url: `${window.baseurl}/restful/v2/subscribe${emailSubscribed}`,
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
        Authorization: bearerToken,
      },
    })
      .then((response) => {
        setUnsubscribe(true);
        localStorage.setItem("unsubscribe", unsubscribe);
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return <>Redirecting...</>;
};

export default UnsubscribeTemp;
