import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GlobalContext } from "../../Consts/GlobalContext";

const CookiesSettingsWrapper = styled.div`
  position: fixed;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 0;
  visibility: hidden;
  top: -101%;
  transition: all 0.6s ease-in-out;
  .blank-space {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .wrapper {
    overflow-y: auto;
    background-color: #fff;
    width: 70%;
    height: 96%;
    padding: 30px;
    border-radius: 15px;
    z-index: 2;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(46, 217, 195, 0.7);
      border-radius: 10px;
      background-clip: content-box;
    }
    .top-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      h2 {
        ${(props) => props.theme.h2_48};
        color: ${(props) => props.theme.color_blue};
      }
      .close-cookies {
        width: 25px;
        height: 25px;
        position: relative;
        span {
          display: block;
          width: 100%;
          height: 5px;
          background-color: #000;
          position: absolute;
          top: 11px;
          left: 0;
          border-radius: 20px;
          &:first-of-type {
            transform: rotate(45deg);
          }
          &:last-of-type {
            transform: rotate(-45deg);
          }
        }
      }
    }
    .cards {
      p {
        ${(props) => props.theme.body_24};
        font-weight: bold;
        span {
          ${(props) => props.theme.body_24};
          font-weight: bold;
        }
      }
      span {
        ${(props) => props.theme.body_20};
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
          padding: 20px 50px;
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  @media (max-width: 767px) {
    .wrapper {
      width: 95%;
      .top-inner {
        h2 {
          font-size: 25px;
          line-height: 30px;
        }
      }
      .cards {
        p {
          font-size: 20px;
          line-height: 28px;
          span {
            font-size: 20px;
            line-height: 28px;
          }
        }
        span {
          font-size: 16px;
          line-height: 22px;
        }
        &:last-child {
          a {
            padding: 15px 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
  @media (max-width: 404px) {
    .wrapper {
      padding: 20px;
      .top-inner {
        h2 {
          font-size: 22px;
          line-height: 25px;
        }
      }
      .cards {
        p {
          font-size: 18px;
          line-height: 25px;
          span {
            font-size: 18px;
            line-height: 25px;
          }
        }
        span {
          font-size: 15px;
          line-height: 20px;
        }
        &:last-child {
          a {
            padding: 15px 25px;
          }
        }
      }
    }
  }
`;

const CookiesSettings = () => {
  const { cookies, setCookies, openCookies, setOpenCookies, lang } =
    useContext(GlobalContext);

  const handleCheckboxChange = (e) => {
    setCookies({ ...cookies, [e.target.name]: e.target.checked });
  };

  const handleAllowAllCookies = (e) => {
    e.preventDefault();
    setCookies({
      ...cookies,
      analytical: true,
      marketing: true,
    });
  };

  const handleSaveCookies = (e) => {
    e.preventDefault();
    localStorage.setItem("cookies", JSON.stringify(cookies));
    setOpenCookies(false);
  };
  const handleCloseCookies = (e) => {
    localStorage.setItem("cookies", JSON.stringify(cookies));
    setOpenCookies(false);
  };

  useEffect(() => {
    let cookies = JSON.parse(localStorage.getItem("cookies"));
    if (cookies) {
      setCookies(cookies);
    } else {
      setOpenCookies(true);
    }
  }, []);
  return (
    <CookiesSettingsWrapper className={openCookies ? "open-cookies" : ""}>
      <div className="blank-space" onClick={() => handleCloseCookies()}></div>
      <div className="wrapper">
        <div className="top-inner">
          <h2 className="t" data-translate="cookiesSettingsTitle">
            Cookies Settings
          </h2>
          <div className="close-cookies" onClick={() => handleCloseCookies()}>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="cards">
          <p className="t" data-translate="cookiesSettingsPrivacyTitle">
            Your Privacy
          </p>
          <span className="t" data-translate="cookiesSettingsPrivacyText">
            When you visit any web site, it may store or retrieve information on
            your browser, mostly in the form of cookies. This information might
            be about you, your preferences or your device and is mostly used to
            make the site work as you expect it to. The information does not
            usually directly identify you, but it can give you a more
            personalised web experience. Because we respect your right to
            privacy, you can choose not to allow some types of cookies. Click on
            the different category headings to find out more and change our
            default settings. However, blocking some types of cookies may impact
            your experience of the site and the services we are able to offer.
          </span>
        </div>
        <div className="cards">
          <p>
            <input
              type="checkbox"
              id="strictly-necessary-cookies"
              checked={cookies.necessary}
              disabled
            />
            <span className="t" data-translate="cookiesSettingsNecessaryTitle">
              Strictly necessary cookies
            </span>
          </p>
          <span className="t" data-translate="cookiesSettingsNecessaryText">
            These cookies are necessary for the website to function and cannot
            be switched off in our systems. They are usually only set in
            response to actions made by you which amount to a request for
            services, such as setting your privacy preferences, logging in or
            filling in forms. You can set your browser to block or alert you
            about these cookies, but some parts of the site will not then work.
            These cookies do not store any personally identifiable information.
          </span>
        </div>
        <div className="cards">
          <p>
            <input
              type="checkbox"
              id="analytical-cookies"
              name="analytical"
              onChange={(e) => handleCheckboxChange(e)}
              checked={cookies.analytical}
            />
            <span className="t" data-translate="cookiesSettingsAnalyticalTitle">
              Analytical cookies
            </span>
          </p>
          <span className="t" data-translate="cookiesSettingsAnalyticalText">
            These cookies allow us to count visits and traffic sources so we can
            measure and improve the performance of our site. They help us to
            know which pages are the most and least popular and see how visitors
            move around the site. All information these cookies collect is
            aggregated and therefore anonymous.
          </span>
        </div>
        <div className="cards">
          <p>
            <input
              type="checkbox"
              id="marketing-cookies"
              name="marketing"
              onChange={(e) => handleCheckboxChange(e)}
              checked={cookies.marketing}
            />
            <span className="t" data-translate="cookiesSettingsMarketingTitle">
              Marketing cookies
            </span>
          </p>
          <span className="t" data-translate="cookiesSettingsMarketingText">
            These cookies may be set through our site by our advertising
            partners. They may be used by those companies to build a profile of
            your interests and show you relevant adverts on other sites. They do
            not store directly personal information, but are based on uniquely
            identifying your browser and internet device. If you do not allow
            these cookies, you will experience less targeted advertising.
          </span>
        </div>
        <div className="cards">
          <Link
            to="/"
            className="receive-quote-btn"
            onClick={(e) => handleAllowAllCookies(e)}
          >
            <span>{lang === "en" ? "Allow All" : "Селектирај ги сите"}</span>
          </Link>
          <Link
            to="/"
            className="receive-quote-btn"
            onClick={(e) => handleSaveCookies(e)}
          >
            <span>
              {lang === "en" ? "Save Settings" : "Зачувај ги поставките"}
            </span>
          </Link>
        </div>
      </div>
    </CookiesSettingsWrapper>
  );
};

export default CookiesSettings;
