import React from "react";
import styled from "styled-components";
import Group6449 from "../../img/tachograph/group-6449.png";
import Teltonika from "../../img/tachograph/teltonika-FMB630.png";
import Group6438 from "../../img/tachograph/group-6438.svg";
import { Link } from "react-router-dom";

const TachographThirdContentWrapper = styled.div`
  padding: 0px 375px 200px;
  .container {
    background-color: #fff;
    h2 {
      ${(props) => props.theme.h2_48};
      color: #202945;
      text-align: center;
    }
    .container-inner {
      display: flex;
      margin-top: 100px;
      margin-left: -15px;
      margin-right: -15px;
      .container-cards {
        flex-basis: 33.3333%;
        padding-right: 15px;
        padding-left: 15px;
        .container-cards-inner {
          position: relative;
          overflow: hidden;
          display: block;
          text-decoration: none;
          box-shadow: 0px 4px 10px #1a284d14;
          border-radius: 5px;
          height: 100%;
          padding: 30px 10px 60px;
          text-align: center;
          p {
            ${(props) => props.theme.body_24};
            color: #6b7ba4;
            position: relative;
            z-index: 1;
          }
          img {
            display: block;
            width: 220px;
            height: 220px;
            margin: 0 auto;
            position: relative;
            z-index: 1;
          }
          span {
            display: none;
            ${(props) => props.theme.body_20};
            color: white;
            position: absolute;
            z-index: 1;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
          }
          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -25px;
            width: 20px;
            height: 20px;
            background-color: #0071ce;
            border-radius: 50%;
            transform: scale(1, 1) translateX(-50%);
            transition: bottom 0.1s ease-in-out, transform 0.6s ease-in-out,
              width 0.6s ease-in-out, height 0.6s ease-in-out;
          }
          &:hover {
            p {
              color: white;
            }
            span {
              display: block;
            }
          }
          &:hover::after {
            bottom: 0;
            right: 0;
            width: 500px;
            height: 500px;
            transition: all 0.6s ease-in-out;
            transform: scale(2, 2) translateX(-50%);
          }
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0px 275px 150px;
    .container {
      h2 {
        font-size: 38px;
        line-height: 53px;
      }
      .container-inner {
        margin-top: 50px;
      }
    }
  }
  @media (max-width: 1439px) {
    padding: 0px 50px 100px;
  }

  @media (max-width: 1023px) {
    padding: 0px 20px 100px;
    .container {
      h2 {
        font-size: 30px;
        line-height: 42px;
        margin-bottom: 30px;
      }
      .container-inner {
        margin-top: -15px;
        margin-bottom: -15px;
        flex-wrap: wrap;
        .container-cards {
          flex-basis: 50%;
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .container {
      h2 {
        font-size: 25px;
        line-height: 35px;
      }
      .container-inner {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        flex-wrap: wrap;
        display: block;
        .container-cards {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 0;
          padding-right: 0;
          &:not(:last-child) {
            margin-bottom: 35px;
          }
        }
      }
    }
  }
`;
const TachographThirdContent = () => {
  return (
    <TachographThirdContentWrapper>
      <div className="container">
        <h2 className="t" data-translate="tachographThirdContentTitle">
          Devices with remote tachograph download support
        </h2>
        <div className="container-inner">
          <div className="container-cards">
            <Link to="/" className="container-cards-inner">
              <img alt="" src={Teltonika} />
              <p>FMC 640</p>
              <span
                className="t"
                data-translate="tachographThirdContentFirstCardText"
              >
                - Learn More -
              </span>
            </Link>
          </div>
          <div className="container-cards">
            <Link to="/" className="container-cards-inner">
              <img alt="" src={Teltonika} />
              <p>FMM 640</p>
              <span
                className="t"
                data-translate="tachographThirdContentFirstCardText"
              >
                - Learn More -
              </span>
            </Link>
          </div>
          <div className="container-cards">
            <Link to="/" className="container-cards-inner">
              <img alt="" src={Teltonika} />
              <p>FMB 640</p>
              <span
                className="t"
                data-translate="tachographThirdContentFirstCardText"
              >
                - Learn More -
              </span>
            </Link>
          </div>
        </div>
      </div>
    </TachographThirdContentWrapper>
  );
};

export default TachographThirdContent;
