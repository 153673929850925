import React from "react";
import styled from "styled-components";
import Group7444 from "../../img/temperature-control/group-7444.svg";
import Group7029 from "../../img/temperature-control/group-7029.png";

const TemperatureControlSecondContentWrapper = styled.div`
  padding: 0px 375px 200px;
  margin-top: -115px;
  position: relative;
  z-index: 1;
  .container {
    padding: 80px 100px 70px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        margin-right: 30px;
        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
          ${(props) => props.theme.body_20};
        }
      }

      .right {
        img {
          width: 370px;
          display: block;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0px 275px 150px;
    .container {
      padding: 30px 25px 40px;
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }
  @media (max-width: 1439px) {
    padding: 0px 50px 100px;
  }

  @media (max-width: 1023px) {
    padding: 0px 20px 50px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            width: 250px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      .container-cards {
        display: block;
        .left {
          margin-right: 0;
          margin-bottom: 15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
      }
    }
  }
`;
const TemperatureControlSecondContent = () => {
  return (
    <TemperatureControlSecondContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="temperatureControlSecondContentFirstCardTitle">Analyse Engine Hours and Fuel Consumption</h2>
            <p className="t" data-translate="temperatureControlSecondContentFirstCardText">
              See how many hours have Carrier and Thermo King units operated in
              different power modes (diesel, electric, etc.), calculate their
              fuel consumption and costs, and eliminate the risk of fuel theft.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7444} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="temperatureControlSecondContentSecondCardTitle">Simple Device Setup</h2>
            <p className="t" data-translate="temperatureControlSecondContentSecondCardText">
              You need only two devices to start using the solution - Carrier
              gateway, Thermo King iBox, or Thermo King Bluebox that is
              connected to the refrigeration unit and IceTrackr Expert that will
              transfer the real-time data directly to IceTrackr system.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7444} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="temperatureControlSecondContentThirdCardTitle">Integrations With Your Business Systems</h2>
            <p className="t" data-translate="temperatureControlSecondContentThirdCardText">
              Use our API integration feature to send the refrigeration unit
              data and all the important alert information to your internal
              business systems.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7029} />
          </div>
        </div>
      </div>
    </TemperatureControlSecondContentWrapper>
  );
};

export default TemperatureControlSecondContent;
