import React from "react";
import CookiesSettings from "../CookiesSettings/CookiesSettings";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import AlertsFirstContent from "./AlertsFirstContent";
import AlertsIntro from "./AlertsIntro";

const AlertsPage = () => {
  return (
    <>
      <MenuFixed />
      <AlertsIntro />
      <AlertsFirstContent />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default AlertsPage;
