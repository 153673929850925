import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GlobalContext } from "../../Consts/GlobalContext";
import solutionCategories from "../Menu/SolutionsCategories";

const SolutionsWrapper = styled.div`
  position: relative;
  z-index: 1;
  .solution-wrapper {
    width: 45%;
    margin: -160px auto 0;
    text-align: center;
    > h2 {
      ${(props) => props.theme.h2_48};
      margin-bottom: 30px;
      color: #202945;
    }
    > p {
      ${(props) => props.theme.body_20};
      margin-bottom: 30px;
    }

    .solutions-cards {
      display: flex;
      flex-flow: column wrap;
      height: 800px;
      .card {
        padding: 15px 15px;
        width: 170px;
        height: 25%;
        display: block;
        text-decoration: none;
        .card-wrapper {
          position: relative;
          overflow: hidden;
          background-color: #fff;
          box-shadow: 0px 4px 10px #1a284d14;
          border-radius: 5px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0 5px;
          > img {
            width: 60px;
            height: 60px;
            margin-bottom: 10px;
          }
          > p {
            ${(props) => props.theme.body_16};
            line-height: 19px;
            text-transform: capitalize;
          }
          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -25px;
            width: 20px;
            height: 20px;
            background-color: #0071ce;
            border-radius: 50%;
            transform: scale(1, 1) translateX(-50%);
            transition: bottom 0.3s ease-in-out, transform 0.6s, width 0.6s,
              height 0.6s;
          }
          &:hover {
            > img,
            > p {
              position: relative;
              z-index: 2;
              color: #fff;
            }
          }
        }
      }
      .card .card-wrapper:hover::after {
        bottom: 0;
        right: 0;
        width: 300px;
        height: 250px;
        border-radius: 30px;
        transition: all 0.6s ease-in-out;
        transform: scale(1.5, 1.5) translateX(-50%);
      }
    }
  }

  @media (max-width: 1900px) {
    .solution-wrapper {
      width: 60%;
      > h2 {
        font-size: 38px;
        line-height: 53px;
      }
      > p {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }

  @media (max-width: 1439px) {
    .solution-wrapper {
      width: 84%;
    }
  }

  @media (max-width: 1023px) {
    margin-top: 50px;
    .solution-wrapper {
      width: 100%;
      padding: 0 20px;
      margin: 0 auto;
      .solutions-cards {
        .card {
          height: 20%;
          width: 182px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .solution-wrapper {
      .solutions-cards {
        flex-flow: row wrap;
        height: auto;
        .card {
          flex-basis: 50%;
          height:170px;
        }
      }
    }
  }
`;
const Solutions = () => {
  const { lang } = useContext(GlobalContext);
  return (
    <SolutionsWrapper>
      <div className="solution-wrapper">
        <h2 className="t" data-translate="homepageSolutionsTitle">
          Our Solutions
        </h2>
        <p className="t" data-translate="homepageSolutionsText">
          We are packed with a lot of solutions which cover wide range of options. Please have a detailed look at them, and find what you most need.
        </p>
        <div className="solutions-cards">
          {solutionCategories.map((el) => (
            <Link
              to={`/solutions/${el.title.replaceAll(" ", "-").toLowerCase()}`}
              className="card"
              key={el.id}
            >
              <div className="card-wrapper">
                <img src={el.image} alt={el.title}/>
                <p>{lang === "en" ? el.title : el.titleMK}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </SolutionsWrapper>
  );
};

export default Solutions;
