import React from "react";
import styled from "styled-components";
import Group8399 from "../../img/alerts/group-8399.png";
import Group8400 from "../../img/alerts/group-8400.png";

const AlertsFirstContentWrapper = styled.div`
  padding: 0px 375px 200px;
  position: relative;
  z-index: 1;
  .container {
    padding: 100px 100px 70px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        flex-basis: 75%;
        margin-right: 30px;
        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
          ${(props) => props.theme.body_20};
        }
      }

      .right {
        flex-basis: 25%;
        position: relative;
        img {
          width: 370px;
          display: block;
          box-shadow: -10px 10px 30px #1a284d14;
        }
        img:first-of-type {
          width: 280px;
          position: absolute;
          top: -50px;
          right: -5px;
          transition: all 0.6s ease-in-out;
          z-index: 1;
        }
        img:last-of-type {
          transition: all 0.6s ease-in-out;
        }
        &:hover {
          img:first-of-type {
            top: -80px;
          }
          img:last-of-type {
            transform: translateY(50px);
          }
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0px 275px 100px;
    .container {
      padding: 100px 25px 60px;
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 0px 50px 100px;
  }
  @media (max-width: 1023px) {
    padding: 0px 20px 50px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            width: 250px;
          }
          img:first-of-type {
            width: 200px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      .container-cards {
        display: block;
        .left {
          margin-right: 0;
          margin-bottom: 80px;

          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
          img:first-of-type {
            right: 35px;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
`;
const AlertsFirstContent = () => {
  return (
    <AlertsFirstContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="alertsFirstContentFirstCardTitle">
              Speeding alerts
            </h2>
            <p className="t" data-translate="alertsFirstContentFirstCardText">
              Get notified when vehicles are violating predefined configurable
              speed limits.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group8399} />
            <img alt="" src={Group8400} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="alertsFirstContentSecondCardTitle"
            >
              Bounds alerts
            </h2>
            <p className="t" data-translate="alertsFirstContentSecondCardText">
              Get notified when vehicles are entering or leaving predefined
              configurable zones
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group8399} />
            <img alt="" src={Group8400} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="alertsFirstContentThirdCardTitle">
              Fuel level alerts
            </h2>
            <p className="t" data-translate="alertsFirstContentThirdCardText">
              Get notified when fuel level changes to rapidly and usually
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group8399} />
            <img alt="" src={Group8400} />
          </div>
        </div>
      </div>
    </AlertsFirstContentWrapper>
  );
};

export default AlertsFirstContent;
