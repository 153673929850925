import React from "react";
import styled from "styled-components";
import Group5590 from "../../img/contact/group-5590.svg";

const ContactTeamWrapper = styled.div`
  padding: 155px 445px;
  .title {
    padding: 0 120px;
    text-align: center;
    margin-bottom: 90px;
    h2 {
      ${(props) => props.theme.h2_48};
      color: #202945;
    }
    p {
      ${(props) => props.theme.body_20};
    }
  }
  .container {
    display: flex;
    margin-left: -30px;
    margin-right: -30px;
    .cards {
      flex-basis: 25%;
      padding: 0 30px;
      .inner-cards {
        text-align: center;
        img {
          display: block;
          width: 170px;
          height: 170px;
          margin: 0 auto;
        }
        p {
          ${(props) => props.theme.body_24};
          margin-top: 35px;
        }
        span {
          ${(props) => props.theme.body_20};
          color: #6b7ba4;
          display: block;
        }
      }
    }
  }

  @media (max-width: 1900px) {
    padding: 155px 250px;
  }
  @media (max-width: 1439px) {
    padding: 155px 50px;
    .title {
      h2 {
        font-size: 38px;
        line-height: 53px;
      }
      p {
        font-size: 18px;
        line-height: 25px;
      }
    }
    .container {
      margin-left: 0;
      margin-right: 0;
      .cards {
        padding: 0;
      }
    }
  }

  @media (max-width: 1023px) {
    padding: 100px 20px;
    .title {
      padding: 0 60px;
    }
    .container {
      flex-wrap: wrap;
      .cards {
        flex-basis: 50%;
        padding: 10px 0;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 50px 20px;
    .title {
      padding: 0;
      h2 {
        font-size: 32px;
        line-height: 45px;
      }
      p {
        font-size: 16px;
        line-height: 22px;
      }
    }
    .container {
      display: block;
      .cards {
        padding: 0;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
`;
const ContactTeam = () => {
  return (
    <ContactTeamWrapper>
      <div className="title">
        <h2 className="t" data-translate="contactTeamTitle">
          We are here to help you!
        </h2>
        <p className="t" data-translate="contactTeamText">
          We will be happy to assist you and tell you more about our product and
          solutions. Just call us on +389 78 33 60 32 or send as an inquiry and
          one of our client consultants will get back to you.
        </p>
      </div>
      <div className="container">
        <div className="cards">
          <div className="inner-cards">
            <img alt="" src={Group5590} />
            <p>Goran</p>
            <span>goran@icelabs.mk</span>
            <span> +389 78 33 60 32</span>
          </div>
        </div>
        <div className="cards">
          <div className="inner-cards">
            <img alt="" src={Group5590} />
            <p>Baze</p>
            <span>baze@icelabs.mk</span>
            <span> +389 77 70 82 05</span>
          </div>
        </div>
        <div className="cards">
          <div className="inner-cards">
            <img alt="" src={Group5590} />
            <p>Zoran</p>
            <span>zoran@icelabs.mk</span>
            <span> +389 78 33 60 33</span>
          </div>
        </div>
        <div className="cards">
          <div className="inner-cards">
            <img alt="" src={Group5590} />
            <p>Martin</p>
            <span>martin@icelabs.mk</span>
            <span> +389 77 57 75 02</span>
          </div>
        </div>
      </div>
    </ContactTeamWrapper>
  );
};

export default ContactTeam;
