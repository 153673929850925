import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import IntroBanner from "./IntroBanner";
import ExploreProducts from "./ExploreProducts";
import Solutions from "./Solutions";
import Testimonials from "./Testimonials";
import PartnersSlider from "./PartnersSlider";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import FooterLinks from "../Footer/FooterLinks";
import Footer from "../Footer/Footer";
import { GlobalContext } from "../../Consts/GlobalContext";
import UnsubscribeSnackbar from "../Unsubscribe/UnsubscribeSnackbar";
import CookiesSettings from "../CookiesSettings/CookiesSettings";

const HomePage = () => {
  const { setUnsubscribe } = useContext(GlobalContext);

  useEffect(() => {
    if (localStorage.getItem("unsubscribe")) {
      setUnsubscribe(true);
      setTimeout(() => {
        localStorage.removeItem("unsubscribe");
        setUnsubscribe(false);
      }, 5000);
    }
  }, []);

  return (
    <>
      <UnsubscribeSnackbar />
      <MenuFixed />
      <IntroBanner />
      <ExploreProducts />
      <Solutions />
      <Testimonials />
      <PartnersSlider />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default HomePage;
