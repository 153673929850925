import React from "react";
import CookiesSettings from "../CookiesSettings/CookiesSettings";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import CostControlFirstContent from "./CostControlFirstContent";
import CostControlIntro from "./CostControlIntro";

const CostControlPage = () => {
  return (
    <>
      <MenuFixed />
      <CostControlIntro />
      <CostControlFirstContent />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default CostControlPage;
