import React from "react";
import styled from "styled-components";
import Component521 from "../../img/tachograph/component-52-1.png";
import Group6330 from "../../img/tachograph/group-6330.png";
import Group6381 from "../../img/tachograph/group-6381.svg";
import Group64 from "../../img/products/group-64.png";
import Group7 from "../../img/products/group-7.png";

const TachographFirstContentWrapper = styled.div`
  padding: 0px 375px;
  margin-bottom: -115px;
  position: relative;
  z-index: 1;
  .container {
    padding: 65px 100px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        margin-right: 30px;
        flex-basis: 70%;

        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
          ${(props) => props.theme.body_20};
        }
      }

      .right {
        flex-basis: 30%;
        img {
          width: 370px;
          display: block;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:nth-child(3) {
        .left {
          margin-right: 0;
        }
        .right {
          display: flex;
          align-items: center;
          img {
            width: auto;
            width: 215px;
            transition: all 0.6s ease-in-out;
            box-shadow: -10px 10px 40px #1a284d17;
            &:first-child {
              transform: translate(50px, 0);
            }
            &:last-child {
              position: relative;
              z-index: 1;
            }
          }
          &:hover {
            img {
              &:first-child {
                transform: translate(20px, -135px);
              }
              &:last-child {
                transform: translate(20px, -10px);
              }
            }
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }
      &:last-child {
        img {
          box-shadow: none;
        }
      }
    }
  }

  @media (max-width: 1900px) {
    padding: 0px 275px;
    .container {
      padding: 30px 25px 40px;
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 0px 50px;
  }
  @media (max-width: 1023px) {
    padding: 0px 20px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            width: 250px;
          }
        }

        &:nth-child(3) {
          .right {
            img {
              width: 150px;
            }
            &:hover {
              img {
                &:first-child {
                  transform: translate(15px, -95px);
                }
                &:last-child {
                  transform: translate(15px, -10px);
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      .container-cards {
        display: block;
        .left {
          margin-right: 0;
          margin-bottom: 15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
`;
const TachographFirstContent = () => {
  return (
    <TachographFirstContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="tachographFirstContentFirstCardTitle"
            >
              Driver and Vehicle File Download
            </h2>
            <p
              className="t"
              data-translate="tachographFirstContentFirstCardText"
            >
              Digital tachograph remote download solution allows you to download
              both driver and vehicle card data. As soon as the driver inserts
              the card in the tachograph, our system automatically checks,
              whether a download needs to be done and starts it, if necessary
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group6330} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="tachographFirstContentSecondCardTitle"
            >
              Scheduled and Automated Downloads
            </h2>
            <p
              className="t"
              data-translate="tachographFirstContentSecondCardText"
            >
              Just set up a tachograph data download schedule that fits your
              business needs - once a month or a week, or starting on a specific
              date, e.g. the first day of the month - and let IceTrackr take
              care of the tachograph file downloads
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group6381} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="tachographFirstContentThirdCardTitle"
            >
              Integrations With Tachograph File Analysis Solutions
            </h2>
            <p
              className="t"
              data-translate="tachographFirstContentThirdCardText"
            >
              Downloading data is the first step to ensure tachograph
              compliance. You also need to analyze the data using tachograph
              file analysis solutions. Our system has integrations with our own
              Tachogram as well as idhaOnline and Optac3 platforms, that will
              allow you to gather and oversee all the necessary information in
              one place
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7} />
            <img alt="" src={Group64} />
          </div>
        </div>
      </div>
    </TachographFirstContentWrapper>
  );
};

export default TachographFirstContent;
