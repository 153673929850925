import React from "react";
import styled from "styled-components";
import BackgroundIntro from "../../img/about-us/group-7799.svg";
import Group7797 from "../../img/about-us/group-7797.png";

const AboutUsIntroWrapper = styled.div`
  margin-top: 94px;
  padding: 266px 375px 300px 235px;
  background-image: url(${BackgroundIntro});
  background-position: -95% -308%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;

  .left {
    margin-right: -120px;
    img {
      display: block;
      // width: 960px;
      // height: 507px;
    }
  }
  .right {
    text-align: right;

    h1 {
      ${(props) => props.theme.h1_64};
      margin-bottom: 40px;
    }
    p {
      ${(props) => props.theme.body_24};
    }
  }

  @media (max-width: 1900px) {
    padding: 266px 155px 300px 120px;
    background-position: -546% 143%;
    .left {
      flex-basis: 60%;
      margin-right: -100px;
      img {
        width: 100%;
      }
    }
    .right {
      flex-basis: 40%;
    }
  }
  @media (max-width: 1439px) {
    padding: 266px 0 300px 50px;
    background-position: 205% 42%;
    .left {
      margin-right: -50px;
    }
    .right {
      h1 {
        font-size: 48px;
        line-height: 67px;
        margin-bottom: 20px;
      }
      p {
        font-size: 22px;
        line-height: 31px;
      }
    }
  }
  @media (max-width: 1023px) {
    padding: 100px 20px 200px;
    display: block;
    background-position: 54% 99%;
    .right {
      h1 {
        font-size: 35px;
        line-height: 49px;
        margin-bottom: 10px;
      }
      p {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
`;
const AboutUsIntro = () => {
  return (
    <AboutUsIntroWrapper>
      <div className="left">
        <img src={Group7797} alt=""/>
      </div>
      <div className="right">
        <h1 className="t" data-translate="aboutUsIntroTitle">
          About IceTrackr
        </h1>
        <p className="t" data-translate="aboutUsIntroText">
          Ice Trackr is an integrated system that allows tracking, monitoring
          and security of vehicles and planning of their movements (routing), in
          a very simple way. It offers opportunities to carry out everyday tasks
          in a new and sophisticated way, which allows saving money and time.
        </p>
      </div>
    </AboutUsIntroWrapper>
  );
};

export default AboutUsIntro;
