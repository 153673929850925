import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../Consts/GlobalContext";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
const CookiePolicyFirstContentWrapper = styled.div`
  padding: 0px 375px 200px;
  position: relative;
  z-index: 1;
  .container {
    padding: 85px 40px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    .inner-container {
      padding: 0 160px;
      p {
        text-align: justify;
        display: block;
        ${(props) => props.theme.body_24};
        &:nth-of-type(2) {
          text-align: center;
        }
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
      .cookies-contact {
        text-align: center;
        ${(props) => props.theme.body_24};
        > span {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            display: block;
            margin-right: 5px;
            color: rgb(141 178 227);
          }
          a {
            color: ${(props) => props.theme.color_blue};
            text-decoration: none;
          }
          &:first-of-type {
            font-size: 36px;
            line-height: 47px;
          }
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0 275px 150px;
    .container {
      .inner-container {
        padding: 0;
      }
    }
  }
  @media (max-width: 1439px) {
    padding: 0 50px 100px;
    .container {
      .inner-container {
        padding: 0 40px;
      }
    }
  }
  @media (max-width: 1023px) {
    padding: 0 20px 50px;
    .container {
      padding: 50px 15px;
    }
  }
  @media (max-width: 767px) {
    .container {
      .inner-container {
        padding: 0;
        p {
          font-size: 18px;
          line-height: 25px;
        }
        .cookies-contact {
          font-size: 16px;
          line-height: 22px;
          > span {
            flex-direction: column;
            &:first-child {
              font-size: 26px;
              line-height: 36px;
            }
          }
        }
      }
    }
  }
`;
const CookiePolicyFirstContent = () => {
  const { lang, setOpenCookies } = useContext(GlobalContext);
  const handleOpenCookiesSettings = (e) => {
    e.preventDefault();
    setOpenCookies(true);
  };
  return (
    <CookiePolicyFirstContentWrapper>
      <div className="container">
        <div className="inner-container">
          <p className="t" data-translate="cookiePolicyFirstContentText1"> 
            Ice Trackr website uses cookies. If you have agreed, in addition to
            cookies that ensure the functioning of the website, other cookies,
            such as web beacons, pixels or similar storage technology may be
            placed on your computer or another device from which you access our
            webpage. This Cookie Policy describes what types of cookies we use
            on our website and for what purposes. Mapon website uses different
            kind of cookies: Strictly Necessary cookies, Analytical cookies,
            Marketing cookies. You can set each cookie category (except strictly
            necessary) by clicking on the “cookie settings” button below:
          </p>
          <p>
            <Link
              to="/"
              className="receive-quote-btn"
              onClick={(e) => handleOpenCookiesSettings(e)}
            >
              <span>
                {lang === "en" ? "Cookie settings" : "Поставки за колачиња"}
              </span>
            </Link>
          </p>
          <p className="t" data-translate="cookiePolicyFirstContentText2">
            If you have any questions about your personal data or this Cookie
            Policy, or if you would like to file a complaint about how we
            process your personal data, please contact us by using the contact
            details below:
          </p>
          <div className="cookies-contact">
            <span className="t" data-translate="iceTrackrMacedonia">
              Ice Trackr Macedonia
            </span>
            <span>
              <LocalPhoneRoundedIcon />
              +389 78336032
            </span>
            <span>
              <EmailRoundedIcon />
              <a href="mailto:contact@icelabs.mk">contact@icelabs.mk</a>{" "}
            </span>
            <span>
              <LocationOnRoundedIcon />
              <span className="t" data-translate="iceTrackrAddress">
                Trifun Hadjijanev 12/3-17 Skopje, Macedonia
              </span>
            </span>
          </div>
        </div>
      </div>
    </CookiePolicyFirstContentWrapper>
  );
};

export default CookiePolicyFirstContent;
