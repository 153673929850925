import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../Consts/GlobalContext";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
const WebsiteTermsFirstContentWrapper = styled.div`
  padding: 0px 375px 200px;
  position: relative;
  z-index: 1;
  .container {
    padding: 85px 40px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    > ul {
      list-style-type: none;
      margin-bottom: 20px;
      li {
        ${(props) => props.theme.body_20};
        text-transform: uppercase;
      }
    }
    .terms-content {
      h2 {
        ${(props) => props.theme.h2_48};
        color: ${(props) => props.theme.color_blue};
        margin-bottom: 15px;
        text-transform: uppercase;
      }
      p {
        ${(props) => props.theme.body_24};
        span {
          display: block;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
        > ul {
          list-style-type: none;
          margin-bottom: 10px;
        }
        .privacy-policy {
          display: inline-block;
          margin-bottom: 0 !important;
        }
        a {
          color: ${(props) => props.theme.color_blue};
        }
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
    .terms-content-contact {
      text-align: center;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        ${(props) => props.theme.body_24};
        svg {
          display: block;
          margin-right: 5px;
          color: rgb(141 178 227);
        }
        a {
          color: ${(props) => props.theme.color_blue};
          text-decoration: none;
        }
        &:first-child {
          font-size: 36px;
          line-height: 46px;
        }
        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0 275px 150px;
  }
  @media (max-width: 1439px) {
    padding: 0 50px 100px;
  }
  @media (max-width: 1023px) {
    padding: 0 20px 50px;
    .container {
      .terms-content {
        h2 {
          font-size: 36px;
          line-height: 50px;
        }
        p {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .container {
      padding: 50px 15px;
      > ul {
        li {
          font-size: 18px;
          line-height: 25px;
        }
      }
      .terms-content {
        h2 {
          font-size: 25px;
          line-height: 35px;
        }
        p {
          font-size: 18px;
          line-height: 25px;
        }
      }
      .terms-content-contact {
        span {
          font-size: 16px;
          line-height: 22px;
          flex-direction: column;
          &:first-child {
            font-size: 25px;
            line-height: 35px;
          }
        }
      }
    }
  }
`;
const WebsiteTermsFirstContent = () => {
  const { lang } = useContext(GlobalContext);
  return (
    <WebsiteTermsFirstContentWrapper>
      <div className="container">
        <ul>
          <li className="t" data-translate="agreementToTerms">
            1. AGREEMENT TO TERMS
          </li>
          <li className="t" data-translate="intellectualPropertyRigths">
            2. INTELLECTUAL PROPERTY RIGHTS
          </li>
          <li className="t" data-translate="userRepresentations">
            3. USER REPRESENTATIONS
          </li>
          <li className="t" data-translate="prohibitedActivities">
            4. PROHIBITED ACTIVITIES
          </li>
          <li className="t" data-translate="userGeneratedContributions">
            5. USER GENERATED CONTRIBUTIONS
          </li>
          <li className="t" data-translate="contributionLicense">
            6. CONTRIBUTION LICENSE
          </li>
          <li className="t" data-translate="mobileApplicationLicense">
            7. MOBILE APPLICATION LICENSE
          </li>
          <li className="t" data-translate="submissions">
            8. SUBMISSIONS
          </li>
          <li className="t" data-translate="siteManagement">
            9. SITE MANAGEMENT
          </li>
          <li className="t" data-translate="privacyPolicyTitle">
            10. PRIVACY POLICY
          </li>
          <li className="t" data-translate="termAndTermination">
            11. TERM AND TERMINATION
          </li>
          <li className="t" data-translate="modificationsAndInterruptions">
            12. MODIFICATIONS AND INTERRUPTIONS
          </li>
          <li className="t" data-translate="governingLaw">
            13. GOVERNING LAW
          </li>
          <li className="t" data-translate="disputeResolution">
            14. DISPUTE RESOLUTION
          </li>
          <li className="t" data-translate="corrections">
            15. CORRECTIONS
          </li>
          <li className="t" data-translate="disclaimer">
            16. DISCLAIMER
          </li>
          <li className="t" data-translate="limitationsOfLiability">
            17. LIMITATIONS OF LIABILITY
          </li>
          <li className="t" data-translate="indemnification">
            18. INDEMNIFICATION
          </li>
          <li className="t" data-translate="userData">
            19. USER DATA
          </li>
          <li className="t" data-translate="electornicCommunications">
            20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
          </li>
          <li className="t" data-translate="miscellaneous">
            21. MISCELLANEOUS
          </li>
          <li className="t" data-translate="contactUsTerms">
            22. CONTACT US
          </li>
        </ul>
        <div className="terms-content">
          <h2 className="t" data-translate="agreementToTerms">
            1. AGREEMENT TO TERMS
          </h2>
          <p>
            <span className="t" data-translate="agreementToTermsText1">
              These Terms of Use constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity (“you”)
              and Ice Trackr ("Company," “we," “us," or “our”), concerning your
              access to and use of the icetrackr.com website as well as any
              other media form, media channel, mobile website or mobile
              application related, linked, or otherwise connected thereto
              (collectively, the “Site”). We are registered in Macedonia and
              have our registered office at Trifun Hadjijanev 12/3-17, Skopje
              1000. Our VAT number is MK4043009501369. You agree that by
              accessing the Site, you have read, understood, and agreed to be
              bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL
              OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM
              USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
            </span>{" "}
            <span className="t" data-translate="agreementToTermsText2">
              Supplemental terms and conditions or documents that may be posted
              on the Site from time to time are hereby expressly incorporated
              herein by reference. We reserve the right, in our sole discretion,
              to make changes or modifications to these Terms of Use from time
              to time. We will alert you about any changes by updating the “Last
              updated” date of these Terms of Use, and you waive any right to
              receive specific notice of each such change. Please ensure that
              you check the applicable Terms every time you use our Site so that
              you understand which Terms apply. You will be subject to, and will
              be deemed to have been made aware of and to have accepted, the
              changes in any revised Terms of Use by your continued use of the
              Site after the date such revised Terms of Use are posted. The
              information provided on the Site is not intended for distribution
              to or use by any person or entity in any jurisdiction or country
              where such distribution or use would be contrary to law or
              regulation or which would subject us to any registration
              requirement within such jurisdiction or country. Accordingly,
              those persons who choose to access the Site from other locations
              do so on their own initiative and are solely responsible for
              compliance with local laws, if and to the extent local laws are
              applicable. All users who are minors in the jurisdiction in which
              they reside (generally under the age of 18) must have the
              permission of, and be directly supervised by, their parent or
              guardian to use the Site. If you are a minor, you must have your
              parent or guardian read and agree to these Terms of Use prior to
              you using the Site.
            </span>
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="intellectualPropertyRigths">
            2. INTELLECTUAL PROPERTY RIGHTS
          </h2>
          <p>
            <span
              className="t"
              data-translate="intellectualPropertyRigthsText1"
            >
              Unless otherwise indicated, the Site is our proprietary property
              and all source code, databases, functionality, software, website
              designs, audio, video, text, photographs, and graphics on the Site
              (collectively, the “Content”) and the trademarks, service marks,
              and logos contained therein (the “Marks”) are owned or controlled
              by us or licensed to us, and are protected by copyright and
              trademark laws and various other intellectual property rights and
              unfair competition laws of the United States, international
              copyright laws, and international conventions. The Content and the
              Marks are provided on the Site “AS IS” for your information and
              personal use only. Except as expressly provided in these Terms of
              Use, no part of the Site and no Content or Marks may be copied,
              reproduced, aggregated, republished, uploaded, posted, publicly
              displayed, encoded, translated, transmitted, distributed, sold,
              licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.
            </span>
            <span className="t" data-translate="intellectualPropertyRigthsText2">
              Provided that you are eligible to use the Site, you are granted a
              limited license to access and use the Site and to download or
              print a copy of any portion of the Content to which you have
              properly gained access solely for your personal, non-commercial
              use. We reserve all rights not expressly granted to you in and to
              the Site, the Content and the Marks.
            </span>
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="userRepresentations">
            3. USER REPRESENTATIONS
          </h2>
          <p className="t" data-translate="userRepresentationsText">
            By using the Site, you represent and warrant that: (1) you have the
            legal capacity and you agree to comply with these Terms of Use; (2)
            you are not a minor in the jurisdiction in which you reside, or if a
            minor, you have received parental permission to use the Site; (3)
            you will not access the Site through automated or non-human means,
            whether through a bot, script, or otherwise; (4) you will not use
            the Site for any illegal or unauthorized purpose; and (5) your use
            of the Site will not violate any applicable law or regulation. If
            you provide any information that is untrue, inaccurate, not current,
            or in complete, we have the right to suspend or terminate your
            account and refuse any and all current or future use of the Site (or
            any portion thereof).
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="prohibitedActivities">
            4. PROHIBITED ACTIVITIES
          </h2>
          <p>
            <span className="t" data-translate="prohibitedActivitiesText1">
              You may not access or use the Site for any purpose other than that
              for which we make the Site available. The Site may not be used in
              connection with any commercial endeavors except those that are
              specifically endorsed or approved by us. As a user of the Site,
              you agree not to: Systematically retrieve data or other content
              from the Site to create or compile, directly or indirectly, a
              collection, compilation, database, or directory without written
              permission from us. Trick, defraud, or mislead us and other users,
              especially in any attempt to learn sensitive account information
              such as user passwords. Circumvent, disable, or otherwise
              interfere with security-related features of the Site, including
              features that prevent or restrict the use or copying of any
            </span>
            <ul>
              <li className="t" data-translate="prohibitedActivitiesText2">
                Policies
              </li>
              <li className="t" data-translate="privacyPolicyFooter">
                Privacy Policy
              </li>
              <li className="t" data-translate="prohibitedActivitiesText3">
                Cookie Policy
              </li>
              <li className="t" data-translate="prohibitedActivitiesText4">
                Terms and Conditions
              </li>
            </ul>
            <span className="t" data-translate="prohibitedActivitiesText5">
              Content or enforce limitations on the use of the Site and/or the
              Content contained therein. Disparage, tarnish, or otherwise harm,
              in our opinion, us and/or the Site. Use any information obtained
              from the Site in order to harass, abuse, or harm another person.
              Make improper use of our support services or submit false reports
              of abuse or misconduct. Use the Site in a manner inconsistent with
              any applicable laws or regulations. Engage in unauthorized framing
              of or linking to the Site. Upload or transmit (or attempt to
              upload or to transmit) viruses, Trojan horses, or other material,
              including excessive use of capital letters and spamming(continuous
              posting of repetitive text), that interferes with any party’s
              uninterrupted use and enjoyment of the Site or modifies, impairs,
              disrupts, alters, or interferes with the use, features, functions,
              operation, or maintenance of the Site. Engage in any automated use
              of the system, such as using scripts to send comments or messages,
              or using any data mining, robots, or similar data gathering and
              extraction tools. Delete the copyright or other proprietary rights
              notice from any Content. Attempt to impersonate another user or
              person or use the username of another user. Upload or transmit (or
              attempt to upload or to transmit) any material that acts as a
              passive or active information collection or transmission
              mechanism, including without limitation, clear graphics
              interchange formats (“gifs”), 1×1pixels, web bugs, cookies, or
              other similar devices (sometimes referred to as “spyware” or
              “passive collection mechanisms” or “pcms”). Interfere with,
              disrupt, or create an undue burden on the Site or the ne tworksor
              services connected to the Site. Harass, annoy, intimidate, or
              threaten any of our employees or agents engaged in providing any
              portion of the Site to you. Attempt to bypass any measures of the
              Site designed to prevent or re strictaccess to the Site, or any
              portion of the Site. Copy or adapt the Site’s software, including
              but not limited to Flash, PHP,HTML, JavaScript, or other code.
              Except as permitted by applicable law, decipher, decompile,
              disassemble, o reverse engineer any of the software comprising or
              in any way making up apart of the Site. Except as may be the
              result of standard search engine or Internet browser usage, use,
              launch, develop, or distribute any automated system, including
              without limitation, any spider, robot, cheat utility, scraper, or
              offline reader that accesses the Site, or using or launching any
              unauthorized script or other software. Use a buying agent or
              purchasing agent to make purchases on the Site. Make any
              unauthorized use of the Site, including collecting usernames
              and/or email addresses of users by electronic or other means for
              the purpose of sending unsolicited email, or creating user
              accounts by automated means or under false pretenses. Use the Site
              as part of any effort to compete with us or otherwise use the Site
              and/or the Content for any revenue-generating endeavor or
              commercial enterprise.
            </span>
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="userGeneratedContributions">
            5. USER GENERATED CONTRIBUTIONS
          </h2>
          <p>
            <span
              className="t"
              data-translate="userGeneratedContributionsText1"
            >
              The Site does not offer users to submit or post content. We may
              provide you with the opportunity to create, submit, post, display,
              transmit, perform, publish, distribute, or broadcast content and
              materials to us or on the Site, including but not limited to text,
              writings, video, audio, photographs, graphics, comments,
              suggestions, or personal information or other material
              (collectively, "Contributions"). Contributions may be viewable by
              other users of the Site and through third-party websites. As such,
              any Contributions you transmit may be treated in accordance with
              the Site Privacy Policy. When you create or make available any
              Contributions, you thereby represent and warrant that: The
              creation, distribution, transmission, public display, or
              performance, and the accessing, downloading, or copying of your
              Contributions do not and will not infringe the proprietary rights,
              including but not limited to the copyright, patent, trademark,
              trade secret, or moral rights of any third party.
            </span>
            <span
              className="t"
              data-translate="userGeneratedContributionsText2"
            >
              You are the creator and owner of or have the necessary licenses,
              rights, consents, releases, and permissions to use and to
              authorize us, the Site, and other users of the Site to use your
              Contributions in any manner contemplated by the Site and these
              Terms of Use. You have the written consent, release, and/or
              permission of each and every identifiable individual person in
              your Contributions to use the name or likeness of each and every
              such identifiable individual person to enable inclusion and use of
              your Contributions in any manner contemplated by the Site and
              these Terms of Use. Your Contributions are not false, inaccurate,
              or misleading. Your Contributions are not unsolicited or
              unauthorized advertising, promotional materials, pyramid schemes,
              chain letters, spam, mass mailings, or other forms of
              solicitation. Your Contributions are not obscene, lewd,
              lascivious, filthy, violent, harassing, libelous, slanderous, or
              otherwise objectionable (as determined by us). Your Contributions
              do not ridicule, mock, disparage, intimidate, or abuse anyone.
              Your Contributions are not used to harass or threaten (in the
              legal sense of those terms) any other person and to promote
              violence against a specific person or class of people. Your
              Contributions do not violate any applicable law, regulation, or
              rule. Your Contributions do not violate the privacy or publicity
              rights of any third party. Your Contributions do not violate any
              applicable law concerning child pornography, or otherwise intended
              to protect the health or well-being of minors. Your Contributions
              do not include any offensive comments that are connected to race,
              national origin, gender, sexual preference, or physical handicap.
              Your Contributions do not otherwise violate, or link to material
              that violates, any provision of these Terms of Use, or any
              applicable law or regulation. Any use of the Site in violation of
              the foregoing violates these Terms of Use and may result in, among
              other things, termination or suspension of your rights to use the
              Site.
            </span>
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="contributionLicense">
            6. CONTRIBUTION LICENSE
          </h2>
          <p className="t" data-translate="contributionLicenseText">
            You and the Site agree that we may access, store, process, and use
            any information and personal data that you provide following the
            terms of the Privacy Policy and your choices (including settings).
            By submitting suggestions or other feedback regarding the Site, you
            agree that we can use and share such feedback for any purpose
            without compensation to you. We do not assert any ownership over
            your Contributions. You retain full ownership of all of your
            Contributions and any intellectual property rights or other
            proprietary rights associated with your Contributions. We are not
            liable for any statements or representations in your Contributions
            provided by you in any area on the Site. You are solely responsible
            for your Contributions to the Site and you expressly agree to
            exonerate us from any and all responsibility and to refrain from any
            legal action against us regarding your Contributions.
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="mobileApplicationLicense">
            7. MOBILE APPLICATION LICENSE
          </h2>
          <p className="t" data-translate="mobileApplicationLicenseText">
            Use License If you access the Site via a mobile application, then we
            grant you a revocable, non-exclusive, non-transferable, limited
            right to install and use the mobile application on wireless
            electronic devices owned or controlled by you, and to access and use
            the mobile application on such devices strictly in accordance with
            the terms and conditions of this mobile application license
            contained in these Terms of Use. You shall not: (1) except as
            permitted by applicable law, decompile, reverse engineer,
            disassemble, attempt to derive the source code of, or decrypt the
            application; (2)make any modification, adaptation, improvement,
            enhancement, translation, or derivative work from the application;
            (3) violate any applicable laws, rules, or regulations in connection
            with your access or use of the application; (4) remove, alter, or
            obscure any proprietary notice (including any notice of copyright or
            trademark)posted by us or the licensors of the application; (5) use
            the application for any revenue generating endeavor, commercial
            enterprise, or other purpose for which it is not designed or
            intended; (6) make the application available over a network or other
            environment permitting access or use by multiple devices or users at
            the same time;(7) use the application for creating a product,
            service, or software that is, directly or indirectly, competitive
            with or in any way a substitute for the application; (8) use the
            application to send automated queries to any website or to send any
            unsolicited commercial e-mail; or (9) use any proprietary
            information or any of our interfaces or our other intellectual
            property in the design, development, manufacture, licensing, or
            distribution of any applications, accessories, or devices for use
            with the application. Apple and Android Devices The following terms
            apply when you use a mobile application obtained from either the
            Apple Store or Google Play (each an “App Distributor”) to access the
            Site: (1) the license granted to you for our mobile application is
            limited to a non-transferable license to use the application on a
            device that utilizes the Apple iOS or Android operating systems, as
            applicable, and in accordance with the usage rules set forth in the
            applicable App Distributor’s terms of service; (2) we are
            responsible for providing any maintenance and support services with
            respect to the mobile application as specified in the terms and
            conditions of this mobile application license contained in these
            Terms of Use or as otherwise required under applicable law, and you
            acknowledge that each App Distributor has no obligation whatsoever
            to furnish any maintenance and support services with respect to the
            mobile application; (3) in the event of any failure of the mobile
            application to conform to any applicable warranty, you may notify
            the applicable App Distributor, and the App Distributor, in
            accordance with its terms and policies, may refund the purchase
            price, if any, paid for the mobile application, and to the maximum
            extent permitted by applicable law, the App Distributor will have no
            other warranty obligation whatsoever with respect to the mobile
            application; (4) you represent and warrant that (i) you are not
            located in a country that is subject to a U.S. government embargo,
            or that has been designated by the U.S. government as a “terrorist
            supporting” country and (ii) you are not listed on any U.S.
            government list of prohibited or restricted parties; (5) you must
            comply with applicable third-party terms of agreement when using the
            mobile application, e.g., if you have a VoIP application, then you
            must not be in violation of their wireless data service agreement
            when using the mobile application; and (6) you acknowledge and agree
            that the App Distributors are third-party beneficiaries of the terms
            and conditions in this mobile application license contained in these
            Terms of Use, and that each App Distributor will have the right (and
            will be deemed to have accepted the right) to enforce the terms and
            conditions in this mobile application license contained in these
            Terms of Use against you as a third-party beneficiary thereof.
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="submissions">
            8. SUBMISSIONS
          </h2>
          <p className="t" data-translate="submissionsText">
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback, or other information regarding the Site
            ("Submissions") provided by you to us are non-confidential and shall
            become our sole property. We shall own exclusive rights, including
            all intellectual property rights, and shall be entitled to the un
            restricted use and dissemination of these Submissions for any lawful
            purpose, commercial or otherwise, without acknowledgment or
            compensation to you. You hereby waive all moral rights to any such
            Submissions, and you hereby warrant that any such Submissions are
            original with you or that you have the right to submit such
            Submissions. You agree there shall be no recourse against us for any
            alleged or actual infringement or misappropriation of any
            proprietary right in your Submissions.
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="siteManagement">
            9. SITE MANAGEMENT
          </h2>
          <p className="t" data-translate="siteManagementText">
            We reserve the right, but not the obligation, to: (1) monitor the
            Site for violations of these Terms of Use; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Terms of Use, including without limitation,
            reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit
            the availability of, or disable (to the extent technologically
            feasible) any of your Contributions or any portion thereof; (4) in
            our sole discretion and without limitation, notice, or liability, to
            remove from the Site or otherwise disable all files and content that
            are excessive in size or are in any way burdensome to our systems;
            and (5) otherwise manage the Site in a manner designed to protect
            our rights and property and to facilitate the proper functioning of
            the Site.
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="privacyPolicyTitle">
            10. PRIVACY POLICY
          </h2>
          <p>
            <span className="privacy-policy">
              {lang === "en"
                ? `We care about data privacy and security. Please review our Privacy
              Policy`
                : `Се грижиме за приватноста и безбедноста на податоците. Ве молиме прегледајте ја нашата Политика за приватност`}{" "}
              <Link to="privacy-policy">(icetrackr.com/privacy-policy)</Link>
            </span>
            <span className="t" data-translate="privacyPolicyText">
              By using the Site, you agree to be bound by our Privacy Policy,
              which is incorporated into these Terms of Use. Please be advised
              the Site is hosted in Germany. If you access the Site from any
              other region of the world with laws or other requirements
              governing personal data collection, use, or disclosure that differ
              from applicable laws in Germany, then through your continued use
              of the Site, you are transferring your data to Germany, and you
              agree to have your data transferred to and processed in Germany.
            </span>
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="termAndTermination">
            11. TERM AND TERMINATION
          </h2>
          <p>
            <span className="t" data-translate="termAndTerminationText1">
              These Terms of Use shall remain in full force and effect while you
              use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS
              OF USE, WERESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
              NOTICEOR LIABILITY, DENY ACCESS TO AND USE OF THE SITE
              (INCLUDINGBLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
              REASON ORFOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF
              ANYREPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
              TERMSOF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
              TERMINATEYOUR USE OR PARTICIPATION IN THE SITE OR DELETE ANY
              CONTENT ORINFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT
              WARNING, IN OURSOLE DISCRETION.
            </span>
            <span className="t" data-translate="termAndTerminationText2">
              If we terminate or suspend your account for any reason, you are
              prohibited from registering and creating a new account under your
              name, a fake or borrowed name, or the name of any third party,
              even if you may be acting on behalf of the third party. In
              addition to terminating or suspending your account, we reserve the
              right to take appropriate legal action, including without
              limitation pursuing civil, criminal, and injunctive redress.
            </span>
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="modificationsAndInterruptions">
            12. MODIFICATIONS AND INTERRUPTIONS
          </h2>
          <p className="t" data-translate="modificationsAndInterruptionsText">
            We reserve the right to change, modify, or remove the contents of
            the Site at anytime or for any reason at our sole discretion without
            notice. However, we have no obligation to update any information on
            our Site. We also reserve the right to modify or discontinue all or
            part of the Site without notice at any time. We will not be liable
            to you or any third party for any modification, price change,
            suspension, or discontinuance of the Site. We cannot guarantee the
            Site will be available at all times. We may experience hardware,
            software, or other problems or need to perform maintenance related
            to the Site, resulting in interruptions, delays, or errors. We
            reserve the right to change, revise, update, suspend, discontinue,
            or otherwise modify the Site at any time or for any reason without
            notice to you. You agree that we have no liability whatsoever for
            any loss, damage, or inconvenience caused by your inability to
            access or use the Site during any downtime or discontinuance of the
            Site. Nothing in these Terms of Use will be construed to obligate us
            to maintain and support the Site or to supply any corrections,
            updates, or releases in connection therewith.
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="governingLaw">
            13. GOVERNING LAW
          </h2>
          <p className="t" data-translate="governingLawText">
            These Terms shall be governed by and defined following the laws of
            Macedonia. Ice Trackr and yourself irrevocably consent that the
            courts of Macedonia shall have exclusive jurisdiction to resolve any
            dispute which may arise in connection with the se terms.
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="disputeResolution">
            14. DISPUTE RESOLUTION
          </h2>
          <p className="t" data-translate="disputeResolutionText">
            You agree to irrevocably submit all disputes related to Terms or the
            relationship established by this Agreement to the jurisdiction of
            the courts. Ice Trackr shall also maintain the right to bring
            proceedings as to the substance of the matter in the courts of the
            country where you reside or, if these Terms are entered into in the
            course of your trade or profession, the state of your principal
            place of business.
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="corrections">
            15. CORRECTIONS
          </h2>
          <p className="t" data-translate="correctionsText">
            There may be information on the Site that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Site at any time, with out prior
            notice.
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="disclaimer">
            16. DISCLAIMER
          </h2>
          <p className="t" data-translate="disclaimerText">
            THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
            AGREETHAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR
            SOLERISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM
            ALLWARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE
            ANDYOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE
            IMPLIEDWARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULARPURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES
            ORREPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THESITE’S
            CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITEAND WE WILL
            ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1)ERRORS, MISTAKES,
            OR INACCURACIES OF CONTENT AND MATERIALS, (2)PERSONAL INJURY OR
            PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,RESULTING FROM YOUR ACCESS
            TO AND USE OF THE SITE, (3) ANYUNAUTHORIZED ACCESS TO OR USE OF OUR
            SECURE SERVERS AND/ORANY AND ALL PERSONAL INFORMATION AND/OR
            FINANCIAL INFORMATIONSTORED THEREIN, (4) ANY INTERRUPTION OR
            CESSATION OFTRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES,
            TROJANHORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH
            THESITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN
            ANYCONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY
            KINDINCURRED AS A RESULT OF THE USE OF ANY CONTENT
            POSTED,TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO
            NOTWARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR
            ANYPRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTYTHROUGH
            THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE ORMOBILE
            APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING,AND WE WILL
            NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FORMONITORING ANY
            TRANSACTION BETWEEN YOU AND ANY THIRD-PARTYPROVIDERS OF PRODUCTS OR
            SERVICES. AS WITH THE PURCHASE OF APRODUCT OR SERVICE THROUGH ANY
            MEDIUM OR IN ANY ENVIRONMENT,YOU SHOULD USE YOUR BEST JUDGMENT AND
            EXERCISE CAUTION WHEREAPPROPRIATE.
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="limitationsOfLiability">
            17. LIMITATIONS OF LIABILITY
          </h2>
          <p className="t" data-translate="limitationsOfLiabilityText">
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BELIABLE
            TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVEDAMAGES, INCLUDING LOST
            PROFIT, LOST REVENUE, LOSS OF DATA, OROTHER DAMAGES ARISING FROM
            YOUR USE OF THE SITE, EVEN IF WE HAVEBEEN ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES.
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="indemnification">
            18. INDEMNIFICATION
          </h2>
          <p className="t" data-translate="indemnificationText">
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of:
            (1)use of the Site; (2) breach of these Terms of Use; (3) any breach
            of your representations and warranties set forth in these Terms of
            Use; (4) your violation of the rights of a third party, including
            but not limited to intellectual property rights; or (5)any overt
            harmful act toward any other user of the Site with whom you
            connected via the Site. Notwithstanding the foregoing, we reserve
            the right, at your expense, to assume the exclusive defense and
            control of any matter for which you are required to indemnify us,
            and you agree to cooperate, at your expense, with our defense of
            such claims. We will use reasonable efforts to notify you of any
            such claim, action, or proceeding which is subject to this
            indemnification upon becoming aware of it.
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="userData">
            19. USER DATA
          </h2>
          <p className="t" data-translate="userDataText">
            We will maintain certain data that you transmit to the Site for the
            purpose of managing the performance of the Site, as well as data
            relating to your use of the Site. Although we perform regular
            routine backups of data, you are solely responsible for all data
            that you transmit or that relates to any activity you have
            undertaken using the Site. You agree that we shall have no liability
            to you for any loss or corruption of any such data, and you hereby
            waive any right of action against us arising from any such loss or
            corruption of such data.
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="electornicCommunications">
            20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS,AND SIGNATURES
          </h2>
          <p className="t" data-translate="electornicCommunicationsText">
            Visiting the Site, sending us emails, and completing online forms
            constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide toyou
            electronically, via email and on the Site, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE
            TO THE USE OFELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS,AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
            OFTRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You
            hereby waive any rights or requirements under any statutes,
            regulations, rules, ordinances, or other laws in any jurisdiction
            which require an original signature or delivery or retention of
            non-electronic records, or to payments or the granting of credits by
            any means other than electronic means.
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="miscellaneous">
            21. MISCELLANEOUS
          </h2>
          <p className="t" data-translate="miscellaneousText">
            These Terms of Use and any policies or operating rules posted by us
            on the Site or in respect to the Site constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Terms of Use
            shall not operate as a waiver of such right or provision. These
            Terms of Use operate to the fullest extent permissible by law. We
            may assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Terms of
            Use is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Terms of Use and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment or agency relationship created between you and us as a
            result of these Terms of Use or use of the Site. You agree that
            these Terms of Use will not be construed against us by virtue of
            having drafted them. You hereby waive any and all defenses you may
            have based on the electronic form of these Terms of Use and the lack
            of signing by the parties hereto to execute these Terms of Use.
          </p>
        </div>
        <div className="terms-content">
          <h2 className="t" data-translate="contactUsTerms">
            22. CONTACT US
          </h2>
          <p className="t" data-translate="contactUsTermsText">
            In order to resolve a complaint regarding the Site or to receive
            further information regarding use of the Site, please contact us at:
          </p>
        </div>
        <div className="terms-content-contact">
          <span className="t" data-translate="iceTrackrMacedonia">
            Ice Trackr Macedonia
          </span>
          <span>
            <LocalPhoneRoundedIcon />
            +389 78336032
          </span>
          <span>
            <EmailRoundedIcon />
            <a href="mailto:contact@icelabs.mk">contact@icelabs.mk</a>{" "}
          </span>
          <span>
            <LocationOnRoundedIcon />
            <span className="t" data-translate="iceTrackrAddress">
              Trifun Hadjijanev 12/3-17 Skopje, Macedonia
            </span>
          </span>
        </div>
      </div>
    </WebsiteTermsFirstContentWrapper>
  );
};

export default WebsiteTermsFirstContent;
