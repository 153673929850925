import React, { useState } from "react";
import styled from "styled-components";
import backgroundImage from "../../img/quote_background.svg";
import axios from "axios";
import successTick from "../../img/success-tick.png";
import { useContext } from "react";
import { GlobalContext } from "../../Consts/GlobalContext";

const QuoteWrapper = styled.div`
  padding: 114px 0;
  background-image: url(${backgroundImage});
  > div {
    width: 50%;
    margin: 0 auto;
    h2 {
      ${(props) => props.theme.h2_48};
    }
    p {
      ${(props) => props.theme.body_20};
      color: white;
    }
    h2,
    > p {
      text-align: center;
      margin-bottom: 30px;
    }
  }

  form {
    padding: 66px 100px 50px;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    > div {
      flex-basis: 50%;
      flex-grow: 1;
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
      label {
        ${(props) => props.theme.body_20};
        display: block;
      }
      input,
      textarea {
        width: 100%;
        display: block;
        border: 1px solid #dce3eb;
        border-radius: 3px;
        padding: 10px;
        font-size: 18px;
      }
      textarea {
        resize: none;
      }
      input:focus,
      textarea:focus {
        outline: none;
        border-color: #202945;
      }
      &:nth-child(2n + 1):not(:last-child) {
        padding-right: 15px;
      }
      &:nth-child(2n + 2):not(:last-child) {
        padding-left: 15px;
      }
      .receive-quote-btn {
        display: inline-block;
        margin-right: 0;
        margin-left: 0;
        flex-grow: 0;
        transition: opacity 0.3s;
        pointer-events: unset;
        cursor: pointer;
      }

      button[disabled] {
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
      }
    }
    > div:last-of-type {
      text-align: center;
    }
    .accept-privacy {
      flex-basis: 100%;
      display: flex;
      align-items: center;
      padding-left: 0 !important;
      label {
        display: inline-block;
        ${(props) => props.theme.body_16}
      }
      input {
        width: unset;
        display: inline-block;
        margin: 0;
        margin-right: 10px;
        width: 24px;
        height: 24px;
        border: 1px solid #dce3eb;
        border-radius: 3px;
      }
    }
  }
  .success-message {
    display: flex;
    position: fixed;
    opacity: 0;
    top: -30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 350px;
    background-color: white;
    color: #007e33;
    z-index: 100000;
    background-color: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 8px 10px 35px #dadde0;
    transition: all 2s ease-in-out;
    > p {
      ${(props) => props.theme.body_20};
      color: rgba(46, 217, 195, 1);
    }
    img {
      display: block;
      width: 100px;
    }
  }
  .success-message.show {
    top: 50%;
    opacity: 1;
  }

  @media (max-width: 1900px) {
    > div {
      width: 55%;
      h2 {
        font-size: 38px;
        line-height: 53px;
      }
      p {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
  @media (max-width: 1439px) {
    > div {
      width: 91%;
    }
  }
  @media (max-width: 1023px) {
    padding: 50px 0;
    > div {
      width: 95%;
    }
  }

  @media (max-width: 767px) {
    padding: 30px 0;
    > div {
      h2 {
        line-height: 34px;
        font-size: 28px;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        line-height: 22px;
      }
    }
    form {
      padding: 40px 15px;
      display: block;
      > div {
        &:nth-child(2n + 1):not(:last-child) {
          padding-right: 0;
        }
        &:nth-child(2n + 2):not(:last-child) {
          padding-left: 0;
        }
      }
    }
  }
`;
const Quote = () => {
  const [state, setState] = useState({
    fullName: "",
    company: "",
    phone: "",
    email: "",
    message: "",
  });
  const [acceptPP, setAcceptPP] = useState(false);
  const [success, setSuccess] = useState(false);
  const { bearerToken } = useContext(GlobalContext);

  const handleInputChange = (event) => {
    setState((prevProps) => ({
      ...prevProps,
      [event.target.name]: event.target.value,
    }));
  };

  const receiveQuote = (e) => {
    e.preventDefault();
    if (
      state.fullName === "" ||
      state.phone === "" ||
      state.email === "" ||
      state.company === "" ||
      state.message === ""
    ) {
      return;
    }

    const data = {
      name: state.fullName,
      phone: state.phone,
      email: state.email,
      company: state.company,
      message: state.message,
    };
    axios({
      method: "post",
      url: `${window.baseurl}/restful/v2/quote`,
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
        Authorization: bearerToken,
      },
      data: data,
    }).then((res) => {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
      console.log(res);
      setState({
        fullName: "",
        company: "",
        phone: "",
        email: "",
        message: "",
      });
      setAcceptPP(false);
    });
  };

  return (
    <QuoteWrapper>
      <div>
        <h2 className="t" data-translate="quoteTitle">
          Get a Free Quote
        </h2>
        <p className="t" data-translate="quoteText">
          Want to find out more about our solutions and receive a personalised
          offer? Fill in the form below and our team will get in touch with you
          as soon as possible!
        </p>
        <form onSubmit={(e) => receiveQuote(e)}>
          <div>
            <label htmlFor="fullName" className="t" data-translate="fullName">
              Full Name
            </label>
            <input
              type="text"
              name="fullName"
              id="fullName"
              value={state.fullName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="company" className="t" data-translate="company">
              Company
            </label>
            <input
              type="text"
              name="company"
              id="company"
              value={state.company}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="phone" className="t" data-translate="phone">
              Phone
            </label>
            <input
              type="text"
              name="phone"
              id="phone"
              value={state.phone}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="t" data-translate="email">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={state.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="message" className="t" data-translate="message">
              Message
            </label>
            <textarea
              rows="3"
              name="message"
              id="message"
              value={state.message}
              onChange={handleInputChange}
            />
          </div>
          <div className="accept-privacy">
            <input
              type="checkbox"
              name="acceptPP"
              checked={acceptPP}
              onChange={(e) => setAcceptPP(e.target.checked)}
              required
            />
            <label
              htmlFor="acceptPP"
              className="t"
              data-translate="privacyPolicy"
            >
              I accept that the information I provide is processed according to
              Ice Trackr privacy policy.
            </label>
          </div>
          <div>
            <button
              type="submit"
              className="receive-quote-btn"
              disabled={acceptPP ? "" : "true"}
            >
              <span className="t" data-translate="receiveQuoteButton">
                Recieve a quote
              </span>
            </button>
          </div>
        </form>
      </div>
      <section>
        <div className={success ? "success-message show" : "success-message"}>
          <img src={successTick} alt="" />
          <p className="t" data-translate="successMessage">
            The Quote has been successfully sent.
          </p>
        </div>
      </section>
    </QuoteWrapper>
  );
};

export default Quote;
