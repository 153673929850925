import React from "react";
import styled from "styled-components";
import Group7359 from "../../img/temperature-control/group-7359.svg";
import Group7267 from "../../img/temperature-control/group-7267.svg";
import Group7360 from "../../img/temperature-control/group-7360.svg";
import Group7365 from "../../img/temperature-control/group-7365.svg";
import Group7364 from "../../img/temperature-control/group-7364.svg";
import Group7388 from "../../img/temperature-control/group-7388.svg";
import ListIcon from "../../img/list-icon.svg";

const TemperatureControlFirstContentWrapper = styled.div`
  padding: 0px 375px;
  margin-bottom: -115px;
  position: relative;
  z-index: 1;
  .container {
    padding: 100px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        flex-basis: 75%;
        margin-right: 20px;
        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
          ${(props) => props.theme.body_20};
        }
        span {
          ${(props) => props.theme.body_20};
          font-weight: 600;
          margin-bottom: 20px;
          display: block;
        }
        ul {
          padding-left: 24px;
          list-style-image: url(${ListIcon});

          li {
            ${(props) => props.theme.body_20};
            font-weight: 400;
            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }
      }

      .right {
        img {
          width: 370px;
          display: block;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 120px;
      }
      &:last-child {
        img {
          box-shadow: none;
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0px 275px;
    .container {
      padding: 30px 25px 40px;
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 0px 50px;
  }
  @media (max-width: 1023px) {
    padding: 0px 20px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            width: 250px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      .container-cards {
        display: block;
        .left {
          margin-right:0;
          margin-bottom:15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom:10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
`;
const TemperatureControlFirstContent = () => {
  return (
    <TemperatureControlFirstContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="temperatureControlFirstContentFirstCardTitle">Track Cargo Data and Monitor Errors</h2>
            <p  className="t" data-translate="temperatureControlFirstContentFirstCardText">
              Get precise real-time data about the state of refrigerator devices
              and monitor directly from the office. Spotting failures and acting
              quickly will save your business from financial losses caused by
              damaged cargo.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7359} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="temperatureControlFirstContentSecondCardTitle">All Data in a Simple Graph</h2>
            <p className="t" data-translate="temperatureControlFirstContentSecondCardText">
              Have easy-to-understand temperature data graphs and adjust the
              displayed information using filters and zoom-in feature to view
              specific data points. Combine temperature monitoring with the GPS
              tracking solution to see when and where the temperature changes
              happened.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7267} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="temperatureControlFirstContentThirdCardTitle">Working Together With Industry Leaders</h2>
            <span className="t" data-translate="temperatureControlFirstContentThirdCardText1">
              Our solution provides all important Carrier and Thermo King
              refrigeration unit temperature data:
            </span>
            <ul>
              <li className="t" data-translate="temperatureControlFirstContentThirdCardText2">Setpoint temperature</li>
              <li className="t" data-translate="temperatureControlFirstContentThirdCardText3">Supply air temperature</li>
              <li className="t" data-translate="temperatureControlFirstContentThirdCardText4">Return air temperature</li>
            </ul>
          </div>
          <div className="right">
            <img alt="" src={Group7360} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="temperatureControlFirstContentFourthCardTitle">Plan and Manage Maintenance With Ease</h2>
            <p className="t" data-translate="temperatureControlFirstContentFourthCardText">
              Receive timely notifications with information about refrigeration
              units, errors, shutdowns, necessary maintenance tasks, etc. That
              way you'll be able to plan maintenance operations and manage your
              units more effectively.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7365} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="temperatureControlFirstContentFifthCardTitle">Receive Instant Alerts</h2>
            <p className="t" data-translate="temperatureControlFirstContentFifthCardText">
              Set up alerts to notify your drivers and dispatchers as soon as
              any temperature changes appear and save your business from
              unexpected costs.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7364} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="temperatureControlFirstContentSixthCardTitle">Monitor Battery Status</h2>
            <p className="t" data-translate="temperatureControlFirstContentSixthCardText">
              Use IceTrackr Expert tracking devices to access data about the
              refrigeration unit battery status and voltage. It'll allow you to
              avoid situations when batteries drain out due to longer inactivity
              and save the money on replacing them.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7388} />
          </div>
        </div>
      </div>
    </TemperatureControlFirstContentWrapper>
  );
};

export default TemperatureControlFirstContent;
