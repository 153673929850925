import React from "react";
import styled from "styled-components";
import Group7014 from "../../img/dispatch/group-7014.png";
import Group7019 from "../../img/dispatch/group-7019.png";
import ListIcon from "../../img/list-icon.svg";

const DispatchCommunicationFirstContentWrapper = styled.div`
  padding: 0px 375px;
  position: relative;
  z-index: 1;
  .container {
    padding: 100px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        flex-basis: 75%;

        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
          ${(props) => props.theme.body_20};
        }
        ul {
          padding-left: 24px;
          list-style-image: url(${ListIcon});
          li {
            ${(props) => props.theme.body_20};
            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }
      }

      .right {
        img {
          width: 370px;
          display: block;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }
      &:last-child {
        img {
          box-shadow: none;
        }
      }
    }
  }

  @media (max-width: 1900px) {
    padding: 0px 275px;
    .container {
      padding: 30px 25px 40px;
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 0px 50px;
  }
  @media (max-width: 1023px) {
    padding: 0px 20px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
          ul {
            li {
              font-size: 15px;
              line-height: 21px;
            }
          }
        }
        .right {
          img {
            width: 300px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      .container-cards {
        display: block;
        .left {
          margin-right: 0;
          margin-bottom: 15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
`;
const DispatchCommunicationFirstContent = () => {
  return (
    <DispatchCommunicationFirstContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="dispatchCommunicationFirstContentFirstCardTitle"
            >
              Connect across all platforms
            </h2>
            <ul>
              <li
                className="t"
                data-translate="dispatchCommunicationFirstContentFirstCardText1"
              >
                Direct messages
              </li>
              <li
                className="t"
                data-translate="dispatchCommunicationFirstContentFirstCardText2"
              >
                Group chats
              </li>
              <li
                className="t"
                data-translate="dispatchCommunicationFirstContentFirstCardText3"
              >
                SMS and Garmin messages
              </li>
              <li
                className="t"
                data-translate="dispatchCommunicationFirstContentFirstCardText4"
              >
                Workspaces
              </li>
              <li
                className="t"
                data-translate="dispatchCommunicationFirstContentFirstCardText5"
              >
                File and location sharing
              </li>
              <li
                className="t"
                data-translate="dispatchCommunicationFirstContentFirstCardText6"
              >
                Mobile applications
              </li>
            </ul>
          </div>
          <div className="right">
            <img src={Group7014} alt=""/>
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="dispatchCommunicationFirstContentSecondCardTitle"
            >
              Send data to external systems using API
            </h2>
            <p
              className="t"
              data-translate="dispatchCommunicationFirstContentSecondCardText"
            >
              Use the Messaging integration to exchange messages and files
              directly between Ice Trackr and your internal systems. With this API,
              files that will be sent from Ice Trackr GO drivers' mobile app will be
              directly added to your bookkeeping system, etc.
            </p>
          </div>
          <div className="right">
            <img src={Group7019} alt=""/>
          </div>
        </div>
      </div>
    </DispatchCommunicationFirstContentWrapper>
  );
};

export default DispatchCommunicationFirstContent;
