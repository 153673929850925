import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Ellipse from "../../img/home-page/ellipse-520.svg";
import IntroAnimationMp4 from "../../img/home-page/IceTrackr_Homepage_Video.mp4";
import { useEffect } from "react";
import { useState } from "react";
import $ from "jquery";
const IntroBannerWrapper = styled.div`
  padding: 250px 0 300px 375px;
  background-color: #f2f7f9;
  position: relative;
  z-index: 1;
  margin-top: 94px;
  .explore-solutions {
    width: 42%;
    h1 {
      ${(props) => props.theme.h1_64};
      margin-bottom: 30px;
    }
    p {
      ${(props) => props.theme.body_24};
      margin-bottom: 30px;
    }
  }
  .ellipse {
    position: absolute;
    bottom: -175px;
    right: -175px;
  }
  .intro-animation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  video {
    width: 100%;
    height: 100%;
    position: relative;
    left: 175px;
  }

  @media (max-width: 1900px) {
    padding: 150px 0 200px 275px;
    .explore-solutions {
      h1 {
        font-size: 56px;
      }
      p {
        font-size: 20px;
        line-height: 28px;
      }
    }
    video {
      left: 50px;
    }
  }
  @media (max-width: 1439px) {
    padding: 150px 0 200px 50px;
    .explore-solutions {
      width: 46%;
      h1 {
        font-size: 46px;
        line-height: 64px;
      }
      p {
        font-size: 18px;
        line-height: 25px;
      }
    }
    video {
      left: 0;
      top: -94px;
    }
  }
  @media (max-width: 1023px) {
    padding: 150px 0 200px 20px;
    .explore-solutions {
      h1 {
        font-size: 35px;
        line-height: 49px;
      }
      p {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 50px 20px 450px;
    .explore-solutions {
      width: 100%;
      h1 {
        font-size: 35px;
        line-height: 49px;
      }
      p {
        font-size: 16px;
        line-height: 22px;
      }
    }
    .ellipse {
      width: 200px;
      bottom: -100px;
      right: -100px;
    }
    video {
      top: unset;
      left: -165px;
      bottom: -150px;
      transform: scale(1.8);
    }
  }
`;
const IntroBanner = () => {
  const [stopAnimation, setStopAnimation] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.outerWidth > 426) {
        setStopAnimation(false);
      } else {
        setStopAnimation(true);
      }
    });
    if (window.outerWidth > 426) {
      setStopAnimation(false);
    } else {
      setStopAnimation(true);
    }
  }, []);

  return (
    <IntroBannerWrapper>
      <div className="intro-animation">
        <video
          playsinline
          // autoPlay={stopAnimation ? "" : "disabled"}
          muted
          // loop={stopAnimation ? "" : "disabled"}
          autoPlay
          loop
        >
          <source src={IntroAnimationMp4} type="video/mp4"></source>
        </video>
      </div>

      <div className="explore-solutions">
        <h1 className="t" data-translate="homepageIntroTitle">
          Ice Trackr is the center of your fleet operations
        </h1>
        <p className="t" data-translate="homepageIntroText">
          An effective and simple way to remotely track vehicles and assets.
          Manage your company's fleet using simple, yet powerful solutions!
        </p>
        <Link to="/solutions" className="receive-quote-btn">
          <span className="t" data-translate="homepageIntroButton">
            Explore Solutions
          </span>
        </Link>
      </div>
      <img className="ellipse" src={Ellipse} alt="" />
    </IntroBannerWrapper>
  );
};

export default IntroBanner;
