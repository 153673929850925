import React from "react";
import styled from "styled-components";
import EmailIcon from "../../img/contact/email.svg";
import PhoneIcon from "../../img/contact/phone.svg";
import LocationIcon from "../../img/contact/location.svg";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const ContactInfoWrapper = styled.div`
  padding: 150px 375px 200px;

  .text {
    padding: 0 200px;
    text-align: center;
    margin-bottom: 65px;
    h2 {
      ${(props) => props.theme.h2_48};
      color: #202945;
    }
    p {
      ${(props) => props.theme.body_20};
    }
  }
  .cards-wrapper {
    display: flex;
    justify-content: space-around;
    .cards {
      flex-basis: 25%;
      text-align: center;
      p {
        ${(props) => props.theme.body_20};
      }
    }
  }
  .verticle-line {
    width: 1px;
    height: 86px;
    margin: 30px auto;
    background-color: #dce3eb;
  }
  .socials {
    p {
      text-align: center;
      font: normal normal 600 30px/38px TT Commons;
    }
    .socials-wrapper {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      .socials-links {
        width: 35px;
        height: 35px;
        color: #8db2e3;
        display: block;
        &:not(:last-of-type) {
          margin-right: 35px;
        }
      }
    }
  }
  .MuiSvgIcon-root {
    width: 100% !important;
    height: 100% !important;
  }

  @media (max-width: 1900px) {
    padding: 150px 275px 200px;
  }
  @media (max-width: 1439px) {
    padding: 100px 50px 150px;
  }
  @media (max-width: 1023px) {
    padding: 50px 20px 100px;
    .text {
      padding: 0 100px;
      h2 {
        font-size: 38px;
        line-height: 53px;
      }
      p {
        font-size: 18px;
        line-height: 25px;
      }
    }
    .cards-wrapper {
      .cards {
        p {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
    .socials {
      p {
        font-size: 25px;
        line-height: 35px;
      }
    }
  }
  @media (max-width: 767px) {
    .text {
      padding: 0;
      h2 {
        font-size: 30px;
        line-height: 42px;
      }
      p {
        font-size: 16px;
        line-height: 22px;
      }
    }
    .cards-wrapper {
      display: block;
      .cards {
        p {
          font-size: 16px;
          line-height: 22px;
        }
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
    .socials {
      p {
        font-size: 22px;
        line-height: 30px;
      }
      .socials-wrapper {
        margin-top: 20px;
      }
    }
  }
`;
const ContactInfo = () => {
  return (
    <ContactInfoWrapper>
      <div className="container">
        <div className="text">
          <h2 className="t" data-translate="contactInfoTitle">
            Contacts
          </h2>
          <p className="t" data-translate="contactInfoText">
            If you’ve got any questions, queries or requests that haven’t been
            covered already, you can email the IceTrackr team. We’ll get back to
            you as soon as we can!
          </p>
        </div>
        <div className="cards-wrapper">
          <div className="cards">
            <img alt="" src={EmailIcon} />
            <p>contact@icelabs.mk</p>
          </div>
          <div className="cards">
            <img alt="" src={LocationIcon} />
            <p className="t" data-translate="contactInfoLocation">
              Trifun Hadzijanev br 12/3-17, 1000 Skopje, Macedonia
            </p>
          </div>
          <div className="cards">
            <img alt="" src={PhoneIcon} />
            <p>+389 78 33 60 32</p>
          </div>
        </div>
        <div className="verticle-line"></div>
        <div className="socials">
          <p className="t" data-translate="contactInfoSocialsTitle">
            You can also find us on:
          </p>
          <div className="socials-wrapper">
            <a
              href="https://www.facebook.com/icetrackr/"
              className="socials-links"
              target="_blank"
            >
              <FacebookRoundedIcon />
            </a>
            <a href="/" className="socials-links" target="_blank">
              <YouTubeIcon />
            </a>
            <a href="/" className="socials-links" target="_blank">
              <TwitterIcon />
            </a>
            <a href="/" className="socials-links" target="_blank">
              <LinkedInIcon />
            </a>
          </div>
        </div>
      </div>
    </ContactInfoWrapper>
  );
};

export default ContactInfo;
