import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GlobalContext } from "../../Consts/GlobalContext";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import features from "./ProductsTableArray";

const ProductsTableWrapper = styled.div`
  padding: 100px 375px 140px;
  background-color: #f2f7f9;
  h1 {
    ${(props) => props.theme.h1_64};
    text-align: center;
    margin-bottom: 65px;
  }
  table {
    border-radius: 5px;
    border-collapse: collapse;
    box-shadow: 0px 4px 10px #1a284d14;
    background-color: #fff;
    width: 100%;
    text-align: center;
    th,
    td {
      border-bottom: 1px solid #dce3eb;
      padding: 23px 55px;
      vertical-align: middle;
    }
    td:not(:last-of-type),
    th:not(:last-of-type) {
      border-right: 1px solid #dce3eb;
    }
    th {
      font: normal normal 600 30px/38px TT Commons;
      color: #202945;
      &:first-child {
        text-align: left;
      }
      &:nth-child(2) {
        span {
          color: #2ed9c3;
        }
      }
      &:last-child {
        span {
          color: #0071ce;
        }
      }
    }
    td {
      &:first-child {
        ${(props) => props.theme.body_24};
        text-align: left;
      }
      span {
        display: block;
        svg {
          font-size: 28px;
          display: block;
          margin: 0 auto;
        }
      }
      .check-icon {
        color: #2ed9c3;
      }
      .cancel-icon {
        color: #ff0000;
      }
    }
    tfoot {
      td {
        padding: 13px;
        .receive-quote-btn {
          display: block;
        }

        &:last-child {
          .receive-quote-btn {
            background-color: #0071ce;
          }
          .receive-quote-btn:hover {
            color: #0071ce;
            background-color: #fff;
          }
        }
      }
    }
  }

  @media (max-width: 1900px) {
    padding: 100px 275px 140px;
  }

  @media (max-width: 1439px) {
    padding: 100px 50px 140px;
    h1 {
      font-size: 50px;
      line-height: 67px;
    }
  }
  @media (max-width: 1023px) {
    padding: 100px 20px 140px;
    h1 {
      font-size: 50px;
      line-height: 67px;
    }
    table {
      th,
      td {
        padding: 10px 20px;
      }
      th {
        font-size: 24px;
        line-height: 34px;
      }
      td {
        &:first-child {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 50px 20px 70px;

    h1 {
      font-size: 38px;
      line-height: 53px;
      margin-bottom: 35px;
    }
    table {
      th,
      td {
        padding: 5px;
      }
      th {
        font-size: 20px;
        line-height: 25px;
      }

      td {
        span {
          svg {
            font-size: 20px;
          }
        }

        &:first-child {
          font-size: 16px;
          line-height: 22px;
        }
      }
      tfoot {
        td {
          padding: 5px;
          .receive-quote-btn {
            font-size: 10px;
            line-height: 12px;
            padding: 8px;
          }
        }
      }
    }
  }
`;

const ProductsTable = () => {
  const { lang } = useContext(GlobalContext);
  return (
    <ProductsTableWrapper>
      <h1 className="t" data-translate="productsTableTitle">
        Choose Your Platform
      </h1>
      <table>
        <thead>
          <tr>
            <th className="t" data-translate="productsTableFeaturesTitle">
              Features
            </th>
            <th>
              IceTrackr <span>Pro</span>
            </th>
            <th>
              IceTrackr <span>Lite</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {features.map((el) => (
            <tr>
              <td>{lang === "en" ? el.title : el.titleMK}</td>
              <td>
                {el.pro ? (
                  <span className="check-icon">
                    <CheckCircleRoundedIcon />
                  </span>
                ) : (
                  <span className="cancel-icon">
                    <CancelRoundedIcon />
                  </span>
                )}
              </td>
              <td>
                {el.lite ? (
                  <span className="check-icon">
                    <CheckCircleRoundedIcon />
                  </span>
                ) : (
                  <span className="cancel-icon">
                    <CancelRoundedIcon />
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td>
              <Link to="/products/pro" className="receive-quote-btn">
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </td>
            <td>
              <Link to="/products/lite" className="receive-quote-btn">
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </td>
          </tr>
        </tfoot>
      </table>
    </ProductsTableWrapper>
  );
};

export default ProductsTable;
