import React, { useContext } from "react";
import styled from "styled-components";
import { GlobalContext } from "../../Consts/GlobalContext";
import Group8464 from "../../img/products/lite/group-8464.png";
import { Link } from "react-router-dom";

const ProductsLiteIntroWrapper = styled.div`
  padding: 105px 415px 105px 535px;
  margin-top: 94px;
  background-color: #f2f7f9;

  .text-wrapper {
    text-align: center;
    margin-bottom: 75px;
    h1 {
      ${(props) => props.theme.h1_64};
      span {
        color: #0071ce;
      }
    }
    p {
      ${(props) => props.theme.body_24};
      margin: 25px 0;
    }
    .receive-quote-btn {
      padding: 20px 45px;
    }
  }
  img {
    display: block;
    width: 100%;
  }

  @media (max-width: 1900px) {
    padding: 105px 275px;
  }
  @media (max-width: 1439px) {
    padding: 105px 50px;
  }
  @media (max-width: 1023px) {
    padding: 50px 20px;
  }
  @media (max-width: 767px) {
    .text-wrapper {
      h1 {
        font-size: 45px;
        line-height: 63px;
      }
      p {
        font-size: 18px;
        line-height: 25px;
      }
      .receive-quote-btn {
        padding: 15px 30px;
      }
    }
  }
`;

const ProductsLiteIntro = () => {
  const { lang } = useContext(GlobalContext);
  return (
    <ProductsLiteIntroWrapper>
      <div className="text-wrapper">
        <h1>
          IceTrackr <span> Lite</span>
        </h1>
        <p className="t" data-translate="productsLiteIntroText">
          A simple solution for small companies and fleets.
        </p>
        <Link to="/contact" className="receive-quote-btn">
          <span className="t" data-translate="receiveQuoteButton">
            Recieve a quote
          </span>
        </Link>
      </div>
      <div>
        <img src={Group8464} />
      </div>
    </ProductsLiteIntroWrapper>
  );
};

export default ProductsLiteIntro;
