import React from "react";
import styled from "styled-components";
import BackgroundIntro from "../../img/quote_background.svg";
import Tachograph from "../../img/tachograph.svg";

const TachographIntroWrapper = styled.div`
  background-image: url(${BackgroundIntro});
  padding: 150px 375px 0;
  .container {
    display: flex;
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #dce3eb;

    .left {
      flex-basis: 35%;
      padding: 100px 0;
      border-right: 1px solid #dce3eb;
      display: flex;
      align-items: center;
      img {
        display: block;
        width: 254px;
        height: 277px;
        margin: 0 auto;
      }
    }
    .right {
      flex-basis: 65%;
      padding: 0 100px;
      background-color: white;
      border-top-right-radius: 5px;
      display: flex;
      align-items: center;
      .right-wrapper {
        h1 {
          ${(props) => props.theme.h1_64};
          margin-bottom: 25px;
        }
        p {
          ${(props) => props.theme.body_24};
        }
      }
    }
  }

  @media (max-width: 1900px) {
    padding: 150px 275px 0;
    .container {
      .left {
        padding: 50px 0;
      }
      .right {
        padding: 0 50px;
        .right-wrapper {
          h1 {
            font-size: 48px;
            line-height: 67px;
          }
          p {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 150px 50px 0;
    .container {
      .left {
        padding: 30px 0;
      }
      .right {
        padding: 0 15px;
      }
    }
  }
  @media (max-width: 1023px) {
    padding: 150px 20px 0;
    .container {
      .left {
        img {
          width: 200px;
          height: 230px;
        }
      }
      .right {
        padding: 0 15px;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 100px 20px 0;
    .container {
      display: block;
      .left {
        padding: 15px 0 0;
        border-right: none;
        img {
          height: 100px;
        }
      }
      .right {
        padding: 15px;
        .right-wrapper {
          h1 {
            font-size: 30px;
            line-height: 42px;
            margin-bottom: 10px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
      }
    }
  }
`;
const TachographIntro = () => {
  return (
    <TachographIntroWrapper>
      <div className="container">
        <div className="left">
          <img src={Tachograph} alt="" />
        </div>
        <div className="right">
          <div className="right-wrapper">
            <h1 className="t" data-translate="tachographIntroTitle">
              Digital Tachograph Data Remote Download
            </h1>
            <p className="t" data-translate="tachographIntroText">
              Tachograph data must be downloaded at least once every 90 days and
              driver card data - once every 28 days. Failing to comply with the
              regulation may result in considerable fines. Fortunately, with our
              solution, you can download tachograph data remotely and automate
              the whole process to never miss a download date
            </p>
          </div>
        </div>
      </div>
    </TachographIntroWrapper>
  );
};

export default TachographIntro;
