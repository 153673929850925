import React from "react";
import styled from "styled-components";
import Group6699 from "../../img/fleet-efficiency/group-6699.svg";
import Group6808 from "../../img/fleet-efficiency/group-6808.svg";
import Group6805 from "../../img/fleet-efficiency/group-6805.svg";

const FleetEfficiencySecondContentWrapper = styled.div`
  padding: 200px 375px;
  .container {
    background-color: #fff;
    h2 {
      ${(props) => props.theme.h2_48};
      color: #202945;
      text-align: center;
    }
    .container-inner {
      display: flex;
      margin-top: 100px;
      margin-left: -30px;
      margin-right: -30px;
      .container-cards {
        flex-basis: 33.3333%;
        padding-right: 30px;
        padding-left: 30px;
        .container-cards-inner {
          position: relative;
          overflow: hidden;
          border-radius: 5px;
          height: 100%;
          padding: 30px 0 60px;
          text-align: center;
          p {
            ${(props) => props.theme.body_20};
            color: #6b7ba4;
            margin-top: 60px;
          }
          img {
            display: block;
            width: 200px;
            height: 140px;
            margin: 0 auto;
          }
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 150px 275px;
    .container {
      h2 {
        font-size: 38px;
        line-height: 53px;
      }
      .container-inner {
        .container-cards {
          .container-cards-inner {
            p {
              font-size: 18px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }

  @media (max-width:1439px){
    padding: 150px 50px;
  }
  @media (max-width:1023px){
    padding: 100px 20px;
    .container {
      h2 {
        font-size: 28px;
        line-height: 39px;
      }
      .container-inner {
        margin-top:50px;
        margin-left:-15px;
        margin-right:-15px;
        .container-cards {
          padding-left:15px;
          padding-right:15px;
          .container-cards-inner {
            p {
              font-size: 18px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
  @media (max-width:767px){
    .container {
      h2 {
        font-size: 25px;
        line-height: 34px;
      }
      .container-inner {
        margin-top:50px;
        margin-left:0;
        margin-right:0;
        display:block;
        .container-cards {
          padding-left:0;
          padding-right:0;
          .container-cards-inner {
            p {
              font-size: 18px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
`;
const FleetEfficiencySecondContent = () => {
  return (
    <FleetEfficiencySecondContentWrapper>
      <div className="container">
        <h2 className="t" data-translate="fleetEfficiencySecondContentTitle">
          Reasons To Have an Automated Fleet Efficiency Solution
        </h2>
        <div className="container-inner">
          <div className="container-cards">
            <div className="container-cards-inner">
              <img alt="" src={Group6699} />
              <p
                className="t"
                data-translate="fleetEfficiencySecondContentFirstCardTitle"
              >
                Make fact-based, informed decisions about the size and usage of
                your fleet
              </p>
            </div>
          </div>
          <div className="container-cards">
            <div className="container-cards-inner">
              <img alt="" src={Group6805} />
              <p
                className="t"
                data-translate="fleetEfficiencySecondContentSecondCardTitle"
              >
                Reduce fuel and maintenance costs that will save your time and
                money
              </p>
            </div>
          </div>
          <div className="container-cards">
            <div className="container-cards-inner">
              <img alt="" src={Group6808} />
              <p
                className="t"
                data-translate="fleetEfficiencySecondContentThirdCardTitle"
              >
                See the big picture and improve the way your business operations
                work
              </p>
            </div>
          </div>
        </div>
      </div>
    </FleetEfficiencySecondContentWrapper>
  );
};

export default FleetEfficiencySecondContent;
