const theme = {
  color_blue: "#202945",
  secondary_color: "#6b7ba4",
  body_20: {
    "font-family": "TT Commons",
    "font-size": "20px",
    "line-height": "28px",
    "font-weight": "500",
    color: `#202945`,
  },
  h1_64: {
    "font-family": "TT Commons",
    "font-size": "64px",
    "line-height": "72px",
    "font-weight": "600",
    color: `#202945`,
  },
  body_24: {
    "font-family": "TT Commons",
    "font-size": "24px",
    "line-height": "32px",
    "font-weight": "500",
    color: `#202945`,
  },
  h2_48: {
    "font-family": "TT Commons",
    "font-size": "48px",
    "line-height": "56px",
    "font-weight": "600",
    color: `#fff`,
  },
  body_11: {
    "font-family": "TT Commons",
    "font-size": "11px",
    "line-height": "15px",
    "font-weight": "500",
    color: `#6b7ba4`,
  },
  body_16: {
    "font-family": "TT Commons",
    "font-size": "16px",
    "line-height": "28px",
    "font-weight": "500",
    color: `#6b7ba4`,
  },
  menu_links: {
    "font-family": "TT Commons",
    "font-size": "18px",
    "line-height": "25px",
    "font-weight": "700",
    color: "#1A284D",
  },
  language: {
    "font-family": "TT Commons",
    "font-size": "18px",
    "line-height": "21px",
    "font-weight": "600",
    color: `#202945`,
  },
  log_in: {
    "font-family": "TT Commons",
    "font-size": "18px",
    "line-height": "25px",
    "letter-spacing": "0.45px",
    "font-weight": "600",
    "text-transform": "uppercase",
    color: "#2ED9C3",
  },
  footer_titles: {
    "font-family": "TT Commons",
    "font-size": "20px",
    "line-height": "28px",
    "font-weight": "600",
    color: `#202945`,
    "text-transform": "uppercase",
    "letter-spacing": "3px",
  },
  footer_14: {
    "font-family": "TT Commons",
    "font-size": "14px",
    "line-height": "19px",
    "font-weight": "500",
    color: `#6b7ba4`,
    "text-transform": "capitalize",
  },
};

export default theme;
