import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import OfferImg from "../../img/contact/offer-img.png";
import ListIcon from "../../img/list-icon.svg";

const ContactOfferWrapper = styled.div`
  padding: 0 375px 95px;
  background-color: #f2f7f9;
  .container {
    display: flex;
    align-items: center;
    .left {
      img {
        margin-top: -50px;
        display: block;
        width: 100%;
      }
    }
    .right {
      flex-basis: 40%;
      margin-left: 100px;
      h2 {
        ${(props) => props.theme.h2_48};
        color: #202945;
        margin-bottom: 25px;
      }
      p {
        ${(props) => props.theme.body_20};
        letter-spacing: 3px;
        text-transform: uppercase;
        margin-bottom: 25px;
      }
      ul {
        padding-left: 24px;
        margin-bottom: 45px;
        list-style-image: url(${ListIcon});
        li {
          ${(props) => props.theme.body_20};
          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }
      }
      > span {
        ${(props) => props.theme.body_20};
        display: block;
        white-space: pre-line;
        margin-bottom: 25px;
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0 275px 95px;

    .container {
      .right {
        margin-left: 50px;
        h2 {
          font-size: 36px;
          line-height: 48px;
        }
        p {
          font-size: 18px;
          line-height: 25px;
        }
        ul {
          li {
            font-size: 18px;
            line-height: 25px;
          }
        }
        > span {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
  @media (max-width: 1439px) {
    padding: 0 50px 95px;
  }

  @media (max-width: 1023px) {
    padding: 0 20px 60px;
    .container {
      .left {
        flex-basis: 50%;
      }
      .right {
        flex-basis: 50%;
        margin-left: 20px;
        h2 {
          font-size: 32px;
          line-height: 43px;
          margin-bottom: 15px;
        }
        p {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 15px;
        }
        ul {
          margin-bottom: 15px;

          li {
            font-size: 16px;
            line-height: 22px;
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
        > span {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 15px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding:30px 20px 30px;
    .container {
      display: block;
      .left{
        img{
          margin-top:0;
        }
      }
      .right{
        margin-left:0;
      }
    }
  }
`;
const ContactOffer = () => {
  return (
    <ContactOfferWrapper>
      <div className="container">
        <div className="left">
          <img src={OfferImg} alt=""/>
        </div>
        <div className="right">
          <h2 className="t" data-translate="contactOfferTitle">
            Receive your offer now
          </h2>
          <p className="t" data-translate="contactOfferListTitle">
            OUR FULL SERVICE OFFER INCLUDES:
          </p>
          <ul>
            <li className="t" data-translate="contactOfferListText1">
              Expert consultations to find the best solution
            </li>
            <li className="t" data-translate="contactOfferListText2">
              Devices' installation and problem monitoring
            </li>
            <li className="t" data-translate="contactOfferListText3">
              Exceptional customer support team
            </li>
          </ul>
          <span className="t" data-translate="contactOfferTitle2">
            Want to learn more about our products and solutions? Fill out the
            form and receive a free quote.
          </span>
          <Link to="/" className="receive-quote-btn">
            <span className="t" data-translate="receiveQuoteButton">
              Get a free Quote
            </span>
          </Link>
        </div>
      </div>
    </ContactOfferWrapper>
  );
};

export default ContactOffer;
