import React from "react";
import CookiesSettings from "../CookiesSettings/CookiesSettings";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import ErpIntegrationFirstContent from "./ErpIntegrationFirstContent";
import ErpIntegrationIntro from "./ErpIntegrationIntro";
const ErpIntegrationPage = () => {
  return (
    <>
      <MenuFixed />
      <ErpIntegrationIntro />
      <ErpIntegrationFirstContent />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default ErpIntegrationPage;
