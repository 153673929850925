import React from "react";
import styled from "styled-components";
import BackgroundIntro from "../../img/quote_background.svg";
import ApiIntegration from "../../img/api.svg";

const ApiIntegrationIntroWrapper = styled.div`
  background-image: url(${BackgroundIntro});
  padding: 150px 375px 0;
  .container {
    display: flex;
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #dce3eb;

    .left {
      flex-basis: 35%;
      padding: 100px 0;
      border-right: 1px solid #dce3eb;
      img {
        display: block;
        width: 254px;
        height: 277px;
        margin: 0 auto;
      }
    }
    .right {
      flex-basis: 65%;
      padding: 25px 100px;
      border-top-right-radius: 5px;
      display: flex;
      align-items: center;
      background-color: #fff;
      .right-wrapper {
        h1 {
          ${(props) => props.theme.h1_64};
          margin-bottom: 25px;
        }
        p {
          ${(props) => props.theme.body_24};
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 150px 275px 0;
    .container {
      .left {
        padding: 50px 0;
      }
      .right {
        padding: 0 50px;
        .right-wrapper {
          h1 {
            font-size: 48px;
            line-height: 67px;
          }
          p {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 150px 50px 0;
    .container {
      .left {
        padding: 30px 0;
      }
      .right {
        padding: 0 15px;
      }
    }
  }
  @media (max-width: 1023px) {
    padding: 150px 20px 0;
    .container {
      .left {
        img {
          width: 200px;
          height: 230px;
        }
      }
      .right {
        padding: 0 15px;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 100px 20px 0;
    .container {
      display: block;
      .left {
        padding: 15px 0 0;
        border-right: none;
        img {
          height: 100px;
        }
      }
      .right {
        padding: 15px;
        .right-wrapper {
          h1 {
            font-size: 30px;
            line-height: 42px;
            margin-bottom: 10px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
      }
    }
  }
`;
const ApiIntegrationIntro = () => {
  return (
    <ApiIntegrationIntroWrapper>
      <div className="container">
        <div className="left">
          <img src={ApiIntegration} alt=""/>
        </div>
        <div className="right">
          <div className="right-wrapper">
            <h1 className="t" data-translate="apiIntegrationIntroTitle">
              API Integration
            </h1>
            <p className="t" data-translate="apiIntegrationIntroText">
              We offer a variety of integrations to automatically send and
              receive fleet data from external systems. From the leading IT
              systems in transport and logistics industry, to custom
              integrations with your accounting and warehouse system - we'll
              make sure you have everything you need in one place
            </p>
          </div>
        </div>
      </div>
    </ApiIntegrationIntroWrapper>
  );
};

export default ApiIntegrationIntro;
