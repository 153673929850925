import React from "react";
import styled from "styled-components";
import Group7357 from "../../img/gps/group-7357.svg";
import Group7304 from "../../img/gps/group-7304.svg";
import Group7356 from "../../img/gps/group-7356.svg";

const GpsSecondContentWrapper = styled.div`
  padding: 0px 375px 200px;
  margin-top: -115px;
  position: relative;
  z-index: 1;
  .container {
    box-shadow: 0px 4px 20px #1a284d14;
    border-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      padding: 100px 100px 80px;
      .left {
        margin-right: 30px;

        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
          ${(props) => props.theme.body_20};
        }
      }

      .right {
        img {
          width: 370px;
          display: block;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #dce3eb;
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0px 275px 150px;
    .container {
      .container-cards {
        padding: 40px 25px;
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
      }
    }
  }
  @media (max-width: 1439px) {
    padding: 0px 50px 100px;
  }

  @media (max-width: 1023px) {
    padding: 0px 20px 50px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            width: 250px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      .container-cards {
        padding: 25px 15px;
        display: block;
        .left {
          margin-right: 0;
          margin-bottom: 15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
      }
    }
  }
`;
const GpsSecondContent = () => {
  return (
    <GpsSecondContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="GPSSecondContentFirstCardTitle">
              Track the Temperature Changes
            </h2>
            <p className="t" data-translate="GPSSecondContentFirstCardText">
              Being aware of the temperature changes and battery levels in your
              refrigerator and heating units will eliminate the risk of human
              error and prevent your company from potential financial losses.
              Besides, you can have all the data from Carrier and Thermo King
              displayed in our system.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7357} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="GPSSecondContentSecondCardTitle">
              Stay in Control
            </h2>
            <p className="t" data-translate="GPSSecondContentSecondCardText">
              Unexpected things happen. Swift decision making and adapting to
              the situation is very important when it does. GPS tracking and
              live map can help by providing an overview of your fleet at a
              glance so you can always stay in control.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7304} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="GPSSecondContentThirdCardTitle">
              Live Map That Shows It All
            </h2>
            <p className="t" data-translate="GPSSecondContentThirdCardText">
              Real-time location, driver behaviour, refuelling map, fuel
              consumption and more - our live map has it all in one place to
              make your fleet management processes easier.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7356} />
          </div>
        </div>
      </div>
    </GpsSecondContentWrapper>
  );
};

export default GpsSecondContent;
