import React, { useContext } from "react";
import styled from "styled-components";
import Group7357 from "../../img/products/pro/group-7357.svg";
import Group6087 from "../../img/products/pro/group-6087.svg";
import Group7066 from "../../img/gps/group-7066.svg";
import Group6208 from "../../img/fuel-control/group-6208.svg";
import Group6330 from "../../img/tachograph/group-6330.png";
import Group6611 from "../../img/fleet-maintenance/group-6611.svg";
import Group6449 from "../../img/tachograph/group-6449.png";
import Group7364 from "../../img/temperature-control/group-7364.svg";
import Group8393 from "../../img/reports/group-8393.png";
import Group8395 from "../../img/reports/group-8395.png";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../Consts/GlobalContext";

const ProductsProFirstContentWrapper = styled.div`
  padding: 0px 375px;
  margin-bottom: -115px;
  position: relative;
  z-index: 1;
  .container {
    padding: 80px 100px 120px;
    box-shadow: 0px 25px 35px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    h1 {
      ${(props) => props.theme.h1_64};
      text-align: center;
      margin-bottom: 65px;
      span {
        &:first-child {
          color: #2ed9c3;
        }
      }
    }
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        margin-right: 30px;

        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
          ${(props) => props.theme.body_20};
          margin-bottom: 30px;
        }
        .receive-quote-btn {
          padding: 20px 70px;
        }
      }

      .right {
        img {
          width: 370px;
          display: block;
          border-radius: 5px;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }

      &:nth-child(2) {
        img {
          box-shadow: -10px 10px 40px #1a284d14;
        }
      }
      &:nth-child(6) {
        .right {
          img {
            display: block;
            box-shadow: -10px 10px 40px #1a284d1a;
          }
          img:first-of-type {
            width: 315px;
            transition: all 0.6s ease-in-out;
            transform: translate(30px, 20px);
          }
          img:last-of-type {
            transition: all 0.6s ease-in-out;
            position: relative;
            z-index: 1;
          }
          &:hover {
            img:first-of-type {
              transform: translate(30px, -20px);
            }
            img:last-of-type {
              transform: translateY(20px);
            }
          }
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0px 275px;
    .container {
      padding: 30px 25px 40px;
      h1 {
        font-size: 58px;
      }
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 0px 50px;
    .container {
      .container-cards {
        .left {
          .receive-quote-btn {
            padding: 15px 45px;
          }
        }
      }
    }
  }
  @media (max-width: 1023px) {
    padding: 0px 20px;
    .container {
      h1 {
        font-size: 48px;
        line-height: 67px;
      }
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            width: 250px;
          }
        }

        &:nth-child(6) {
          .right {
            img:first-of-type {
              width: 260px;
              transform: translate(20px, 20px);
            }
            img:last-of-type {
              width: 300px;
            }
            &:hover {
              img:first-of-type {
                transform: translate(20px, -10px);
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      h1 {
        font-size: 38px;
        line-height: 53px;
      }
      .container-cards {
        display: block;
        .left {
          margin-right: 0;
          margin-bottom: 15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
          .receive-quote-btn {
            padding: 10px 25px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }

        &:nth-child(6) {
          .right {
            img:first-of-type {
              width: 260px;
              transform: translate(0px, 20px);
            }
            img:last-of-type {
              width: 300px;
            }
            &:hover {
              img:first-of-type {
                transform: translate(0px, 0px);
              }
            }
          }
        }
      }
    }
  }
`;
const ProductsProFirstContent = () => {
  const { lang } = useContext(GlobalContext);
  return (
    <ProductsProFirstContentWrapper>
      <div className="container">
        <h1>
          IceTrackr <span>Pro</span>{" "}
          <span className="t" data-translate="productsProLiteFirstContentTitle">
            Platform Solutions
          </span>
        </h1>
        <div>
          <div className="container-cards">
            <div className="left">
              <h2
                className="t"
                data-translate="productsProFirstContentFirstCardTitle"
              >
                GPS tracking with live data
              </h2>
              <p
                className="t"
                data-translate="productsProFirstContentFirstCardText"
              >
                See the exact location, current route and speed of all your
                vehicles in your fleet in real-time
              </p>
              <Link
                to="/solutions/gps-tracking-and-live-data"
                className="receive-quote-btn"
              >
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </div>
            <div className="right">
              <img alt="" src={Group7066} />
            </div>
          </div>
          <div className="container-cards">
            <div className="left">
              <h2
                className="t"
                data-translate="productsProFirstContentSecondCardTitle"
              >
                Precise Fuel Consumption
              </h2>
              <p
                className="t"
                data-translate="productsProFirstContentSecondCardText"
              >
                Oversee your fleet’s fuel levels with high accuracy fuel
                sensors. See the average fuel consumption for your vehicles over
                different time periods. Identify vehicles with the highest fuel
                consumption and their routes.
              </p>
              <Link to="/solutions/fuel-control" className="receive-quote-btn">
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </div>
            <div className="right">
              <img alt="" src={Group6208} />
            </div>
          </div>
          <div className="container-cards">
            <div className="left">
              <h2
                className="t"
                data-translate="productsProFirstContentThirdCardTitle"
              >
                Driver and Vehicle File Download
              </h2>
              <p
                className="t"
                data-translate="productsProFirstContentThirdCardText"
              >
                Digital tachograph remote download solution allows you to
                download both driver and vehicle card data. As soon as the
                driver inserts the card in the tachograph, our system
                automatically checks, whether a download needs to be done and
                starts it, if necessary
              </p>
              <Link
                to="/solutions/digital-tachograph-remote-download"
                className="receive-quote-btn"
              >
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </div>
            <div className="right">
              <img alt="" src={Group6330} />
            </div>
          </div>
          <div className="container-cards">
            <div className="left">
              <h2
                className="t"
                data-translate="productsProFirstContentFourthCardTitle"
              >
                Fleet Maintenance
              </h2>
              <p
                className="t"
                data-translate="productsProFirstContentFourthCardText"
              >
                Set reminders, keep track of repair costs, manage licenses and
                other documents. You'll also get automated calculations of your
                fleet's efficiency to see whether changes are needed
              </p>
              <Link
                to="/solutions/fleet-maintenance"
                className="receive-quote-btn"
              >
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </div>
            <div className="right">
              <img alt="" src={Group6611} />
            </div>
          </div>
          <div className="container-cards">
            <div className="left">
              <h2
                className="t"
                data-translate="productsProFirstContentFifthCardTitle"
              >
                Task Manager
              </h2>
              <p
                className="t"
                data-translate="productsProFirstContentFifthCardText"
              >
                Assign tasks, run vehicle inspections and communicate with your
                drivers on the go.
              </p>
              <Link
                to="/solutions/task-management"
                className="receive-quote-btn"
              >
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </div>
            <div className="right">
              <img alt="" src={Group6449} />
            </div>
          </div>
          <div className="container-cards">
            <div className="left">
              <h2
                className="t"
                data-translate="productsProFirstContentSixthCardTitle"
              >
                Reports
              </h2>
              <p
                className="t"
                data-translate="productsProFirstContentSixthCardText"
              >
                Have a overview of all the fleet data represented in a variety
                of ways, and a easy download of all this reports via PDF or
                Excel format.
              </p>
              <Link to="/solutions/reports" className="receive-quote-btn">
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </div>
            <div className="right">
              <img alt="" src={Group8395} />
              <img alt="" src={Group8393} />
            </div>
          </div>
          <div className="container-cards">
            <div className="left">
              <h2
                className="t"
                data-translate="productsProFirstContentSeventhCardTitle"
              >
                Temperature monitoring
              </h2>
              <p
                className="t"
                data-translate="productsProFirstContentSeventhCardText"
              >
                Get notified about regrigeration system failures and analyse
                data conveniently to eliminate human error, cut costs and
                improve efficiency
              </p>
              <Link
                to="/solutions/temperature-control"
                className="receive-quote-btn"
              >
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </div>
            <div className="right">
              <img alt="" src={Group7357} />
            </div>
          </div>
          <div className="container-cards">
            <div className="left">
              <h2
                className="t"
                data-translate="productsProFirstContentEighthCardTitle"
              >
                Vehicle Inspection
              </h2>
              <p
                className="t"
                data-translate="productsProFirstContentEighthCardText"
              >
                Catch issues early by using simple adaptable inspection forms.
                You can also schedule inspections and add photos
              </p>
              <Link
                to="/solutions/vehicle-inspection"
                className="receive-quote-btn"
              >
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </div>
            <div className="right">
              <img alt="" src={Group6087} />
            </div>
          </div>
          <div className="container-cards">
            <div className="left">
              <h2
                className="t"
                data-translate="productsProFirstContentNinthCardTitle"
              >
                Receive Instant Alerts
              </h2>
              <p
                className="t"
                data-translate="productsProFirstContentNinthCardText"
              >
                Set up alerts to notify your drivers and dispatchers as soon as
                any temperature changes appear and save your business from
                unexpected costs.
              </p>
              <Link to="/solutions/alerts" className="receive-quote-btn">
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </div>
            <div className="right">
              <img alt="" src={Group7364} />
            </div>
          </div>
        </div>
      </div>
    </ProductsProFirstContentWrapper>
  );
};

export default ProductsProFirstContent;
