import React from "react";
import ContactTeam from "../Contact/ContactTeam";
import CookiesSettings from "../CookiesSettings/CookiesSettings";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import ProductsLiteFirstContent from "./ProductsLiteFirstContent";
import ProductsLiteIntro from "./ProductsLiteIntro";

const ProductsLitePage = () => {
  return (
    <>
      <MenuFixed />
      <ProductsLiteIntro />
      <ProductsLiteFirstContent />
      <ContactTeam />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default ProductsLitePage;
