import React from "react";
import styled from "styled-components";
import Group7702 from "../../img/task-management/group-7702.png";
import ListIcon from "../../img/list-icon.svg";

const TaskManagementSecondContentWrapper = styled.div`
  padding: 0px 375px 200px;
  margin-top: -115px;
  position: relative;
  z-index: 1;
  .container {
    padding: 75px 100px 70px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        flex-basis: 75%;

        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        ul {
          padding-left: 24px;
          list-style-image: url(${ListIcon});
          li {
            ${(props) => props.theme.body_20};
            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }
      }

      .right {
        flex-basis: 25%;
        img {
          width: 370px;
          display: block;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0px 275px 150px;
    .container {
      padding: 30px 25px 40px;
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }
  @media (max-width: 1439px) {
    padding: 0px 50px 100px;
  }

  @media (max-width: 1023px) {
    padding: 0px 20px 50px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
          ul {
            li {
              font-size: 15px;
              line-height: 21px;
            }
          }
        }
        .right {
          img {
            width: 250px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      .container-cards {
        display: block;
        .left {
          margin-right: 0;
          margin-bottom: 15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
      }
    }
  }
`;
const TaskManagementSecondContent = () => {
  return (
    <TaskManagementSecondContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="taskManagementSecondContentFirstCardTitle"
            >
              Communication Tool for Drivers and Back-Office
            </h2>
            <ul>
              <li
                className="t"
                data-translate="taskManagementSecondContentFirstCardText1"
              >
                Reach your employees anywhere, anytime
              </li>
              <li
                className="t"
                data-translate="taskManagementSecondContentFirstCardText2"
              >
                Exchange invoices, photos and other media on-the-go
              </li>
              <li
                className="t"
                data-translate="taskManagementSecondContentFirstCardText3"
              >
                Connect with the back-office using one communications platform
              </li>
              <li
                className="t"
                data-translate="taskManagementSecondContentFirstCardText4"
              >
                Transfer the necessary information, using messaging group and
                conversation archive
              </li>
              <li
                className="t"
                data-translate="taskManagementSecondContentFirstCardText5"
              >
                Have a fast, user-friendly interface optimised for low data
                usage
              </li>
            </ul>
          </div>
          <div className="right">
            <img src={Group7702} alt=""/>
          </div>
        </div>
      </div>
    </TaskManagementSecondContentWrapper>
  );
};

export default TaskManagementSecondContent;
