import React from "react";
import styled from "styled-components";
import Group7797 from "../../img/about-us/group-7797.png";

const AboutUsFirstContentWrapper = styled.div`
  padding: 0 375px 190px;
  margin-top: -200px;

  .wrapper {
    display: flex;
    .left {
      flex-basis: 50%;
      margin-right: 100px;
      h2 {
        ${(props) => props.theme.h2_48};
        color: #202945;
        margin-bottom: 15px;
      }
      p {
        ${(props) => props.theme.body_20}
      }
    }
    .right {
      flex-basis: 50%;
      img {
        width: 100%;
        display:block;
      }
    }
    &:not(:last-of-type) {
      margin-bottom: 110px;
    }
    &:nth-of-type(2n) {
      .left {
        order: 1;
        margin-right: 0;
        margin-left: 100px;
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0 275px 190px;
  }
  @media (max-width: 1439px) {
    padding: 0 50px 190px;
    .wrapper {
      .left {
        h2 {
          font-size: 38px;
          line-height: 53px;
        }
        p {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
  @media (max-width: 1439px) {
    padding: 0 50px 120px;
    .wrapper {
      .left {
        h2 {
          font-size: 28px;
          line-height: 39px;
        }
        p {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
  @media (max-width: 1023px) {
    padding: 0 20px 80px;

  }
  @media (max-width: 767px) {
    .wrapper {
      display: block;
      .left {
        margin-right: 0;
        margin-bottom:20px;
        h2 {
          font-size: 25px;
          line-height: 35px;
        }
      }
      &:nth-of-type(2n) {
        .left {
          margin-left: 0;
        }
      }
      &:not(:last-of-type) {
        margin-bottom: 50px;
      }
    }
  }
`;
const AboutUsFirstContent = () => {
  return (
    <AboutUsFirstContentWrapper>
      <div className="wrapper">
        <div className="left">
          <h2 className="t" data-translate="aboutUsFirstContentFirstCardTitle">
            We Help Businesses to Manage Fleets
          </h2>
          <p className="t" data-translate="aboutUsFirstContentFirstCardText">
            Our journey began in 2012 when we started to build our fleet
            management and asset tracking platform and got our first clients,
            many of which are still working with us.
          </p>
        </div>
        <div className="right">
          <img src={Group7797} alt=""/>
        </div>
      </div>
      <div className="wrapper">
        <div className="left">
          <h2 className="t" data-translate="aboutUsFirstContentSecondCardTitle">
            Efforts
          </h2>
          <p className="t" data-translate="aboutUsFirstContentSecondCardText">
            We've put a lot of effort to make sure our products are
            user-friendly and reliable. It's resulted in thousands of millions
            in tracked kilometers
          </p>
        </div>
        <div className="right">
          <img src={Group7797} alt=""/>
        </div>
      </div>
    </AboutUsFirstContentWrapper>
  );
};

export default AboutUsFirstContent;
