import React from "react";
import styled from "styled-components";
import Group5699 from "../../img/dashboard/group-5699.svg";
import Group7570 from "../../img/dashboard/group-7570.svg";
import Group7360 from "../../img/dashboard/group-7360.svg";
import Group6142 from "../../img/dashboard/group-6142.svg";
import Group6141 from "../../img/dashboard/group-6141.svg";
import Ellipse569 from "../../img/dashboard/ellipse-569.svg";
import Group5907 from "../../img/dashboard/group-5907.png";

const DashboardSecondContentWrapper = styled.div`
  padding: 0px 375px 200px;
  position: relative;
  h2 {
    ${(props) => props.theme.h2_48};
    color: #202945;
    margin-bottom: 125px;
    text-align: center;
  }
  .ellipse {
    position: absolute;
    left: -390px;
    top: 0;
    z-index: -1;
  }
  .container {
    display: flex;
    .left {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      position: relative;
      .cards {
        flex-basis: 25%;
        min-height: 150px;
        width: 270px;
        transition: all 0.6s ease-in-out;
        .inner-cards {
          background-color: white;
          box-shadow: 0px 4px 20px #1a284d14;
          border-radius: 10px;
          padding: 10px 60px;
          height: 100%;
          text-align: center;
          span {
            ${(props) => props.theme.body_16}
            white-space: nowrap;
          }
          .img-wrapper {
            width: 100%;
            min-height: 75px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
            img {
              display: block;
            }
          }
        }
        &:not(:last-child) {
          margin-bottom: 45px;
        }
        &:nth-child(2n) {
          position: absolute;
          height: 150px;
          margin-bottom: 0;
          z-index: 1;
        }
        &:nth-of-type(2) {
          top: 95px;
          right: 80px;
          transition: all 0.6s ease-in-out;
        }
        &:nth-of-type(4) {
          top: 300px;
          right: 110px;
          transition: all 0.6s ease-in-out;
        }
      }
      &:hover .cards:nth-of-type(1) {
        transform: translateX(-50px);
      }
      &:hover .cards:nth-of-type(2) {
        right: 0;
      }
      &:hover .cards:nth-of-type(3) {
        transform: translateX(-90px);
      }
      &:hover .cards:nth-of-type(4) {
        right: 20px;
      }
      &:hover .cards:nth-of-type(5) {
        transform: translateX(-65px);
      }
    }
    .right {
      flex-basis: 50%;
      display: flex;
      align-items: center;
      img {
        display: block;
        width: 100%;
        height: 370px;
        box-shadow: -4px 10px 60px #1a284d17;
        border-radius: 5px;
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 150px 275px;
    .container {
      .left {
        .cards {
          width: 200px;
          .inner-cards {
            padding: 10px 15px;
          }
          &:nth-of-type(4) {
            right: 95px;
          }
        }
      }
    }
  }
  @media (max-width: 1439px) {
    padding: 100px 50px;
    h2 {
      margin-bottom: 75px;
    }
    .container {
      .left {
        &:hover .cards:nth-of-type(1) {
          transform: translateX(-20px);
        }
        &:hover .cards:nth-of-type(2) {
          right: 10px;
        }
        &:hover .cards:nth-of-type(3) {
          transform: translateX(-40px);
        }
        &:hover .cards:nth-of-type(4) {
          right: 30px;
        }
        &:hover .cards:nth-of-type(5) {
          transform: translateX(-30px);
        }
      }
    }
  }

  @media (max-width: 1023px) {
    padding: 100px 20px;
    h2 {
      font-size: 38px;
      line-height: 53px;
    }
    .container {
      display: block;
      .left {
        align-items: center;
        &:hover .cards:nth-of-type(1) {
          transform: translateX(-50px);
        }
        &:hover .cards:nth-of-type(2) {
          right: 0;
        }
        &:hover .cards:nth-of-type(3) {
          transform: translateX(-90px);
        }
        &:hover .cards:nth-of-type(4) {
          right: 20px;
        }
        &:hover .cards:nth-of-type(5) {
          transform: translateX(-65px);
        }
      }
    }
  }

  @media (max-width: 767px) {
    h2{
      font-size: 28px;
      line-height: 39px;
      margin-bottom:25px;
    }
    .container {
      .left {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: unset;
        margin-right: -8px;
        margin-left: -8px;
        margin-bottom: 20px;
        .cards {
          flex-basis: 50%;
          min-height: auto;
          max-height: 100%;
          padding: 8px;
          &:nth-child(2n) {
            position: static;
            height: auto;
            margin-bottom: 0;
            z-index: 1;
          }
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
        &:hover .cards:nth-of-type(1) {
          transform: none;
        }
        &:hover .cards:nth-of-type(3) {
          transform: none;
        }
        &:hover .cards:nth-of-type(5) {
          transform: none;
        }
      }
    }
  }
`;
const DashboardSecondContent = () => {
  return (
    <DashboardSecondContentWrapper>
      <h2>All You Need In 5 Simple Blocks</h2>
      <img alt="" src={Ellipse569} className="ellipse" />
      <div className="container">
        <div className="left">
          <div className="cards">
            <div className="inner-cards">
              <div className="img-wrapper">
                <img alt="" src={Group5699} />
              </div>
              <span
                className="t"
                data-translate="dashboardSecondContentFirstCardTitle"
              >
                Fleet Efficiency
              </span>
            </div>
          </div>
          <div className="cards">
            <div className="inner-cards">
              <div className="img-wrapper">
                <img alt="" src={Group7570} />
              </div>
              <span
                className="t"
                data-translate="dashboardSecondContentSecondCardTitle"
              >
                Working Time
              </span>
            </div>
          </div>
          <div className="cards">
            <div className="inner-cards">
              <div className="img-wrapper">
                <img alt="" src={Group6141} />
              </div>
              <span
                className="t"
                data-translate="dashboardSecondContentThirdCardTitle"
              >
                Fuel Control
              </span>
            </div>
          </div>
          <div className="cards">
            <div className="inner-cards">
              <div className="img-wrapper">
                <img alt="" src={Group7360} />
              </div>
              <span
                className="t"
                data-translate="dashboardSecondContentFourthCardTitle"
              >
                Mileage
              </span>
            </div>
          </div>
          <div className="cards">
            <div className="inner-cards">
              <div className="img-wrapper">
                <img alt="" src={Group6142} />
              </div>
              <span
                className="t"
                data-translate="dashboardSecondContentFifthCardTitle"
              >
                Driver Behaviour
              </span>
            </div>
          </div>
        </div>
        <div className="right">
          <img alt="" src={Group5907} />
        </div>
      </div>
    </DashboardSecondContentWrapper>
  );
};

export default DashboardSecondContent;
