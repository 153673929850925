import React from "react";
import styled from "styled-components";
import Group6193 from "../../img/fuel-control/group-6193.svg";
import Group6221 from "../../img/fuel-control/group-6221.svg";
import Group6226 from "../../img/fuel-control/group-6226.png";
import Group6208 from "../../img/fuel-control/group-6208.svg";

const FuelControlFirstContentWrapper = styled.div`
  padding: 0px 375px;
  margin-bottom: -115px;
  position: relative;
  z-index: 1;
  .container {
    padding: 80px 100px 120px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        margin-right: 30px;

        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
          ${(props) => props.theme.body_20};
        }
      }

      .right {
        img {
          width: 370px;
          display: block;
          box-shadow: -10px 10px 40px #1a284d14;
          border-radius: 5px;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }
      &:last-child {
        img {
          box-shadow: none;
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0px 275px;
    .container {
      padding: 30px 25px 40px;
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 0px 50px;
  }
  @media (max-width: 1023px) {
    padding: 0px 20px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            width: 250px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      .container-cards {
        display: block;
        .left {
          margin-right: 0;
          margin-bottom: 15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
`;
const FuelControlFirstContent = () => {
  return (
    <FuelControlFirstContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="fuelControlFirstContentFirstCardTitle"
            >
              Precise Fuel Consumption
            </h2>
            <p
              className="t"
              data-translate="fuelControlFirstContentFirstCardText"
            >
              Oversee your fleet’s fuel levels with high accuracy fuel sensors.
              See the average fuel consumption for your vehicles over different
              time periods. Identify vehicles with the highest fuel consumption
              and their routes.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group6193} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="fuelControlFirstContentSecondCardTitle"
            >
              Real-time Fuel Level
            </h2>
            <p
              className="t"
              data-translate="fuelControlFirstContentSecondCardText"
            >
              Monitoring real-time fuel levels for the whole fleet helps with
              planning refuelling stops. That, in turn, leads to better route
              planning saving your company time and money.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group6226} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="fuelControlFirstContentThirdCardTitle"
            >
              Fuel Level Change Graphs and Tank Tracking
            </h2>
            <p
              className="t"
              data-translate="fuelControlFirstContentThirdCardText"
            >
              Identify refuelling locations and spot fuel thefts by using
              precise graphs. Follow the fuel amount in tanks and allow our
              solution to make the remaining fuel amount calculations for you.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group6208} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="fuelControlFirstContentFourthCardTitle"
            >
              Insightful Reports
            </h2>
            <p
              className="t"
              data-translate="fuelControlFirstContentFourthCardText"
            >
              IceTrackr collects different types of information, including data
              on fuel consumption and fuel stops, to visualise it and help you
              make better business decisions. Use the reports to compare
              vehicles, drivers, routes and ultimately improve your fleet’s
              overall fuel economy.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group6221} />
          </div>
        </div>
      </div>
    </FuelControlFirstContentWrapper>
  );
};

export default FuelControlFirstContent;
