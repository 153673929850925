import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../img/logo.svg";
import logoMobile from "../../img/logo-mobile.svg";
import logoWhite from "../../img/logo-white.svg";
import Garrow from "../../img/garrow.svg";
import Barrow from "../../img/barrow.svg";
import arrow from "../../img/arrow.svg";
import { Link } from "react-router-dom";
import solutionCategories from "./SolutionsCategories";
import { GlobalContext } from "../../Consts/GlobalContext";
import { useContext } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import Group8448 from "../../img/products/pro/group-8448.png";
import Group8464 from "../../img/products/lite/group-8464.png";

const NavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 94px;
  padding: 0px 100px;
  background-color: rgba(245, 248, 250, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  color: white;
  .logo {
    a {
      display: flex;
      position: relative;
      img:last-of-type {
        width: 160px;
        height: 48px;
        display: block;
        margin-left: 65px;
      }
      img:first-of-type,
      img:nth-of-type(2) {
        position: absolute;
        transition: all 0.3s ease-out;
      }
      img:first-of-type {
        left: 0;
        top: 0;
      }
      img:nth-of-type(2) {
        left: 20px;
        top: 15px;
      }
      &:hover {
        img:first-of-type {
          transform: rotate(180deg) translateY(1px);
          left: 20px;
        }
        img:nth-of-type(2) {
          transform: rotate(180deg) translateY(-5px);
          left: 2px;
        }
      }
    }
  }
  .inner-navbar {
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    .menu {
      display: flex;
      align-items: center;
      
      > li {
        position: relative;
        margin: 0 20px;
        list-style: none;
        height: 10vh;
        display: flex;
        align-items: center;

        > a {
          ${(props) => props.theme.menu_links};
          display: block;
          text-decoration: none;
          transition: all 0.6s ease-out;

          .arrow {
            margin-left: 10px;
            transition: all 0.6s ease-out;
          }
        }

        .sub-menu {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          list-style: none;
          top: 94px;
          background-color: white;
          box-shadow: 0px 10px 40px -20px rgba(26,40,77,0.7);
          left: -302px;
          padding: 45px 50px;
          border-radius: 15px;
          transition: all 0.6s ease-out;
          display: flex;
          flex-wrap: wrap;
          width: 95vw;
          ${(props) => props.theme.menu_links};
          > li {
            flex-basis: 25%;
            margin: 25px 0;
            display: flex;
            align-items: center;
            > a {
              display: flex;
              text-decoration: none;
              text-transform: capitalize;
              padding: 0 30px;
              align-items: center;
              ${(props) => props.theme.body_20};

              .sub-menu-image {
                display: flex;
                align-items: center;
                img {
                  width: 50px;
                  height: 50px;
                  display: block;
                }
              }
              span {
                margin-left: 15px;
              }
            }
          }
          .productsProImage{
            width:115px;
            display:block;
          }
          .productsProSpan,
          .productsLiteSpan{
            margin-left:5px;
          }
          .productsProSpan{
            color:#2ED9C3;
          }
          .productsLiteSpan{
            color:#0071CE;
          }
          &:before {
            content: "";
            display: inline-block;
            width: 40px;
            height: 40px;
            background-color: white;
            position: absolute;
            top: -18px;
            left: 368px;
            transform: rotate(45deg);
          }
        }
        .sub-menu-products{
          width:1240px;
          justify-content space-evenly;
          padding:0;
          >li{
            margin:0;
            padding:50px 130px;
          }
          >hr{
            margin:0 auto;
            border:none;
            border-left:1.5px solid #1A284D14;
          }
        }

        .sub-menu-active {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    li.active .menu-links {
      transform: translateY(5px);
      .arrow {
        transform: rotate(180deg);
      }
    }
    .right-menu {
      display: flex;
      align-items: center;
      .receive-quote-btn {
        margin-right: 20px;
        margin-left: 20px;
      }
      
    }
  }
  .log-in {
    ${(props) => props.theme.log_in};
    text-decoration: none;
  }
  .language{
    position: relative;
    text-decoration: none;
    ${(props) => props.theme.language};
    span {
      text-transform: uppercase;
    }
    .arrow {
      margin-left: 5px;
    }
    .language-submenu {
      position: absolute;
      top: 57px;
      visibility: hidden;
      opacity: 0;
      list-style: none;
      background-color: white;
      box-shadow: 0px 10px 40px #1a284d14;
      left: -30px;
      padding: 35px 40px;
      border-radius: 15px;
      transition: all 0.6s ease-out;
      z-index: -100;
      li {
        text-transform: uppercase;
      }
      li:not(:last-of-type) {
        margin-bottom: 10px;
      }
      &.active {
        visibility: visible;
        opacity: 1;
      }
      &:before {
        content: "";
        display: inline-block;
        width: 40px;
        height: 40px;
        background-color: white;
        position: absolute;
        top: -18px;
        left: 42px;
        transform: rotate(45deg);
      }
    }
  }
  .mobile-language{
    flex-basis:25%;
    margin-right:15px;
    display:none;
  }
  .mobile-menu{
    flex-basis:16%;
    display:none;
    .hamburger-menu{
      display:flex;
      flex-direction: column;
      span{
        display:block;
        width:100%;
        height:4px;
        background-color: #202945;
        border-radius:15px;
      }
      span:not(:last-of-type){
          margin-bottom:3px;
      }
    }
    .hamburger-menu.light-mobile-menu {
      span{
        background-color: white;
      }
    }

    .mobile-menu-opened{
      position: fixed;
      height: calc(var(--vh,1vh)*100);
      width:100%;
      left: 101%;
      top: 0;
      transition:left 0.4s ease-in-out;
      display:flex;
      .mobile-menu-blankspace{
        background-color: rgba(0,0,0,0.15);
        opacity:0;
        max-width:0;
        visibility: hidden;
        height:100%;
        flex-basis:30%;
        left:-125px;
        top:0;
        transition:all 0.3s ease-in-out;
        transition-delay:0.1s;
      }
      .mobile-menu-wrapper{
        display:flex;
        flex-direction: column;
        justify-content:space-between;
        height:100%;
        flex-basis:70%;
        padding: 33px 20px;
        background-color: #fff;

        ul{
          list-style: none;
          margin-top:50px;
          li{
            
            a{
              text-decoration: none;
              display:block;
            }
            &:not(:last-child){
              margin-bottom:35px;
            }
          }
  
          .mobile-menu-links{
            > a{
              ${(props) => props.theme.body_24};
              text-transform: capitalize;
              letter-spacing:1.5px;
              color:${(props) => props.theme.color_blue};
              display:flex;
              align-items: center;
              .arrow{
                margin-left:5px;
                width:12px;
                display:block;
                transition: all 0.6s ease-out;
              }
  
            }
            ul{
              overflow: hidden;
              display:flex;
              flex-wrap:wrap;
              max-height:0;
              transition:all 0.6s ease-out;
              li{
                flex-basis:50%;
                margin:5px 0;
                a{
                  ${(props) => props.theme.body_16}
                  line-height:23px;
                  color:lightskyblue;
                  text-transform:capitalize;
                }
              }
            }
            
            
          }
          .mobile-menu-links.show {
            .arrow{
              transform: rotate(180deg);
            }
            ul{
            max-height:500px;
            }
          }
        }
        .mobile-menu-top{
          display:flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom:50px;
          img{
            width:120px;
          }
          .mobile-menu-close{
            width:25px;
            height:25px;
            position:relative;
            span{
              display:block;
              width:100%;
              height:5px;
              background-color:#000;
              position:absolute;
              top:11px;
              left:0;
              border-radius:20px;
              &:first-of-type{
                transform: rotate(45deg) ;
              }
              &:last-of-type{
                transform: rotate(-45deg)
              }
            }
          }
        }
        .mobile-menu-contacts{
          ${(props) => props.theme.body_20};
          color:rgb(0, 113, 206);
          .mobile-menu-contacts-cards{
            display:flex;
            align-items: center;
            .contact-icons{
              display:block;
              width:24px;
              height:24px;
            }
            span:last-child{
              display:block;
              margin-left:10px;
            }
            &:not(:last-child){
              margin-bottom:15px;
            }
          }
          
        }
        .mobile-menu-bottom{
          .receive-quote-btn{
            display:block;
          }
          .log-in{
            margin-top:20px;
            display:block;
            text-align:center;
          }
        }
      }
    }
    .mobile-menu-opened.open{
      left:0;
      .mobile-menu-blankspace{
        opacity:1;
        visibility:visible;
        max-width:30%;
      }
    }
  }
  
  .light-theme {
    color: white !important;
  }
  

  @media (max-width: 1900px) {
    padding: 0px 50px;
    .inner-navbar {
      .menu {
        > li {
          .sub-menu {
            left:-260px;
            &:before {
              left:326px;
            }
            >li{
              margin:20px 0;
            }
          }
        }
      }
  }

  @media (max-width: 1439px) {
    padding: 0px 50px;
    .logo {
      a {
        img:last-of-type {
          width: 100px;
          height: 29px;
          margin-left: 45px;
        }
        img:first-of-type,
        img:nth-of-type(2) {
          width: 25px;
        }
        img:nth-of-type(2) {
          left: 10px;
          top: 10px;
        }
        &:hover {
          img:nth-of-type(2) {
            transform: rotate(180deg) translateY(-2px);
            left: 8px;
          }
        }
      }
    }
    .inner-navbar {
      .menu {
        > li {
          margin:0 15px;
          > a {
            font-size: 15px;
          }
          .sub-menu {
            width:992px;
            left:-194px;
            >li{
              >a{
                font-size: 17px;
                .sub-menu-image{
                  img{
                    width:40px;
                    height:40px;
                  }
                }
              }
            }
            &:before{
              left:248px;
            }
          }
        }
      }
      .right-menu {
        .log-in {
          font-size: 15px;
        }
        .language {
          font-size: 15px;
        }
      }
    }
  }

  @media (max-width:1023px){
    padding: 0 20px;
    justify-content:space-between;
    .inner-navbar {
      .menu,
      .right-menu{
        display:none;
      }
      
    }
    .mobile-menu{
      display:block;
      flex-basis:6%;
    }
    .mobile-language{
      display:block;
      flex-basis:10%;

    }
  }
  @media(max-width:767px){
    .mobile-menu{
      flex-basis:16%;
    }
    .mobile-language{
      flex-basis:25%;

    }
  }
  @media (max-width:403px){
    .mobile-menu{
      display:block;
      flex-basis:25%;
    }
    .mobile-language{
      display:block;
      flex-basis:45%;

    }
  }
`;
const MenuFixed = () => {
  const [activeMenu, setActiveMenu] = useState("false");
  const [transparentMenu, setTransparentMenu] = useState(false);
  const [openLanguage, setOpenLanguage] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const { lang, setLang, translateAll, openCookies, setOpenCookies } =
    useContext(GlobalContext);
  const handleSubMenu = (e) => {
    e.currentTarget.classList.add("active");
    e.currentTarget.children[1].classList.add("sub-menu-active");
  };
  const handleSubMenuClose = (e) => {
    e.currentTarget.children[1].classList.remove("sub-menu-active");
    e.currentTarget.classList.remove("active");
  };

  const handleLanguageOpen = (e) => {
    e.preventDefault();
    setOpenLanguage(!openLanguage);
  };

  const handleLanguageChange = (e) => {
    setLang(e.currentTarget.innerText.toLowerCase());
    localStorage.setItem("lang", e.currentTarget.innerText.toLowerCase());
  };

  const handleMobileMenuOpen = (e) => {
    setOpenMobileMenu(!openMobileMenu);
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const handleMobileMenuClose = (e) => {
    setOpenMobileMenu(false);
  };

  useEffect(() => {
    //menu active state
    if (window.location.pathname.split("/")[1] === "solutions") {
      setActiveMenu("solutions-active");
      if (window.location.pathname.split("/")[2] !== undefined) {
        setTransparentMenu(true);
      } else {
        setTransparentMenu(false);
      }
    } else if (window.location.pathname.split("/")[1] === "about-us") {
      setActiveMenu("aboutus-active");
    } else if (window.location.pathname.split("/")[1] === "contact") {
      setActiveMenu("contact-active");
    } else if (window.location.pathname.split("/")[1] === "products") {
      setActiveMenu("products-active");
    }

    if (localStorage.getItem("lang")) {
      setLang(localStorage.getItem("lang"));
    }
    translateAll();
  }, []);
  useEffect(() => {
    if (openMobileMenu) {
      document.body.classList.add("no-scrolling");
    } else if (openCookies) {
      document.body.classList.add("no-scrolling");
    } else {
      document.body.classList.remove("no-scrolling");
    }
  }, [openMobileMenu, openCookies]);

  return (
    <NavbarWrapper
      className={
        transparentMenu
          ? openMobileMenu
            ? "transparent-mobile-menu"
            : "transparent-menu"
          : undefined
      }
    >
      <div className="logo">
        <Link to="/">
          <img alt="" src={Garrow} />
          <img alt="" src={Barrow} />
          {transparentMenu ? (
            <img src={logoWhite} alt="logoWhite" />
          ) : (
            <img src={logo} alt="logo" />
          )}
        </Link>
      </div>
      <div className="inner-navbar">
        <ul className="menu">
          <li
            onMouseEnter={handleSubMenu}
            onMouseLeave={handleSubMenuClose}
            onClick={handleSubMenu}
          >
            <Link
              to="/solutions"
              className={
                activeMenu === "solutions-active"
                  ? "menu-links solutions-active"
                  : "menu-links" && transparentMenu
                  ? "menu-links light-theme"
                  : "menu-links"
              }
            >
              <span className="t" data-translate="solutions">
                Solutions
              </span>
              <img src={arrow} alt="arrow" className="arrow" />
            </Link>
            <ul className="sub-menu" onMouseLeave={handleSubMenuClose}>
              {solutionCategories.map((el) => (
                <li key={el.id}>
                  <Link
                    to={`/solutions/${el.title
                      .replaceAll(" ", "-")
                      .toLowerCase()}`}
                  >
                    <div className="sub-menu-image">
                      <img src={el.image} alt={el.title} className="svg-test" />
                    </div>
                    <span>{lang === "en" ? el.title : el.titleMK}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li
            onMouseEnter={handleSubMenu}
            onMouseLeave={handleSubMenuClose}
            onClick={handleSubMenu}
          >
            <Link
              to="/products"
              className={
                activeMenu === "products-active"
                  ? "menu-links products-active"
                  : "menu-links" && transparentMenu
                  ? "menu-links light-theme"
                  : "menu-links"
              }
            >
              <span className="t" data-translate="products">
                Products
              </span>
              <img src={arrow} alt="" className="arrow" />
            </Link>
            <ul
              className="sub-menu sub-menu-products"
              onMouseLeave={handleSubMenuClose}
            >
              <li>
                <Link to="/products/pro">
                  <img src={Group8448} alt="" className="productsProImage" />
                  IceTrackr<span className="productsProSpan">Pro</span>
                </Link>
              </li>
              <hr />
              <li>
                <Link to="/products/lite">
                  <img src={Group8464} alt="" className="productsProImage" />
                  IceTrackr<span className="productsLiteSpan">Lite</span>
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to="/about-us"
              className={
                activeMenu === "aboutus-active"
                  ? "menu-links aboutus-active"
                  : "menu-links" && transparentMenu
                  ? "menu-links light-theme"
                  : "menu-links"
              }
            >
              <span className="t" data-translate="aboutUs">
                About us
              </span>
            </Link>
          </li>
          <li>
            <Link
              to="/blog"
              className={
                activeMenu === "blog-active"
                  ? "menu-links blog-active"
                  : "menu-links" && transparentMenu
                  ? "menu-links light-theme"
                  : "menu-links"
              }
            >
              <span className="t" data-translate="blog">
                Blog
              </span>
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className={
                activeMenu === "contact-active"
                  ? "menu-links contact-active"
                  : "menu-links" && transparentMenu
                  ? "menu-links light-theme"
                  : "menu-links"
              }
            >
              <span className="t" data-translate="contact">
                Contact
              </span>
            </Link>
          </li>
        </ul>
        <div className="right-menu">
          <a
            href="http://login.icetrackr.com/customer"
            className="log-in t"
            data-translate="logIn"
          >
            Log in
          </a>
          <Link to="/contact" className="receive-quote-btn">
            <span className="t" data-translate="receiveQuoteButton">
              Recieve a quote
            </span>
          </Link>
          <Link
            to="/"
            className="language"
            onClick={(e) => handleLanguageOpen(e)}
          >
            <span className={transparentMenu && "light-theme"}>{lang}</span>
            <img src={arrow} alt="" className="arrow" />
            <ul
              className={
                openLanguage ? "language-submenu active" : "language-submenu"
              }
            >
              <li className="en" onClick={(e) => handleLanguageChange(e)}>
                en
              </li>
              <li className="mk" onClick={(e) => handleLanguageChange(e)}>
                mk
              </li>
            </ul>
          </Link>
        </div>
      </div>
      <Link
        to="/"
        className="language mobile-language"
        onClick={(e) => handleLanguageOpen(e)}
      >
        <span className={transparentMenu && "light-theme"}>{lang}</span>
        <img src={arrow} alt="" className="arrow" />
        <ul
          className={
            openLanguage ? "language-submenu active" : "language-submenu"
          }
        >
          <li className="en" onClick={(e) => handleLanguageChange(e)}>
            en
          </li>
          <li className="mk" onClick={(e) => handleLanguageChange(e)}>
            mk
          </li>
        </ul>
      </Link>
      <div className="mobile-menu">
        <div
          className={
            transparentMenu
              ? "hamburger-menu light-mobile-menu"
              : "hamburger-menu"
          }
          onClick={(e) => handleMobileMenuOpen(e)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div
          className={
            openMobileMenu ? "mobile-menu-opened open" : "mobile-menu-opened"
          }
        >
          <div
            className="mobile-menu-blankspace"
            onClick={() => handleMobileMenuClose()}
          ></div>
          <div className="mobile-menu-wrapper">
            <div>
              <div className="mobile-menu-top">
                <img src={logoMobile} alt="logo-mobile" />
                <div
                  className="mobile-menu-close"
                  onClick={() => handleMobileMenuClose()}
                >
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className="mobile-menu-contacts">
                <div className="mobile-menu-contacts-cards">
                  <span className="contact-icons">
                    <EmailIcon />
                  </span>
                  <span>contact@icelabs.mk</span>
                </div>
                <div className="mobile-menu-contacts-cards">
                  <span className="contact-icons">
                    <PhoneIcon />
                  </span>
                  <span>+389 78 33 60 32</span>
                </div>
              </div>
              <ul>
                <li className="mobile-menu-links">
                  <Link
                    to="/solutions"
                    className={
                      activeMenu === "solutions-active"
                        ? " solutions-active"
                        : "false" && transparentMenu
                        ? " light-theme"
                        : "false"
                    }
                  >
                    <span className="t" data-translate="solutions">
                      Solutions
                    </span>
                  </Link>
                </li>
                <li className="mobile-menu-links">
                  <Link
                    to="/products"
                    className={
                      activeMenu === "products-active"
                        ? " products-active"
                        : "" && transparentMenu
                        ? " light-theme"
                        : ""
                    }
                  >
                    <span className="t" data-translate="products">
                      Products
                    </span>
                  </Link>
                </li>
                <li className="mobile-menu-links">
                  <Link
                    to="/about-us"
                    className={
                      activeMenu === "aboutus-active"
                        ? " aboutus-active"
                        : "" && transparentMenu
                        ? " light-theme"
                        : ""
                    }
                  >
                    <span className="t" data-translate="aboutUs">
                      About us
                    </span>
                  </Link>
                </li>
                <li className="mobile-menu-links">
                  <Link
                    to="/"
                    className={
                      activeMenu === "blog-active"
                        ? " blog-active"
                        : "" && transparentMenu
                        ? " light-theme"
                        : ""
                    }
                  >
                    <span className="t" data-translate="blog">
                      Blog
                    </span>
                  </Link>
                </li>
                <li className="mobile-menu-links">
                  <Link
                    to="/contact"
                    className={
                      activeMenu === "contact-active"
                        ? "contact-active"
                        : "" && transparentMenu
                        ? "light-theme"
                        : ""
                    }
                  >
                    <span className="t" data-translate="contact">
                      Contact
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mobile-menu-bottom">
              <Link to="/contact" className="receive-quote-btn">
                <span className="t" data-translate="receiveQuoteButton">
                  Recieve a quote
                </span>
              </Link>
              <a
                href="http://login.icetrackr.com/customer"
                className="log-in t"
                data-translate="logIn"
              >
                Log in
              </a>
            </div>
          </div>
        </div>
      </div>
    </NavbarWrapper>
  );
};

export default MenuFixed;
