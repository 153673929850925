import React from "react";
import styled from "styled-components";
import WalterLogo from "../../img/api-integration/lkwwalter.png";
import Project44Logo from "../../img/api-integration/project44.png";
import SixfoldLogo from "../../img/api-integration/sixfold.png";
import DuvenbeckLogo from "../../img/api-integration/duvenbeck.png";

const ApiIntegrationFirstContentWrapper = styled.div`
  padding: 0px 375px 200px;
  position: relative;
  z-index: 1;
  .container {
    padding: 85px 40px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    .inner-container {
      padding: 0 160px;
      text-align: center;
      span {
        display: block;
        ${(props) => props.theme.body_20};
      }
      span:first-of-type {
        margin-bottom: 30px;
      }
    }

    .container-cards {
      display: flex;
      align-items: center;
      margin-top: 80px;
      margin-left: -30px;
      margin-right: -30px;
      .cards {
        flex-basis: 25%;
        text-align: center;
        height: 100%;
        padding: 0 30px;
        .inner-cards {
          p,
          span {
            ${(props) => props.theme.body_20}
          }
          p {
            font-weight: 400;
          }
          span {
            font-weight: 600;
          }
          img {
            display: block;
            width: 150px;
            height: 50px;
            margin: 0 auto 35px;
          }
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0 275px 150px;
  }
  @media (max-width: 1439px) {
    padding: 0 50px 100px;
  }
  @media (max-width: 1023px) {
    padding: 0 20px 50px;
    .container {
      padding: 50px 15px;
      .inner-container {
        padding: 0;
      }
      .container-cards {
        margin-left: -10px;
        margin-right: -10px;
        .cards {
          padding: 0 10px;
          .inner-cards {
            p {
              font-size: 17px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .container {
      .container-cards {
        margin-top: 40px;
        display: block;
        margin-left: 0;
        margin-right: 0;
        .cards {
          padding: 0;
          .inner-cards {
            img {
              margin: 0 auto 15px;
            }
          }
          &:not(:last-child) {
            margin-bottom: 35px;
          }
        }
      }
    }
  }
`;
const ApiIntegrationFirstContent = () => {
  return (
    <ApiIntegrationFirstContentWrapper>
      <div className="container">
        <div className="inner-container">
          <span className="t" data-translate="apiIntegrationFirstContentTitle1">
            We have developed an integration with some of the largest carrier
            and truckload marketplaces that allows you to send vehicle's
            location data directly to the external systems. That allows you to
            easily find the best routes and maximise your fleet efficiency.
          </span>
          <span className="t" data-translate="apiIntegrationFirstContentTitle2">
            These integrations will provide transparency and visibility for all
            of your assets and deliveries throughout your entire supply chain
            and help to manage your day-to-day business operations.
          </span>
        </div>
        <div className="container-cards">
          <div className="cards">
            <div className="inner-cards">
              <img src={WalterLogo} alt=""/>
              <p
                className="t"
                data-translate="apiIntegrationFirstContentCompany"
              >
                Company for transport and logistics :{" "}
              </p>
              <span>Walter GMBH</span>
            </div>
          </div>
          <div className="cards">
            <div className="inner-cards">
              <img src={DuvenbeckLogo} alt=""/>
              <p
                className="t"
                data-translate="apiIntegrationFirstContentCompany"
              >
                Company for transport and logistics :{" "}
              </p>
              <span>Duvenbeck GMBH</span>
            </div>
          </div>
          <div className="cards">
            <div className="inner-cards">
              <img src={SixfoldLogo} alt=""/>
              <p
                className="t"
                data-translate="apiIntegrationFirstContentCompany"
              >
                Company for transport and logistics :{" "}
              </p>
              <span>Sixfold</span>
            </div>
          </div>
          <div className="cards">
            <div className="inner-cards">
              <img src={Project44Logo} alt=""/>
              <p
                className="t"
                data-translate="apiIntegrationFirstContentCompany"
              >
                Company for transport and logistics :{" "}
              </p>
              <span>Project44</span>
            </div>
          </div>
        </div>
      </div>
    </ApiIntegrationFirstContentWrapper>
  );
};

export default ApiIntegrationFirstContent;
