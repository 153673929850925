import React from "react";
import styled from "styled-components";
import Group7029 from "../../img/driver-behaviour/group-7029.png";

const DriverBehaviourSecondContentWrapper = styled.div`
  padding: 0px 375px 200px;
  margin-top: -115px;
  position: relative;
  z-index: 1;
  .container {
    padding: 60px 100px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        margin-right: 30px;

        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
          ${(props) => props.theme.body_20};
        }
      }

      .right {
        img {
          width: 370px;
          display: block;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }
    }
  }

  @media (max-width: 1900px) {
    padding: 0px 275px 150px;
    .container {
      padding: 30px 25px 40px;
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }
  @media (max-width: 1439px) {
    padding: 0px 50px 100px;
  }

  @media (max-width: 1023px) {
    padding: 0px 20px 50px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            width: 250px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      .container-cards {
        display: block;
        .left {
          margin-right: 0;
          margin-bottom: 15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
      }
    }
  }
`;
const DriverBehaviourSecondContent = () => {
  return (
    <DriverBehaviourSecondContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="driverBehaviourSecondContentFirstCardTitle"
            >
              Send Data to External Systems Using API
            </h2>
            <p
              className="t"
              data-translate="driverBehaviourSecondContentFirstCardText"
            >
              IceTrackr offers a variety of integrations with external systems,
              allowing you to automatically send and receive all the relevant
              data about your fleet. For instance, you can use the Driving
              behaviour API to automatically send the drivers' ratings to your
              bookkeeping system that will use this data to calculate drivers'
              bonuses.
            </p>
          </div>
          <div className="right">
            <img src={Group7029} alt=""/>
          </div>
        </div>
      </div>
    </DriverBehaviourSecondContentWrapper>
  );
};

export default DriverBehaviourSecondContent;
