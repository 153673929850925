import FuelPump from "../../img/fuel-pump.svg";
import CostControl from "../../img/money.svg";
import DriverBehaviour from "../../img/driver-behaviour.svg";
import RoutePlanning from "../../img/route.svg";
import Tachograph from "../../img/tachograph.svg";
import DispatchComms from "../../img/dispatch.svg";
import Gps from "../../img/gps.svg";
import Sensors from "../../img/sensors.svg";
import FleetMaintenance from "../../img/fleet-maintenance.svg";
import Reports from "../../img/reports.svg";
import TemperatureControl from "../../img/temperature.svg";
import TaskManagement from "../../img/task.svg";
import FleetEfficiency from "../../img/fleet-efficiency.svg";
import ErpIntegration from "../../img/erp.svg";
import Dashboards from "../../img/dashboard.svg";
import VehicleInspection from "../../img/vehicle.svg";
import Alerts from "../../img/alerts.svg";
import ApiIntegration from "../../img/api.svg";
import HistoryEvents from "../../img/history.svg";
import TaxiCall from "../../img/taxi-call.svg";
const solutionsCategories = [
  {
    id: 1,
    title: "Fuel control",
    titleMK:'Контрола на гориво',
    image: FuelPump,
  },
  {
    id: 2,
    title: "Cost Control",
    titleMK:'Контрола на трошоците',
    image: CostControl,
  },
  {
    id: 3,
    title: "Driver behaviour",
    titleMK:'Однесување на возачот',
    image: DriverBehaviour,
  },
  {
    id: 4,
    title: "Route planning",
    titleMK:'Планирање на маршрутата',
    image: RoutePlanning,
  },
  {
    id: 5,
    title: "Digital tachograph remote download",
    titleMK:'Далечинско преземање на дигитален тахограф ',
    image: Tachograph,
  },
  {
    id: 6,
    title: "dispatch and communication",
    titleMK:'испраќање и комуникација',
    image: DispatchComms,
  },
  {
    id: 7,
    title: "GPS tracking and live data",
    titleMK:'GPS следење и податоци во живо',
    image: Gps,
  },
  {
    id: 8,
    title: "Sensors for seats and taximeter",
    titleMK:'Сензори за седишта и таксиметар',
    image: Sensors,
  },
  {
    id: 9,
    title: "Fleet maintenance",
    titleMK:'Одржување на флотата',
    image: FleetMaintenance,
  },
  {
    id: 10,
    title: "reports",
    titleMK:'Извештаи',
    image: Reports,
  },
  {
    id: 11,
    title: "Temperature control",
    titleMK:'Контрола на температурата',
    image: TemperatureControl,
  },
  {
    id: 12,
    title: "Task management",
    titleMK:'Менаџмент на задачи',
    image: TaskManagement,
  },
  {
    id: 13,
    title: "Fleet efficiency",
    titleMK:'Ефикасност на флотата',
    image: FleetEfficiency,
  },
  {
    id: 14,
    title: "ERP Integration",
    titleMK:'ERP интеграција',
    image: ErpIntegration,
  },
  {
    id: 15,
    title: "Dashboards",
    titleMK:'Контролни табли',
    image: Dashboards,
  },
  {
    id: 16,
    title: "Vehicle inspection",
    titleMK:'Преглед на возилото',
    image: VehicleInspection,
  },
  {
    id: 17,
    title: "Alerts",
    titleMK:'Известувања',
    image: Alerts,
  },
  {
    id: 18,
    title: "API integrations",
    titleMK:'API интеграции',
    image: ApiIntegration,
  },
  {
    id: 19,
    title: "History of events",
    titleMK:'Историја на настани',
    image: HistoryEvents,
  },
  {
    id: 20,
    title: "Taxi call dispatch",
    titleMK:'Повик до такси',
    image: TaxiCall,
  },
];
export default solutionsCategories;
