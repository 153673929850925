import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TinexLogo from "../../img/home-page/tinexlogo.png";
import Alov from "../../img/home-page/alov.png";
import PssLogo from "../../img/home-page/psslogo.png";
import KitgoLogo from "../../img/home-page/kitgologo.png";
import SlavejLogo from "../../img/home-page/slavejlogo.png";

const PartnersSliderWrapper = styled.div`
  background-color: #f2f7f9;
  border-top: 1px solid #dce3eb;
  padding: 65px 290px;
  p {
    ${(props) => props.theme.body_24};
    text-align: center;
    margin-bottom: 30px;
  }
  .slick-slide {
    > div {
      height: 90px;
    }
  }
  .partners-image {
    height: 100%;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1900px) {
    padding: 65px 80px;
  }
  @media (max-width: 1023px) {
    padding: 65px 40px;
  }
`;
const PartnersSlider = () => {
  const settingsPartners = {
    infinite: true,
    slidesToShow: 5,
    speed: 500,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "20px",
          slidesToShow: 3,
          speed: 500,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "20px",
          slidesToShow: 2,
          slidesToScroll: 2,
          speed: 500,
        },
      },
      {
        breakpoint: 768,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "20px",
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
        },
      },
    ],
    prevArrow: <ArrowBackIcon />,
    nextArrow: <ArrowForwardIcon />,
  };
  return (
    <PartnersSliderWrapper>
      <p className="t" data-translate="homepagePartnersTitle">
        Trusted by:
      </p>
      <Slider {...settingsPartners}>
        <div className="partners-image">
          <img alt="" src={KitgoLogo} />
        </div>
        <div className="partners-image">
          <img alt="" src={PssLogo} />
        </div>
        <div className="partners-image">
          <img alt="" src={Alov} />
        </div>
        <div className="partners-image">
          <img alt="" src={TinexLogo} />
        </div>
        <div className="partners-image">
          <img alt="" src={SlavejLogo} />
        </div>
        <div className="partners-image">
          <img alt="" src={KitgoLogo} />
        </div>
        <div className="partners-image">
          <img alt="" src={PssLogo} />
        </div>
        <div className="partners-image">
          <img alt="" src={Alov} />
        </div>
        <div className="partners-image">
          <img alt="" src={TinexLogo} />
        </div>
        <div className="partners-image">
          <img alt="" src={SlavejLogo} />
        </div>
      </Slider>
    </PartnersSliderWrapper>
  );
};

export default PartnersSlider;
