import React from "react";
import CookiesSettings from "../CookiesSettings/CookiesSettings";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import VehicleInspectionFirstContent from "./VehicleInspectionFirstContent";
import VehicleInspectionIntro from "./VehicleInspectionIntro";

const VehicleInspectionPage = () => {
  return (
    <>
      <MenuFixed />
      <VehicleInspectionIntro />
      <VehicleInspectionFirstContent />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default VehicleInspectionPage;
