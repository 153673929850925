import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import solutionsCategories from "../Menu/SolutionsCategories";
import $ from "jquery";

const SolutionsIntroWrapper = styled.div`
  padding: 400px 0 300px;
  background-color: #f2f7f9;
  position: relative;
  overflow: hidden;
  .intro-text {
    text-align: center;
    width: 30%;
    margin: 0 auto;
    h1 {
      ${(props) => props.theme.h1_64};
    }
    > p {
      ${(props) => props.theme.body_24}
    }
    p:first-of-type {
      margin-bottom: 20px;
    }
  }

  .left-container {
    position: absolute;
    left: 0;
    top: -350px;
    bottom: 0;
    display: flex;
    .left {
      flex-basis: 50%;
    }
  }

  .right-container {
    position: absolute;
    right: 0;
    top: -350px;
    bottom: 0;
    display: flex;
  }
  .cards-slider {
    margin: 10px 15px 20px;
    display: block;
    .inner-card {
      box-shadow: 0px 4px 10px #1a284d14;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 220px;
      height: 220px;
      border-radius: 10px;
      img {
        display: block;
        width: 100px;
        height: 90px;
      }
    }
  }

  .infiniteslide_wrap {
    // display:flex;
  }

  @media (max-width: 1900px) {
    padding: 300px 0;
    .intro-text {
      width: 55%;
    }
    .left-container {
      .right-solutions-slider {
        display: none !important;
      }
    }
    .right-container {
      .left-solutions-slider-2 {
        display: none !important;
      }
    }
  }
  @media (max-width: 1439px) {
    .intro-text {
      h1 {
        font-size: 54px;
        line-height: 64px;
      }
      > p {
        font-size: 22px;
        line-height: 30px;
      }
    }
    .cards-slider {
      .inner-card {
        width: 170px;
        height: 170px;
        img {
          width: 95px;
          height: 95px;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    padding: 150px 0;
    .intro-text {
      width: 65%;
      h1 {
        font-size: 45px;
        line-height: 64px;
      }
      > p {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .cards-slider {
      .inner-card {
        width: 100px;
        height: 100px;
        img {
          width: 60px;
          height: 50px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .intro-text {
      width: 95%;
      h1 {
        font-size: 38px;
        line-height: 53px;
      }
      > p {
        font-size: 18px;
        line-height: 25px;
      }
    }
    .left-container {
      .left-solutions-slider {
        display: none !important;
      }
    }
    .right-container {
      .right-solutions-slider-2 {
        display: none !important;
      }
    }
  }
`;
const SolutionsIntro = () => {
  $.fn.infiniteslide = function (options) {
    let i = 0;
    //option
    var settings = $.extend(
      {
        speed: 100,
        direction: "left",
        pauseonhover: true,
        responsive: false,
        clone: 1,
      },
      options
    );

    var setCss = function (obj, direction) {
      $(obj).wrap('<div class="infiniteslide_wrap"></div>').parent().css({
        overflow: "hidden",
      });

      if (direction === "up" || direction === "down") {
        var d = "column";
      } else {
        var d = "row";
      }

      $(obj)
        .css({
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
          "-ms-flex-align": "center",
          flexDirection: d,
        })
        .children()
        .css({
          flex: "none",
          display: "block",
        });
    };

    var setClone = function (obj, clone) {
      var $clone = $(obj)
        .children()
        .clone(true)
        .addClass("infiniteslide_clone");
      i = 1;
      while (i <= clone) {
        $clone.clone(true).appendTo($(obj));
        i++;
      }
    };

    var getWidth = function (obj) {
      var w = 0;
      $(obj)
        .children(":not(.infiniteslide_clone)")
        .each(function (key, value) {
          w = w + $(this).outerWidth(true);
        });
      return w;
    };
    var getHeight = function (obj) {
      var h = 0;
      $(obj)
        .children(":not(.infiniteslide_clone)")
        .each(function (key, value) {
          h = h + $(this).outerHeight(true);
        });
      return h;
    };

    var getSpeed = function (l, s) {
      return l / s;
    };
    var getNum = function (obj, direction) {
      if (direction === "up" || direction === "down") {
        var num = getHeight(obj);
      } else {
        var num = getWidth(obj);
      }
      return num;
    };

    var getTranslate = function (num, direction) {
      if (direction === "up" || direction === "down") {
        var i = "0,-" + num + "px,0";
      } else {
        var i = "-" + num + "px,0,0";
      }
      return i;
    };

    var setAnim = function (obj, id, direction, speed) {
      var num = getNum(obj, direction);
      if (direction === "up" || direction === "down") {
        $(obj)
          .parent(".infiniteslide_wrap")
          .css({
            height: num + "px",
          });
      }
      var i = getTranslate(num, direction);

      $(obj).attr("data-style", "infiniteslide" + id);

      var css =
        "@keyframes infiniteslide" +
        id +
        "{" +
        "from {transform:translate3d(0,0,0);}" +
        "to {transform:translate3d(" +
        i +
        ");}" +
        "}";
      $("<style />")
        .attr("id", "infiniteslide" + id + "_style")
        .html(css)
        .appendTo("head");

      if (direction === "right" || direction === "down") {
        var reverse = " reverse";
      } else {
        var reverse = "";
      }

      $(obj).css({
        animation:
          "infiniteslide" +
          id +
          " " +
          getSpeed(num, speed) +
          "s linear 0s infinite" +
          reverse,
      });
    };
    var setStop = function (obj) {
      $(obj)
        .on("mouseenter", function () {
          $(this).css({
            animationPlayState: "paused",
          });
        })
        .on("mouseleave", function () {
          $(this).css({
            animationPlayState: "running",
          });
        });
    };

    var setResponsive = function (obj, direction) {
      var num = getNum(obj, direction);
      var i = getTranslate(num, direction);
      return i;
    };

    return this.each(function (key, value) {
      var $this = $(this);
      var num = Date.now() + Math.floor(10000 * Math.random()).toString(16);
      if (settings.pauseonhover === true) {
        setStop($this);
      }
      var _onload = function () {
        setCss($this, settings.direction);
        setClone($this, settings.clone);
        setAnim($this, num, settings.direction, settings.speed);

        if (settings.responsive) {
          $(window).on("resize", function () {
            var i = setResponsive($this, settings.direction);
            var styleid = $this.attr("data-style");
            var stylehtml = $("#" + styleid + "_style").html();

            var stylehtml_new = stylehtml.replace(
              /to {transform:translate3d\((.*?)\)/,
              "to {transform:translate3d(" + i + ")"
            );
            $("#" + styleid + "_style").html(stylehtml_new);
          });
        }
      };

      if (window.loaded) {
        _onload();
      } else {
        $(window).on("load", _onload);
      }
    });
  };
  useEffect(() => {
    window.loaded = true;
    $(".left-solutions-slider").infiniteslide({ direction: "up" });
    $(".right-solutions-slider").infiniteslide({ direction: "up", speed: 200 });
    $(".left-solutions-slider-2").infiniteslide({
      direction: "up",
      speed: 200,
    });
    $(".right-solutions-slider-2").infiniteslide({
      direction: "up",
    });
  });
  return (
    <SolutionsIntroWrapper>
      <div className="intro-text">
        <h1 className="t" data-translate="solutionsIntroTitle">
          Our Solutions
        </h1>
        <p className="t" data-translate="solutionsIntroText1">
          IceTrackr offers a wide range of solutions that will fit your needs
          perfectly, whether you a have big fleet of different types of vehicles
          and assets, or just a few cars.
        </p>
        <p className="t" data-translate="solutionsIntroText2">
          Let us help you find solutions that fit your business needs the most.
          Our Solution Advisor will calculate what's best for you in just a few
          clicks.
        </p>
      </div>
      <div className="left-container">
        <div className="left-solutions-slider">
          {solutionsCategories.map((el) => (
            <Link
              to={`/solutions/${el.title.replaceAll(" ", "-").toLowerCase()}`}
              className="cards-slider"
              key={el.id}
            >
              <div className="inner-card">
                <img src={el.image} alt={el.title} />
              </div>
            </Link>
          ))}
        </div>
        <div className="right-solutions-slider">
          {solutionsCategories.map((el) => (
            <Link
              to={`/solutions/${el.title.replaceAll(" ", "-").toLowerCase()}`}
              className="cards-slider"
              key={el.id}
            >
              <div className="inner-card">
                <img src={el.image} alt={el.title} />
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className="right-container">
        <div className="left-solutions-slider-2">
          {solutionsCategories.map((el) => (
            <Link
              to={`/solutions/${el.title.replaceAll(" ", "-").toLowerCase()}`}
              className="cards-slider"
              key={el.id}
            >
              <div className="inner-card">
                <img src={el.image} alt={el.title} />
              </div>
            </Link>
          ))}
        </div>
        <div className="right-solutions-slider-2">
          {solutionsCategories.map((el) => (
            <Link
              to={`/solutions/${el.title.replaceAll(" ", "-").toLowerCase()}`}
              className="cards-slider"
              key={el.id}
            >
              <div className="inner-card">
                <img src={el.image} alt={el.title} />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </SolutionsIntroWrapper>
  );
};

export default SolutionsIntro;
