import React from "react";
import CookiesSettings from "../CookiesSettings/CookiesSettings";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import SmallSlider from "../SmallSlider/SmallSlider";
import GpsFirstContent from "./GpsFirstContent";
import GpsIntro from "./GpsIntro";
import GpsSecondContent from "./GpsSecondContent";

const GpsPage = () => {
  return (
    <>
      <MenuFixed />
      <GpsIntro />
      <GpsFirstContent />
      <SmallSlider />
      <GpsSecondContent />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default GpsPage;
