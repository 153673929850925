import React from "react";
import styled from "styled-components";
import Group6492 from "../../img/fleet-maintenance/group-6492.svg";
import Group6493 from "../../img/fleet-maintenance/group-6493.png";
import Group6507 from "../../img/fleet-maintenance/group-6507.svg";
import Group6611 from "../../img/fleet-maintenance/group-6611.svg";

const FleetMaintenanceFirstContentWrapper = styled.div`
  padding: 0px 375px;
  margin-bottom: -115px;
  position: relative;
  z-index: 1;
  .container {
    padding: 100px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        margin-right: 30px;
        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
          ${(props) => props.theme.body_20};
        }
      }

      .right {
        img {
          width: 370px;
          display: block;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }
      &:last-child {
        img {
          box-shadow: none;
        }
      }
    }
  }

  @media (max-width: 1900px) {
    padding: 0px 275px;
    .container {
      padding: 30px 25px 40px;
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 0px 50px;
  }
  @media (max-width: 1023px) {
    padding: 0px 20px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            width: 250px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      .container-cards {
        display: block;
        .left {
          margin-right: 0;
          margin-bottom: 15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
`;
const FleetMaintenanceFirstContent = () => {
  return (
    <FleetMaintenanceFirstContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="fleetMaintenanceFirstContentFirstCardTitle"
            >
              All Data in One Dashboard
            </h2>
            <p
              className="t"
              data-translate="fleetMaintenanceFirstContentFirstCardText"
            >
              Have quick access to all information about your fleet - upcoming
              service dates, repair costs, expiring documents, mileage and more.
              Use historic data and compare your fleet vehicles to make
              fact-based business decisions.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group6492} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="fleetMaintenanceFirstContentSecondCardTitle"
            >
              Calculate Your Fleet Efficiency
            </h2>
            <p
              className="t"
              data-translate="fleetMaintenanceFirstContentSecondCardText"
            >
              Track your fleet efficiency, using an automated, transparent
              solution. It will help you to understand whether you have enough,
              too many or too little vehicles, regardless of your fleet
              specifics.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group6493} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="fleetMaintenanceFirstContentThirdCardTitle"
            >
              Reminders About Important Maintenance Tasks
            </h2>
            <p
              className="t"
              data-translate="fleetMaintenanceFirstContentThirdCardText"
            >
              Set reminders based on one or more of your chosen triggers -
              distance driven, motor hours, or days since the last service
              check/repairment. That will help you to boost the fleet
              maintenance efficiency and save the time spent on inspecting all
              vehicles by yourself.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group6507} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="fleetMaintenanceFirstContentFourthCardTitle"
            >
              Keep Track of All Repair Costs
            </h2>
            <p
              className="t"
              data-translate="fleetMaintenanceFirstContentFourthCardText"
            >
              Manage all repair costs in a single dashboard - information about
              repair events, additional notes, invoices, etc. Having everything
              you need for your fleet's cost management will allow you to
              calculate its profitability and make better investment decisions.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group6611} />
          </div>
        </div>
      </div>
    </FleetMaintenanceFirstContentWrapper>
  );
};

export default FleetMaintenanceFirstContent;
