import React from "react";
import styled from "styled-components";
import Group7677 from "../../img/route-planning/group-7677.svg";
import Group7678 from "../../img/route-planning/group-7678.svg";
import Group7679 from "../../img/route-planning/group-7679.svg";
import Group7680 from "../../img/route-planning/group-7680.svg";

const RoutePlanningSecondContentWrapper = styled.div`
  padding: 0px 375px 200px;
  position: relative;
  z-index: 1;
  .container {
    margin-top: -65px;
    .container-cards {
      display: flex;
      margin-left: -15px;
      margin-right: -15px;
      .cards {
        flex-basis: 25%;
        min-height: 270px;
        padding: 0 15px;
        .inner-cards {
          background-color: white;
          box-shadow: 0px 4px 20px #1a284d14;
          border-radius: 10px;
          padding: 20px;
          height: 100%;
          text-align: center;
          p {
            ${(props) => props.theme.body_20}
            font-weight: 400;
            color: #6b7ba4;
          }
          span {
            ${(props) => props.theme.body_24}
            font-weight: 600;
          }
          .img-wrapper {
            width: 100%;
            height: 130px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            img {
              display: block;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0px 275px 150px;
    .container {
      .container-cards {
        .cards {
          .inner-cards {
            p {
              font-size: 15px;
              line-height: 21px;
            }
            span {
              font-size: 18px;
              line-height: 25px;
            }
            .img-wrapper {
              img {
                width: 155px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1439px) {
    padding: 0px 50px 100px;
    .container {
      .container-cards {
        .cards {
          .inner-cards {
            .img-wrapper {
              img {
                width: 135px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1023px) {
    padding: 0px 20px 50px;
    .container {
      .container-cards {
        flex-wrap: wrap;
        .cards {
          flex-basis: 50%;
          padding:15px;
          .inner-cards {
            .img-wrapper {
              img {
                width: 155px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .container {
      .container-cards {
        margin-right:0;
        margin-left:0;
        display:block;
        .cards {
          flex-basis: 50%;
          padding:15px 0;
        }
      }
    }
  }
`;
const RoutePlanningSecondContent = () => {
  return (
    <RoutePlanningSecondContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="cards">
            <div className="inner-cards">
              <div className="img-wrapper">
                <img alt="" src={Group7679} />
              </div>
              <span
                className="t"
                data-translate="routePlanningSecondContentFirstCardTitle"
              >
                Detailed optimization
              </span>
              <p
                className="t"
                data-translate="routePlanningSecondContentFirstCardText"
              >
                An algorithm that plans routes based on the vehicle and cargo
                size, weight, load capacity, etc.
              </p>
            </div>
          </div>
          <div className="cards">
            <div className="inner-cards">
              <div className="img-wrapper">
                <img alt="" src={Group7678} />
              </div>
              <span
                className="t"
                data-translate="routePlanningSecondContentSecondCardTitle"
              >
                Reliable navigation
              </span>
              <p
                className="t"
                data-translate="routePlanningSecondContentSecondCardText"
              >
                Real-time traffic updates, delivery estimates and other valuable
                information.
              </p>
            </div>
          </div>
          <div className="cards">
            <div className="inner-cards">
              <div className="img-wrapper">
                <img alt="" src={Group7677} />
              </div>
              <span
                className="t"
                data-translate="routePlanningSecondContentThirdCardTitle"
              >
                Personalised settings
              </span>
              <p
                className="t"
                data-translate="routePlanningSecondContentThirdCardText"
              >
                Calculations that avoid toll roads, unpaved roads, ferries and
                highways.
              </p>
            </div>
          </div>
          <div className="cards">
            <div className="inner-cards">
              <div className="img-wrapper">
                <img alt="" src={Group7680} />
              </div>
              <span
                className="t"
                data-translate="routePlanningSecondContentFourthCardTitle"
              >
                Sequencing
              </span>
              <p
                className="t"
                data-translate="routePlanningSecondContentFourthCardText"
              >
                Optimizing multiple delivery points and vehicles based on load
                capacity, priority, costs and delivery terms.
              </p>
            </div>
          </div>
        </div>
      </div>
    </RoutePlanningSecondContentWrapper>
  );
};

export default RoutePlanningSecondContent;
