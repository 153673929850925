import React from "react";
import CookiesSettings from "../CookiesSettings/CookiesSettings";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import FleetEfficiencyFirstContent from "./FleetEfficiencyFirstContent";
import FleetEfficiencyIntro from "./FleetEfficiencyIntro";
import FleetEfficiencySecondContent from "./FleetEfficiencySecondContent";

const FleetEfficiencyPage = () => {
  return (
    <>
      <MenuFixed />
      <FleetEfficiencyIntro />
      <FleetEfficiencyFirstContent />
      <FleetEfficiencySecondContent />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default FleetEfficiencyPage;
