import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../Consts/GlobalContext";

const SolutionsContentCards = ({ title, desc, image, descMK, titleMK }) => {
  const { lang } = useContext(GlobalContext);
  return (
    <>
      <div className="cards">
        <div className="left">
          <h2>{lang === "en" ? title : titleMK}</h2>
          <p>{lang === "en" ? desc : descMK}</p>
          <Link
            to={`/solutions/${title.replaceAll(" ", "-").toLowerCase()}`}
            className="receive-quote-btn"
          >
            <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
          </Link>
        </div>
        <div className="right">
          <img src={image} alt=""/>
        </div>
      </div>
    </>
  );
};

export default SolutionsContentCards;
