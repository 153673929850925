import React, { createContext, useState } from "react";
import customLang from "../Components/LanguageSupport/Language";
import $ from "jquery";
import { useEffect } from "react";
const Context = createContext();

const Provider = ({ children }) => {
  const [unsubscribe, setUnsubscribe] = useState(false);
  const bearerToken =
    "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ4dmRTZFByaGxOamN6WElzcktQbDZEMUFGUUlQdGg5VnpIeFh6cG1HNGtJIn0.eyJleHAiOjE2NDM0NjQ0MDksImlhdCI6MTY0MDc4NjAwOSwianRpIjoiMWUxMGE3ZDItOTMwOS00MzQwLWJkODItNzY2YmFhODY1YzVkIiwiaXNzIjoiaHR0cHM6Ly9hcGktZGV2LWVuZy5zY291bnQuYXBwL2F1dGgvcmVhbG1zL3Njb3VudC1kZXYtZW5nIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6ImQxOTEyYzc5LTRmZDYtNDkwZS1hMzgwLTg1OWY3NTY2MjgwNiIsInR5cCI6IkJlYXJlciIsImF6cCI6InNjb3VudC1jbG91ZCIsInNlc3Npb25fc3RhdGUiOiI0MjVhZTk5ZC05ZTVhLTQ0ZDItOTk0NS1hODllZDEwN2QwYjUiLCJhY3IiOiIxIiwiYWxsb3dlZC1vcmlnaW5zIjpbIioiXSwicmVzb3VyY2VfYWNjZXNzIjp7InNjb3VudC1jbG91ZCI6eyJyb2xlcyI6WyJhZG1pbiJdfSwiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsInZpZXctYXBwbGljYXRpb25zIiwidmlldy1jb25zZW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJtYW5hZ2UtY29uc2VudCIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoiZW1haWwgcHJvZmlsZSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwic2NvdW50LW9yaWdpbiI6ImNsb3VkIiwibmFtZSI6IkJsYWdvamEgQ2hhdmtvc2tpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiYmF6ZSIsImdpdmVuX25hbWUiOiJCbGFnb2phIiwiZmFtaWx5X25hbWUiOiJDaGF2a29za2kiLCJlbWFpbCI6ImJhemVAaWNlbGFicy5tayJ9.iHcM_MyVDR2CynFIlyk3lP2c2qUgralNrNV3t-bbg4iM6UNtqZ-jlM304-y8xLfbv4hiXp8eel1h1JgPtxyydB_Nba1hU-YO0wWEfGCN2cbxJ2nh_cGXpsaEfOGmXMSieVNI51TlmE1hwIxB5TpEqpxiP7vCwqaI-QbJYRzW61zx1RB7obS51u-xnopKP_YRdg2vTLbtkS2Xx-7k496o5qp1Dd_qoVccK0-7WuX9qzjwMKBT1izu_m0ktSw78e0NyMQOcK5BGnIGIX88PVP1-ZDYUu4QoRX2qBV9kSiyO8_KI4IC3WNyS9qvlM5j41y03gRtMGf-O_qpF_46wtiK0g";

  const [lang, setLang] = useState("en");
  function translateAll() {
    $(".t").each(function (idx, element) {
      const key = $(this).attr("data-translate");
      const hasPlaceholder = $(this).attr("placeholder");

      if (hasPlaceholder) {
        $(this).attr("placeholder", customLang[lang][key]);
      } else {
        $(this).text(customLang[lang][key]);
      }
    });
  }
  const [openCookies, setOpenCookies] = useState(false);

  const [cookies, setCookies] = useState({
    necessary: true,
    analytical: false,
    marketing: false,
  });

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  useEffect(() => {
    translateAll();
  }, [lang]);
  const value = {
    unsubscribe,
    setUnsubscribe,
    bearerToken,
    translateAll,
    lang,
    setLang,
    cookies,
    setCookies,
    openCookies,
    setOpenCookies,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const GlobalContext = Context;
export const GlobalProvider = Provider;
