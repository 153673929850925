import "./App.css";
import ReactGA from 'react-ga';
import RouteChangeTracker from './Components/Analytics/RouteChangeTracker'
import theme from "./Consts/Theme";
import GlobalStyle from "./Consts/GlobalStyle";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import HomePage from "./Components/HomePage/HomePage";
import SolutionsPage from "./Components/SolutionsPage/SolutionsPage";
import FuelControlPage from "./Components/FuelControl/FuelControlPage";
import TachographPage from "./Components/Tachograph/TachographPage";
import FleetMaintenancePage from "./Components/FleetMaintenance/FleetMaintenancePage";
import FleetEfficiencyPage from "./Components/FleetEfficiency/FleetEfficiencyPage";
import DispatchCommunicationPage from "./Components/DispatchCommunication/DispatchCommunicationPage";
import DriverBehaviourPage from "./Components/DriverBehaviour/DriverBehaviourPage";
import GpsPage from "./Components/GPS/GpsPage";
import TemperatureControlPage from "./Components/TemperatureControl/TemperatureControlPage";
import TaskManagementPage from "./Components/TaskManagement/TaskManagementPage";
import VehicleInspectionPage from "./Components/VehicleInspection/VehicleInspectionPage";
import AlertsPage from "./Components/Alerts/AlertsPage";
import CostControlPage from "./Components/CostControl/CostControlPage";
import ReportsPage from "./Components/Reports/ReportsPage";
import ErpIntegrationPage from "./Components/ErpIntegration/ErpIntegrationPage";
import ApiIntegrationPage from "./Components/ApiIntegration/ApiIntegrationPage";
import HistoryEventsPage from "./Components/HistoryEvents/HistoryEventsPage";
import SensorsPage from "./Components/Sensors/SensorsPage";
import TaxiCallPage from "./Components/TaxiCall/TaxiCallPage";
import RoutePlanningPage from "./Components/RoutePlanning/RoutePlanningPage";
import DashboardPage from "./Components/Dashboard/DashboardPage";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import AboutUsPage from "./Components/AboutUs/AboutUsPage";
import ContactPage from "./Components/Contact/ContactPage";
import Subscribe from "./Components/Subscribe/Subscribe";
import Unsubscribe from "./Components/Unsubscribe/Unsubscribe";
import { GlobalProvider } from "./Consts/GlobalContext";
import ProductsPage from "./Components/Products/ProductsPage";
import ProductsProPage from "./Components/ProductsPro/ProductsProPage";
import ProductsLitePage from "./Components/ProductsLite/ProductsLitePage";
import PrivacyPolicyPage from "./Components/PrivacyPolicy/PrivacyPolicyPage";
import CookiePolicyPage from "./Components/CookiePolicy/CookiePolicyPage";
import WebsiteTermsPage from "./Components/WebsiteTerms/WebsiteTermsPage";

const TRACKING_ID = "UA-221719195-1"; // GOOGLE ANALYTICS UA ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <>
      <GlobalProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Router>
            <Switch>
              <ScrollToTop>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/solutions" component={SolutionsPage} />
                <Route
                  exact
                  path="/solutions/fuel-control"
                  component={FuelControlPage}
                />
                <Route
                  exact
                  path="/solutions/digital-tachograph-remote-download"
                  component={TachographPage}
                />
                <Route
                  exact
                  path="/solutions/fleet-maintenance"
                  component={FleetMaintenancePage}
                />
                <Route
                  exact
                  path="/solutions/fleet-efficiency"
                  component={FleetEfficiencyPage}
                />
                <Route
                  exact
                  path="/solutions/dispatch-and-communication"
                  component={DispatchCommunicationPage}
                />
                <Route
                  exact
                  path="/solutions/driver-behaviour"
                  component={DriverBehaviourPage}
                />

                <Route
                  exact
                  path="/solutions/gps-tracking-and-live-data"
                  component={GpsPage}
                />
                <Route
                  exact
                  path="/solutions/temperature-control"
                  component={TemperatureControlPage}
                />
                <Route
                  exact
                  path="/solutions/task-management"
                  component={TaskManagementPage}
                />
                <Route
                  exact
                  path="/solutions/vehicle-inspection"
                  component={VehicleInspectionPage}
                />
                <Route exact path="/solutions/alerts" component={AlertsPage} />
                <Route
                  exact
                  path="/solutions/cost-control"
                  component={CostControlPage}
                />
                <Route
                  exact
                  path="/solutions/reports"
                  component={ReportsPage}
                />
                <Route
                  exact
                  path="/solutions/erp-integration"
                  component={ErpIntegrationPage}
                />
                <Route
                  exact
                  path="/solutions/api-integrations"
                  component={ApiIntegrationPage}
                />
                <Route
                  exact
                  path="/solutions/history-of-events"
                  component={HistoryEventsPage}
                />
                <Route
                  exact
                  path="/solutions/sensors-for-seats-and-taximeter"
                  component={SensorsPage}
                />
                <Route
                  exact
                  path="/solutions/taxi-call-dispatch"
                  component={TaxiCallPage}
                />
                <Route
                  exact
                  path="/solutions/route-planning"
                  component={RoutePlanningPage}
                />
                <Route
                  exact
                  path="/solutions/dashboards"
                  component={DashboardPage}
                />
                <Route exact path="/about-us" component={AboutUsPage} />
                <Route exact path="/contact" component={ContactPage} />
                <Route exact path="/subscribetemp" component={Subscribe} />
                <Route path="/unsubscribe" component={Unsubscribe} />

                <Route exact path="/products" component={ProductsPage} />
                <Route exact path="/products/pro" component={ProductsProPage} />
                <Route
                  exact
                  path="/products/lite"
                  component={ProductsLitePage}
                />
                <Route
                  exact
                  path="/privacy-policy"
                  component={PrivacyPolicyPage}
                />
                <Route
                  exact
                  path="/cookie-policy"
                  component={CookiePolicyPage}
                />
                <Route
                  exact
                  path="/website-terms"
                  component={WebsiteTermsPage}
                />
              </ScrollToTop>
            </Switch>
            <div>
                <RouteChangeTracker />
            </div>
          </Router>
        </ThemeProvider>
      </GlobalProvider>

    </>
  );

}

export default App;
