import React from "react";
import styled from "styled-components";
import Group6449 from "../../img/tachograph/group-6449.png";
import Group7037 from "../../img/task-management/group-7037.png";
import ListIcon from "../../img/list-icon.svg";

const TaskManagementFirstContentWrapper = styled.div`
  padding: 0px 375px;
  margin-bottom: -115px;
  position: relative;
  z-index: 1;
  .container {
    padding: 100px 100px 20px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        flex-basis: 75%;
        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        ul {
          padding-left: 24px;
          list-style-image: url(${ListIcon});
          li {
            ${(props) => props.theme.body_20};
            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }
      }

      .right {
        flex-basis: 25%;
        img {
          width: 370px;
          display: block;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }
      &:last-child {
        img {
          box-shadow: none;
        }
      }
    }
  }

  @media (max-width: 1900px) {
    padding: 0px 275px;
    .container {
      padding: 30px 25px 40px;
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 0px 50px;
  }
  @media (max-width: 1023px) {
    padding: 0px 20px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
          ul {
            li {
              font-size: 15px;
              line-height: 21px;
            }
          }
        }
        .right {
          img {
            width: 250px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      .container-cards {
        display: block;
        .left {
          margin-right: 0;
          margin-bottom: 15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
`;
const TaskManagementFirstContent = () => {
  return (
    <TaskManagementFirstContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="taskManagementFirstContentFirstCardTitle"
            >
              Managers’ Perspective
            </h2>
            <ul>
              <li
                className="t"
                data-translate="taskManagementFirstContentFirstCardText1"
              >
                Create and assign tasks to drivers and field workers with ease
              </li>
              <li
                className="t"
                data-translate="taskManagementFirstContentFirstCardText2"
              >
                Update schedules and routes while drivers are on-the-go
              </li>
              <li
                className="t"
                data-translate="taskManagementFirstContentFirstCardText3"
              >
                Receive status updates in real-time
              </li>
              <li
                className="t"
                data-translate="taskManagementFirstContentFirstCardText4"
              >
                Get additional information requests from drivers instantly
              </li>
            </ul>
          </div>
          <div className="right">
            <img alt="" src={Group6449} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="taskManagementFirstContentSecondCardTitle"
            >
              Driver's Perspective
            </h2>
            <ul>
              <li
                className="t"
                data-translate="taskManagementFirstContentSecondCardText1"
              >
                Receive clear and detailed information about the tasks
              </li>
              <li
                className="t"
                data-translate="taskManagementFirstContentSecondCardText2"
              >
                Focus on completing the schedule leaving the planning for
                managers
              </li>
              <li
                className="t"
                data-translate="taskManagementFirstContentSecondCardText3"
              >
                Use your favourite navigation app for route navigation
              </li>
            </ul>
          </div>
          <div className="right">
            <img alt="" src={Group7037} />
          </div>
        </div>
      </div>
    </TaskManagementFirstContentWrapper>
  );
};

export default TaskManagementFirstContent;
