import React from "react";
import CookiesSettings from "../CookiesSettings/CookiesSettings";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import SmallSlider from "../SmallSlider/SmallSlider";
import FuelControlFirstContent from "./FuelControlFirstContent";
import FuelControlIntro from "./FuelControlIntro";
import FuelControlSecondContent from "./FuelControlSecondContent";

const FuelControlPage = () => {
  return (
    <>
      <MenuFixed />
      <FuelControlIntro />
      <FuelControlFirstContent />
      <SmallSlider />
      <FuelControlSecondContent />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default FuelControlPage;
