import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import Ellipse2 from "../../img/home-page/ellipse-520.svg";
import Intersection from "../../img/home-page/intersection.svg";
import Quatation from "../../img/home-page/quatation.svg";
import Avatar from "../../img/home-page/avatar.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const TestimonialsWrapper = styled.div`
.testimonials {
    padding: 300px 290px 180px;
    background-color: #f2f7f9;
    margin-top: -100px;
    position:relative;
    h2 {
      ${(props) => props.theme.h2_48};
      margin-bottom: 30px;
      color: #202945;
      text-align: center;
    }
    .testimonials-cards {
      padding: 85px;
      background-color: white;
      border-radius: 25px;
      box-shadow: 7px 7px 35px #6b7ba424;
      transition: all 0.6s ease-in;
      > p {
        ${(props) => props.theme.body_20};
        margin: 30px 0;
      }
      .testimonials-cards-bottom {
        display: flex;
        align-items: center;
        div {
            margin-left:15px;
            > p:first-of-type {
                font: normal normal bold 22px/29px TT Commons;
                color: #202945;
            }
            > p:last-of-type {
                normal normal normal 17px/23px TT Commons;
                color: #6B7BA4;
            }
        }
      }
      
    }
    .slick-slide.slick-active.testimonials-cards,
  .slick-slide .testimonials-cards {
    opacity: 0.3;
    transform: scale(0.9) translate3d(-345px, 0, 0);
  }
  .slick-slide.slick-center .testimonials-cards {
    opacity: 1;
    transform: scale(1) translate3d(-310px, 0, 0);
  }

  .ellipse-2{
    position:absolute;
    left:-270px;
    top:-175px;
  }
  .intersection{
    position:absolute;
    right:0;
    top:-556.5px;
    }
  }

  @media (max-width: 1900px){
    .testimonials{
      padding: 300px 60px 180px;
    
    }
  }
  @media (max-width:1439px){
    .testimonials{
      h2{
        font-size: 38px;
        line-height: 53px;
      }
      .testimonials-cards {
        box-shadow: none;
      }
  
        .slick-slide.slick-active.testimonials-cards,
        .slick-slide .testimonials-cards {
          opacity: 0.3;
          transform: scale(0.9) translate3d(0, 0, 0);
        }
        .slick-slide.slick-center .testimonials-cards {
          opacity: 1;
          transform: scale(1) translate3d(0, 0, 0);
        }
        
    }
  }
  @media (max-width:1023px){
    .testimonials{
      h2{
        font-size: 28px;
        line-height: 39px;
      }
      .testimonials-cards {
        padding:50px;
      }
      .intersection,
      .ellipse-2{
        display:none;
      }
      
    }
  }
  @media (max-width:767px){
    .testimonials{
      padding: 200px 10px 90px;

      .testimonials-cards {
        padding:15px;
        >img{
          width:30px;
        }
        >p{
          font-size: 14px;
          line-height: 20px;
          margin: 8px 0;
        }
        .testimonials-cards-bottom{
          >img{
            width:30px;
          }
          div{
            >p:first-of-type {
              font-size: 16px;
              line-height: 22px;
            }
            >p:last-of-type {
              font-size: 13px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
`;
const Testimonials = () => {
  const settingsTestimonials = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 2,
    speed: 500,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "20px",
          slidesToShow: 1,
          speed: 500,
        },
      },
    ],
    prevArrow: <ArrowBackIcon />,
    nextArrow: <ArrowForwardIcon />,
  };
  return (
    <TestimonialsWrapper>
      <div className="testimonials">
        <h2 className="t" data-translate="homepageSolutionsTestimonialsTitle">
          Testimonials
        </h2>
        <Slider {...settingsTestimonials}>
          <div className="testimonials-cards">
            <img alt="" src={Quatation} />
            <p>
              With the help of Ice Trackr we get the full benefit of following
              in real time the temperature of our coolers. This helps us in a
              way where we deliver a fresh food much easier and much faster.
            </p>
            <div className="testimonials-cards-bottom">
              <img alt="" src={Avatar} />
              <div>
                <p className="testemonials-cards-author">Nake</p>
                <p className="testemonials-cards-job">
                  CEO and Founder of Bulmak Transport
                </p>
              </div>
            </div>
          </div>
          <div className="testimonials-cards">
            <img alt="" src={Quatation} />
            <p>
              After installing Ice Trackr bundled with the module for Fuel Control,
              our costs went down by 15% and our delivery time decreased for 10%.
              Now we have full overview of our fleet movement, and full overview of
              the fleet fuel consumption.
            </p>
            <div className="testimonials-cards-bottom">
              <img alt="" src={Avatar} />
              <div>
                <p>Vanco</p>
                <p>CEO and Founder of BiSi Trans</p>
              </div>
            </div>
          </div>
          <div className="testimonials-cards">
            <img alt="" src={Quatation} />
            <p>
              We installed Ice Trackr lite tracking first, we had a great benefit.
              later on we realise we need more, and we added the module for assets
              tracking which bring way more easier handling of our toi-toi boxes.
            </p>
            <div className="testimonials-cards-bottom">
              <img alt="" src={Avatar} />
              <div>
                <p className="testemonials-cards-author">Sonja</p>
                <p className="testemonials-cards-job">
                  CEO and Founder of MSS Skopje
                </p>
              </div>
            </div>
          </div>
          <div className="testimonials-cards">
            <img alt="" src={Quatation} />
            <p>
              With the help of the Taxi Call Dispatch module we now have much
              and faster response to all of the incoming and outgoing calls,
              and we always have the chance to see the full call history for
              a detailed future plannings.
            </p>
            <div className="testimonials-cards-bottom">
              <img alt="" src={Avatar} />
              <div>
                <p className="testemonials-cards-author">Vanja</p>
                <p className="testemonials-cards-job">
                  CEO and Founder of Koki Taxi
                </p>
              </div>
            </div>
          </div>
          <div className="testimonials-cards">
            <img alt="" src={Quatation} />
            <p>
              We started with the lite tracking from Ice Trackr, then we soon
              realise we need more, next step was adding the Fuel Control module.
              After some period we upgrade once more, we added the Tachograph
              remote download module which helped us to easy download the Vehicle
              inspection data.
            </p>
            <div className="testimonials-cards-bottom">
              <img alt="" src={Avatar} />
              <div>
                <p className="testemonials-cards-author">Stole</p>
                <p className="testemonials-cards-job">
                  CEO and Founder of DB Sped
                </p>
              </div>
            </div>
          </div>
        </Slider>
        <img alt="" src={Ellipse2} className="ellipse-2" />
        <img alt="" src={Intersection} className="intersection" />
      </div>
    </TestimonialsWrapper>
  );
};

export default Testimonials;
