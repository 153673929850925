import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GlobalContext } from "../../Consts/GlobalContext";
import Group64 from "../../img/products/group-64.png";
import Group7 from "../../img/products/group-7.png";

const ProductsFirstContentWrapper = styled.div`
  padding: 150px 375px;
  .cards {
    display: flex;
    justify-content: center;
    align-items: center;
    .left {
      flex-basis: 50%;
      h2 {
        ${(props) => props.theme.h2_48};
        color: #202945;
      }
      p {
        ${(props) => props.theme.body_20};
        margin: 35px 0 90px;
      }
      .receive-quote-btn {
        padding: 21px 70px;
      }
    }
    .right {
      flex-basis: 50%;
      display: flex;
      align-items: center;
      img {
        box-shadow: -10px 10px 40px #1a284d17;
        display: block;
        transition: all 0.6s ease-out;
        width: 50%;
        &:first-child {
          transform: translateX(65px) scale(0.9);
        }
        &:last-child {
          position: relative;
          z-index: 1;
          transform: scale(0.9);
        }
      }
      &:hover {
        img {
          &:first-child {
            transform: translate(65px, -145px) scale(0.7);
          }
          &:last-child {
            position: relative;
            z-index: 1;
            transform: scale(0.8);
          }
        }
      }
    }
  }

  @media (max-width: 1900px) {
    padding: 150px 275px;
  }

  @media (max-width: 1439px) {
    padding: 150px 50px;
  }
  @media (max-width: 1023px) {
    padding: 150px 20px;
    .cards {
      .left {
        h2 {
          font-size: 35px;
          line-height: 49px;
        }
        p {
          margin: 20px 0 50px;
          font-size: 18px;
          line-height: 25px;
        }
        .receive-quote-btn {
          padding: 15px 40px;
        }
      }

      .right {
        &:hover {
          img {
            &:first-child {
              transform: translate(45px, -90px) scale(0.7);
            }
            &:last-child {
              transform: scale(0.8);
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 50px 20px;

    .cards {
      display: block;
      .left {
        p {
          margin: 15px 0 30px;
        }
        .receive-quote-btn {
          padding: 10px 30px;
        }
      }
    }
  }
`;

const ProductsFirstContent = () => {
  const { lang } = useContext(GlobalContext);
  return (
    <ProductsFirstContentWrapper>
      <div className="cards">
        <div className="left">
          <h2 className="t" data-translate="productsFirstContentFirstCardTitle">
            Tachogram
          </h2>
          <p className="t" data-translate="productsFirstContentFirstCardText">
            A complete solution for managing digital tachograph (including smart
            tachograph) compliance, downloading and analysing driver and vehicle
            card data remotely using online platform and mobile application
          </p>
          <Link
            to="/solutions/digital-tachograph-remote-download"
            className="receive-quote-btn"
          >
            <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
          </Link>
        </div>
        <div className="right">
          <img src={Group7} alt="" />
          <img src={Group64} alt="" />
        </div>
      </div>
    </ProductsFirstContentWrapper>
  );
};

export default ProductsFirstContent;
