import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline; 
        box-sizing: border-box; 

    }
    *, *:before, *:after {
      box-sizing: inherit;
    }
    #root{
      overflow-x:hidden;
    }
    button{
      border:0;
    }
    .no-scrolling{
      overflow-y:hidden;
    }
    .solutions-active,
      .aboutus-active,
      .blog-active,
      .contact-active,
      .products-active {
        color: #0071ce !important;
      }
    .receive-quote-btn {
      background-color: rgba(46, 217, 195, 1);
      font-size: 18px;
      text-transform: uppercase;
      text-decoration: none;
      color: white;
      padding: 15px 20px;
      border-radius: 100px;
      display: inline-block;
      font-family: "TT Commons", sans-serif;
      letter-spacing: 2px;
      line-height: 25px;
      position: relative;
      overflow: hidden;
      transition:all 0.6s;
      span {
        display:block;
        position: relative;
        z-index: 1;
        text-align: center;
      }

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -25px;
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 50%;
        transform: scale(1, 1) translateX(-50%);
        transition: bottom 0.3s ease-in-out, transform 0.6s, width 0.6s,
          height 0.6s;
      }
      &:hover {
        color: rgba(46, 217, 195, 1);
        box-shadow: 4px 4px 20px #2029451C;
        background-color: #fff;
      }
    }
    .receive-quote-btn:hover::after {
      bottom: 0;
      right: 0;
      width: 100%;
      height: 250px;
      border-radius: 30px;
      transition: all 0.6s ease-in-out;
      transform: scale(1.5, 1.5) translateX(-50%);
    }
    
    .slick-prev,
    .slick-next {
      color: #8db2e3 !important;
      width: 40px !important;
      height: 40px !important;
    }
    .slick-prev:hover,
    .slick-next:hover {
      color: #0071ce;
    }
    .slick-prev {
      left: -50px;
    }
    .slick-next {
      right: -50px;
    }

    .transparent-menu {
      background-color: transparent !important;
      backdrop-filter: blur(0) !important;
      position:sticky !important;
      margin-bottom:-94px;
    }
    .transparent-mobile-menu {
      background-color: transparent !important;
      backdrop-filter:unset;
    }
    .unsubscribe-snackbar {
      bottom: 20px;
      visibility: visible;
      opacity:1;
    }
    .open-cookies{
      top:0 !important;
      visibility: visible !important;
      opacity:1 !important;
    }



    @media (max-width: 1900px) {
      .receive-quote-btn {
        font-size:16px;
      }
    }

    @media (max-width:1440px){
      .receive-quote-btn {
        font-size:15px;
        padding: 10px;
      }
    }

    @media (max-width:1023px){
      .slick-prev {
        left: -35px;
      }
      .slick-next {
        right: -35px;
      }
    }

    @media (max-width:767px){
      .slick-prev,
      .slick-next {
        color: #8db2e3 !important;
        width: 20px !important;
        height: 20px !important;
      }
      .slick-prev {
        left: 0px;
        z-index:2;
      }
      .slick-next {
        right: 0px;
      }
    }
`;

export default GlobalStyle;
