import React from "react";
import CookiesSettings from "../CookiesSettings/CookiesSettings";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import SensorsFirstContent from "./SensorsFirstContent";
import SensorsIntro from "./SensorsIntro";
const SensorsPage = () => {
  return (
    <>
      <MenuFixed />
      <SensorsIntro />
      <SensorsFirstContent />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default SensorsPage;
