import React from "react";
import styled from "styled-components";
import Group7014 from "../../img/route-planning/group-7014.png";
import Group7029 from "../../img/route-planning/group-7029.png";
import Group7675 from "../../img/route-planning/group-7675.svg";
import ListIcon from "../../img/list-icon.svg";

const RoutePlanningFirstContentWrapper = styled.div`
  padding: 0px 375px;
  margin-bottom: -115px;
  position: relative;
  z-index: 1;
  .container {
    padding: 100px 100px 70px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        flex-basis: 75%;
        margin-right: 30px;
        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
          ${(props) => props.theme.body_20};
        }
        ul {
          padding-left: 24px;
          list-style-image: url(${ListIcon});
          li {
            ${(props) => props.theme.body_20};
            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }
      }

      .right {
        flex-basis: 25%;
        img {
          width: 370px;
          display: block;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }
      &:last-child {
        img {
          box-shadow: none;
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0px 275px;
    .container {
      padding: 30px 25px 40px;
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 0px 50px;
  }
  @media (max-width: 1023px) {
    padding: 0px 20px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            width: 250px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      .container-cards {
        display: block;
        .left {
          margin-right: 0;
          margin-bottom: 15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
          ul {
            li {
              font-size: 15px;
              line-height: 21px;
            }
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
`;
const RoutePlanningFirstContent = () => {
  return (
    <RoutePlanningFirstContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="routePlanningFirstContentFirstCardTitle"
            >
              Send the routes to your drivers instantly
            </h2>
            <ul>
              <li
                className="t"
                data-translate="routePlanningFirstContentFirstCardText1"
              >
                The planned routes can be sent directly to the Ice Trackr
                Framework and opened using any of the standard navigation apps
              </li>
              <li
                className="t"
                data-translate="routePlanningFirstContentFirstCardText2"
              >
                Add comments and attachments to the route and make sure you'll
                never lose any important information
              </li>
            </ul>
          </div>
          <div className="right">
            <img alt="" src={Group7014} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="routePlanningFirstContentSecondCardTitle"
            >
              Keep the database of all your clients and orders
            </h2>
            <p
              className="t"
              data-translate="routePlanningFirstContentSecondCardText"
            >
              Create order and client list in the Ice Trackr system or import it from
              your IT systems using API integrations. That way you'll be able to
              assign orders and cargos to specific clients within a few seconds
              time.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7675} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2
              className="t"
              data-translate="routePlanningFirstContentThirdCardTitle"
            >
              API integrations for an effortless workflow
            </h2>
            <p
              className="t"
              data-translate="routePlanningFirstContentThirdCardText"
            >
              Automate your route planning process by integrating your IT system
              with Ice Trackr. Take advantage of the integration options with your
              company's systems to ensure an automatic exchange with the new
              orders, documents and other data
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group7029} />
          </div>
        </div>
      </div>
    </RoutePlanningFirstContentWrapper>
  );
};

export default RoutePlanningFirstContent;
