import React from "react";
import CookiesSettings from "../CookiesSettings/CookiesSettings";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import SmallSlider from "../SmallSlider/SmallSlider";
import TachographFirstContent from "./TachographFirstContent";
import TachographIntro from "./TachographIntro";
import TachographSecondContent from "./TachographSecondContent";
import TachographThirdContent from "./TachographThirdContent";

const TachographPage = () => {
  return (
    <>
      <MenuFixed />
      <TachographIntro />
      <TachographFirstContent />
      <SmallSlider />
      <TachographSecondContent />
      <TachographThirdContent />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default TachographPage;
