import React, { useContext } from "react";
import styled from "styled-components";
import Group8468 from "../../img/products/lite/group-8468.png";
import Group8467 from "../../img/products/lite/group-8467.png";
import Group8391 from "../../img/products/lite/group-8391.png";
import Group7066 from "../../img/gps/group-7066.svg";
import Group7364 from "../../img/temperature-control/group-7364.svg";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../Consts/GlobalContext";

const ProductsLiteFirstContentWrapper = styled.div`
  padding: 0px 375px;
  position: relative;
  z-index: 1;
  .container {
    padding: 80px 100px 120px;
    box-shadow: 0px 25px 35px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    h1 {
      ${(props) => props.theme.h1_64};
      text-align: center;
      margin-bottom: 65px;
      span {
        &:first-child {
          color: #0071ce;
        }
      }
    }
    .container-cards {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        margin-right: 30px;
        flex-basis: 70%;
        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
          ${(props) => props.theme.body_20};
          margin-bottom: 30px;
        }
        .receive-quote-btn {
          padding: 20px 70px;
        }
      }

      .right {
        flex-basis: 30%;
        img {
          width: 370px;
          display: block;
          border-radius: 5px;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }
      &:last-child {
        img {
          box-shadow: none;
        }
      }
      &:nth-child(2) {
        img {
          box-shadow: -10px 10px 40px #1a284d14;
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0px 275px;
    .container {
      padding: 30px 25px 40px;
      h1 {
        font-size: 58px;
      }
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 0px 50px;
    .container {
      .container-cards {
        .left {
          .receive-quote-btn {
            padding: 15px 45px;
          }
        }
      }
    }
  }
  @media (max-width: 1023px) {
    padding: 0px 20px;
    .container {
      h1 {
        font-size: 48px;
        line-height: 67px;
      }
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            width: 250px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      h1 {
        font-size: 38px;
        line-height: 53px;
      }
      .container-cards {
        display: block;
        .left {
          margin-right: 0;
          margin-bottom: 15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom: 10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
          .receive-quote-btn {
            padding: 10px 25px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
`;
const ProductsLiteFirstContent = () => {
  const { lang } = useContext(GlobalContext);
  return (
    <ProductsLiteFirstContentWrapper>
      <div className="container">
        <h1>
          IceTrackr <span>Lite </span>
          <span className="t" data-translate="productsLiteFirstContentTitle">
            Platform Solutions
          </span>
        </h1>
        <div>
          <div className="container-cards">
            <div className="left">
              <h2
                className="t"
                data-translate="productsLiteFirstContentFirstCardTitle"
              >
                GPS tracking with live data
              </h2>
              <p
                className="t"
                data-translate="productsLiteFirstContentFirstCardText"
              >
                See the exact location, current route and speed of all your
                vehicles in your fleet in real-time
              </p>
              <Link
                to="/solutions/gps-tracking-and-live-data"
                className="receive-quote-btn"
              >
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </div>
            <div className="right">
              <img alt="" src={Group7066} />
            </div>
          </div>
          <div className="container-cards">
            <div className="left">
              <h2
                className="t"
                data-translate="productsLiteFirstContentSecondCardTitle"
              >
                Geo-fence Objects
              </h2>
              <p
                className="t"
                data-translate="productsLiteFirstContentSecondCardText"
              >
                Create virtual territories and generate reports to see when a
                vehicle has entered or left a territory. Set up alerts to
                receive notifications immediately.
              </p>
              <Link to="/solutions/fuel-control" className="receive-quote-btn">
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </div>
            <div className="right">
              <img alt="" src={Group8468} />
            </div>
          </div>
          <div className="container-cards">
            <div className="left">
              <h2
                className="t"
                data-translate="productsLiteFirstContentThirdCardTitle"
              >
                Route History
              </h2>
              <p
                className="t"
                data-translate="productsLiteFirstContentThirdCardText"
              >
                View and analyse historical routes and speed charts.
              </p>
              <Link
                to="/solutions/history-of-events"
                className="receive-quote-btn"
              >
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </div>
            <div className="right">
              <img alt="" src={Group8467} />
            </div>
          </div>
          <div className="container-cards">
            <div className="left">
              <h2
                className="t"
                data-translate="productsLiteFirstContentFourthCardTitle"
              >
                Route Reports
              </h2>
              <p
                className="t"
                data-translate="productsLiteFirstContentFourthCardText"
              >
                A compact report is available for each vehicle showing full
                route history including information about stops, distance
                travelled in each part of the route, start and end locations,
                time spent on the road as well as the average speed and max
                speed.
              </p>
              <Link to="/solutions/reports" className="receive-quote-btn">
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </div>
            <div className="right">
              <img alt="" src={Group8391} />
            </div>
          </div>
          <div className="container-cards">
            <div className="left">
              <h2
                className="t"
                data-translate="productsLiteFirstContentFifthCardTitle"
              >
                Receive Instant Alerts
              </h2>
              <p
                className="t"
                data-translate="productsLiteFirstContentFifthCardText"
              >
                Set up alerts to notify your drivers and dispatchers as soon as
                any temperature changes appear and save your business from
                unexpected costs.
              </p>
              <Link to="/solutions/alerts" className="receive-quote-btn">
                <span>{lang === "en" ? "Learn More" : "Дознај повеќе"}</span>
              </Link>
            </div>
            <div className="right">
              <img alt="" src={Group7364} />
            </div>
          </div>
        </div>
      </div>
    </ProductsLiteFirstContentWrapper>
  );
};

export default ProductsLiteFirstContent;
