import React from "react";
import styled from "styled-components";
import Group8315 from "../../img/taxi-call/group-8315.png";
import Group6493 from "../../img/taxi-call/group-6493.png";

const TaxiCallFirstContentWrapper = styled.div`
  padding: 0px 375px 200px;
  position: relative;
  z-index: 1;
  .container {
    padding: 100px 100px 70px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        flex-basis: 75%;
        margin-right: 30px;
        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
          ${(props) => props.theme.body_20};
        }
      }

      .right {
        flex-basis: 25%;
        img {
          width: 370px;
          display: block;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }
      &:last-child {
        img {
          box-shadow: none;
        }
      }
    }
  }

  @media (max-width: 1900px) {
    padding: 0px 275px 150px;
    .container {
      padding: 30px 25px 40px;
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 0px 50px 100px;
  }
  @media (max-width: 1023px) {
    padding: 0px 20px 50px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            width: 250px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      .container-cards {
        display: block;
        .left {
          margin-right:0;
          margin-bottom:15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom:10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
`;
const TaxiCallFirstContent = () => {
  return (
    <TaxiCallFirstContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="taxiCallFirstContentFirstCardTitle">Control panel</h2>
            <p className="t" data-translate="taxiCallFirstContentFirstCardText">
              Manage your call operators, drivers, cars, tariffs, clients, see
              statistics and much more.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group6493} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="taxiCallFirstContentSecondCardTitle">Reports</h2>
            <p className="t" data-translate="taxiCallFirstContentSecondCardText">
              See all reports and manage your employee privileges from personal
              web services interface.
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group8315} />
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="taxiCallFirstContentThirdCardTitle">Driver App</h2>
            <p className="t" data-translate="taxiCallFirstContentThirdCardText">
              Increase driver efficiency by automatically matching rides with
              the closest available drivers
            </p>
          </div>
          <div className="right">
            <img alt="" src={Group6493} />
          </div>
        </div>
      </div>
    </TaxiCallFirstContentWrapper>
  );
};

export default TaxiCallFirstContent;
