import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { GlobalContext } from "../../Consts/GlobalContext";
import CookiesSettings from "../CookiesSettings/CookiesSettings";
import Footer from "../Footer/Footer";
import FooterLinks from "../Footer/FooterLinks";
import MenuFixed from "../Menu/MenuFixed";
import Quote from "../Quote/Quote";
import AboutUsFirstContent from "./AboutUsFirstContent";
import AboutUsIntro from "./AboutUsIntro";
import AboutUsSecondContent from "./AboutUsSecondContent";
import AboutUsStatistics from "./AboutUsStatistics";

const AboutUsPage = () => {
  const { translateAll } = useContext(GlobalContext);

  useEffect(() => {
    translateAll();
  }, []);
  return (
    <>
      <MenuFixed />
      <AboutUsIntro />
      <AboutUsStatistics />
      <AboutUsFirstContent />
      <AboutUsSecondContent />
      <Quote />
      <FooterLinks />
      <Footer />
      <CookiesSettings />
    </>
  );
};

export default AboutUsPage;
