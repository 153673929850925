import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Group5475 from "../../img/home-page/group-5475.svg";
import Group5907 from "../../img/home-page/group-5907.png";
import Group5908 from "../../img/home-page/group-5908.png";
import Group5909 from "../../img/home-page/group-5909.png";

const ExploreProductsWrapper = styled.div`
  .explore-products {
    height: 1208px;
    display: flex;
    padding-top: 170px;
    .left-container {
      flex-basis: 50%;
      background-image: url(${Group5475});
      background-position: 135% 100%;
      background-repeat: no-repeat;
      background-size: cover;
      .left-container-images {
        position: relative;
        padding-left: 260px;
        margin: 0 0 0 auto;
        img {
          display: block;
          max-width: 550px;
          max-height: 400px;
          transition: all 0.8s ease-in-out;
          box-shadow: -20px 25px 70px #1a284d30;
        }
        img:not(:first-child) {
          position: absolute;
        }
        img:first-child {
          transform: translateX(60px);
        }
        img:nth-child(2) {
          bottom: -275px;
          left: 183px;
        }
        img:last-child {
          right: 30px;
          top: 155px;
        }

        &:hover {
          img:first-child {
            transform: translateX(-100px) scale(1.1);
          }
          img:nth-child(2) {
            left: 250px;
            transform: scale(1.1);
          }
          img:last-child {
            top: 50px;
            transform: scale(1.1);
          }
        }
      }
    }
    .right-container {
      flex-basis: 24%;
      margin-top: 130px;
      margin-left: 20px;
      h2 {
        ${(props) => props.theme.h2_48};
        margin-bottom: 30px;
        color: #202945;
      }
      p {
        ${(props) => props.theme.body_20};
        margin-bottom: 30px;
      }
    }
  }

  @media (max-width: 1900px) {
    .explore-products {
      .left-container {
        background-position: 107% 100%;
        flex-basis: 58%;
        .left-container-images {
          padding-left: 120px;

          img:nth-child(2) {
            left: 70px;
          }
          &:hover {
            img:first-child {
              transform: translateX(-70px) scale(1.1);
            }
            img:nth-child(2) {
              left: 120px;
            }
          }
        }
      }
      .right-container {
        h2 {
          font-size: 38px;
          line-height: 53px;
        }
        p {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
  @media (max-width: 1439px) {
    .explore-products {
      .left-container {
        background-position: 100% 100%;
        margin-top: 100px;
        .left-container-images {
          padding-left: 0;
          img {
            max-width: 350px;
            max-height: 200px;
          }
          img:first-child {
            transform: translateX(100px);
          }
          img:nth-child(2) {
            left: 50px;
            bottom: -200px;
          }
          img:last-child {
            right: 50px;
            top: 135px;
          }
          &:hover {
            img:first-child {
              transform: translateX(40px) scale(1.1);
            }
            img:nth-child(2) {
              left: 100px;
              transform: scale(1.1);
            }
            img:last-child {
              top: 50px;
              transform: scale(1.1);
            }
          }
        }
      }
      .right-container {
        margin-left: 0;
        flex-basis: 36%;
        h2 {
          font-size: 38px;
        }
        p {
          font-size: 18px;
        }
      }
    }
  }
  @media (max-width: 1023px) {
    .explore-products {
      height: auto;
      display: block;
      .left-container {
        background-position: 100% 30%;
        .left-container-images {
          img {
            max-width: 450px;
            max-height: 250px;
          }
          img:nth-child(2) {
            margin-top: 50px;
            position: static;
            transform: translateX(50px);
          }
          img:last-child {
            top: 160px;
          }
          &:hover {
            img:first-child {
              transform: translateX(50px) scale(1.1);
            }
            img:nth-child(2) {
              transform: translateX(100px) scale(1.1);
            }
            img:last-child {
              top: 80px;
              transform: scale(1.1);
            }
          }
        }
      }
      .right-container {
        padding: 0 20px;
        margin-top: 60px;
      }
    }
  }

  @media (max-width: 767px) {
    .explore-products {
      .left-container {
        background-position: 100% 30%;
        .left-container-images {
          padding: 0 20px;
          img:not(:first-child) {
            position: static;
          }
          img:first-child {
            transform: translate(0);
            width: 100%;
          }
          img:nth-child(2) {
            transform: translateX(0);
            width: 100%;
          }
          img:last-child {
            margin: 25px auto 0;
          }
          &:hover {
            img:first-child {
              transform: none;
            }
            img:nth-child(2) {
              transform: none;
            }
            img:last-child {
              transform: none;
            }
          }
        }
      }
    }
  }
`;
const ExploreProducts = () => {
  return (
    <ExploreProductsWrapper>
      <div className="explore-products">
        <div className="left-container">
          <div className="left-container-images">
            <img src={Group5907} alt="" />
            <img src={Group5908} alt="" />
            <img src={Group5909} alt="" />
          </div>
        </div>
        <div className="right-container">
          <h2 className="t" data-translate="homepageExploreProductsTitle">
            How can Ice Trackr help your company
          </h2>
          <p className="t" data-translate="homepageExploreProductsText">
            Ice Trackr is an integrated system that allows tracking, monitoring
            and security of vehicles and planning of their movements (routing),
            in a very simple way. It offers opportunities to carry out everyday
            tasks in a new and sophisticated way, which allows saving money and
            time.
          </p>
          <Link to="/products" className="receive-quote-btn">
            <span className="t" data-translate="homepageExploreProductsButton">
              Explore Products
            </span>
          </Link>
        </div>
      </div>
    </ExploreProductsWrapper>
  );
};

export default ExploreProducts;
