import React from "react";
import styled from "styled-components";
import Group6676 from "../../img/fleet-efficiency/group-6676.svg";
import Group6696 from "../../img/fleet-efficiency/group-6696.svg";
import Group6677 from "../../img/fleet-efficiency/group-6677.svg";

const FleetEfficiencyFirstContentWrapper = styled.div`
  padding: 0px 375px;
  position: relative;
  z-index: 1;
  .container {
    padding: 100px;
    box-shadow: 0px 4px 20px #1a284d14;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    .container-cards {
      display: flex;
      align-items: center;
      .left {
        margin-right: 30px;

        h2 {
          ${(props) => props.theme.h2_48};
          color: #202945;
          margin-bottom: 16px;
        }
        p {
            ${(props) => props.theme.body_20};
          }
        ul {
          padding-left: 24px;
          li {
            ${(props) => props.theme.body_20};
            &::marker {
              color: #dce3eb;
            }
          }
        }
      }

      .right {
        img {
          width: 370px;
          display: block;
        }
      }
      &:nth-child(2n) {
        .left {
          order: 1;
          margin-right: 0;
          margin-left: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 175px;
      }
      &:last-child {
        img {
          box-shadow: none;
        }
      }
    }
  }
  @media (max-width: 1900px) {
    padding: 0px 275px;
    .container {
      padding: 30px 25px 40px;
      .container-cards {
        .left {
          h2 {
            font-size: 38px;
            line-height: 53px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 75px;
        }
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 0px 50px;
  }
  @media (max-width: 1023px) {
    padding: 0px 20px;
    .container {
      .container-cards {
        .left {
          h2 {
            font-size: 28px;
            line-height: 39px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
          ul{
            li{
              font-size: 16px;
              line-height:22px;
            }
          }
        }
        .right {
          img {
            width: 250px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 30px 15px 40px;
      .container-cards {
        display: block;
        .left {
          margin-right:0;
          margin-bottom:15px;
          h2 {
            font-size: 28px;
            line-height: 39px;
            margin-bottom:10px;
          }
          p {
            font-size: 15px;
            line-height: 21px;
          }
        }
        .right {
          img {
            margin: 0 auto;
          }
        }
        &:nth-child(2n) {
          .left {
            margin-left: 0;
          }
        }
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
`;
const FleetEfficiencyFirstContent = () => {
  return (
    <FleetEfficiencyFirstContentWrapper>
      <div className="container">
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="fleetEfficiencyFirstContentFirstCardTitle">Tracking Fleet Performance Is a Challenge</h2>
            <p className="t" data-translate="fleetEfficiencyFirstContentFirstCardText">
              Our solution helps to tackle it by providing an automated
              calculation of your fleet efficiency on a daily, weekly and
              monthly basis
            </p>
          </div>
          <div className="right">
            <img src={Group6676} alt=""/>
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="fleetEfficiencyFirstContentSecondCardTitle">Calculations Based On Ignition Time On</h2>
            <p className="t" data-translate="fleetEfficiencyFirstContentSecondCardText">
              The most convenient way to compare efficiency between vehicles
              that are used only for specific hours of the day, driving when
              being operated or used in a stationary state
            </p>
          </div>
          <div className="right">
            <img src={Group6696} alt=""/>
          </div>
        </div>
        <div className="container-cards">
          <div className="left">
            <h2 className="t" data-translate="fleetEfficiencyFirstContentThirdCardTitle">5 Dashboards To Show It All</h2>
            <ul>
              <li className="t" data-translate="fleetEfficiencyFirstContentThirdCardText1">Average efficiency</li>
              <li className="t" data-translate="fleetEfficiencyFirstContentThirdCardText2">Average daily driving time</li>
              <li className="t" data-translate="fleetEfficiencyFirstContentThirdCardText3">Average daily idle time</li>
              <li className="t" data-translate="fleetEfficiencyFirstContentThirdCardText4">Average daily ignition off time</li>
              <li className="t" data-translate="fleetEfficiencyFirstContentThirdCardText5">Average daily ignition on time</li>
            </ul>
          </div>
          <div className="right">
            <img src={Group6677} alt=""/>
          </div>
        </div>
      </div>
    </FleetEfficiencyFirstContentWrapper>
  );
};

export default FleetEfficiencyFirstContent;
