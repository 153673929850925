const features = [
  {
    id: 1,
    title: "GPS tracking",
    titleMK: "GPS следење",
    pro: true,
    lite: true,
  },
  {
    id: 2,
    title: "Fuel control",
    titleMK: "Контрола на гориво",
    pro: true,
    lite: false,
  },
  {
    id: 3,
    title: "Remote tachograph download",
    titleMK: "Далечинско тахограф преземање",
    pro: true,
    lite: false,
  },
  {
    id: 4,
    title: "Two-way communication with Garmin",
    titleMK: "Двонасочна комуникација со Garmin",
    pro: true,
    lite: false,
  },
  {
    id: 5,
    title: "Reports",
    titleMK: "Извештаи",
    pro: true,
    lite: true,
  },
  {
    id: 6,
    title: "Alerts",
    titleMK: "Известувања",
    pro: true,
    lite: true,
  },
  {
    id: 7,
    title: "GEO-fence",
    titleMK: "Гeoодбрана",
    pro: true,
    lite: true,
  },
  {
    id: 8,
    title: "Connection to CAN",
    titleMK: "Поврзување со CAN",
    pro: true,
    lite: false,
  },
  {
    id: 9,
    title: "Vehicle engine blocking",
    titleMK: "Блокирање на моторот на возилото",
    pro: true,
    lite: false,
  },
  {
    id: 10,
    title: "Driver behaviour analysis",
    titleMK: "Анализа на однесувањето на возачот",
    pro: true,
    lite: false,
  },
  {
    id: 11,
    title: "Task management",
    titleMK: "Управување со задачи",
    pro: true,
    lite: false,
  },
  {
    id: 12,
    title: "Vehicle inspections",
    titleMK: "Проверки на возила",
    pro: true,
    lite: false,
  },
  {
    id: 13,
    title: "Fleet efficiency",
    titleMK: "Ефикасност на флотата",
    pro: true,
    lite: false,
  },
  {
    id: 14,
    title: "Route planning",
    titleMK: "Планирање на маршрутата",
    pro: true,
    lite: false,
  },
  {
    id: 15,
    title: "Fleet maintenance",
    titleMK: "Одржување на флота",
    pro: true,
    lite: false,
  },
  {
    id: 16,
    title: "API integrations",
    titleMK: "API интеграции",
    pro: true,
    lite: false,
  },
  {
    id: 17,
    title: "Route history",
    titleMK: "Историја на маршрутата",
    pro: true,
    lite: true,
  },
];

export default features;
